import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { bookingUsPostCallWrapper } from "../apiCaching/POST-Requests/apibookingCallWrapper";
import { isTestEnvironment } from "../apiCaching/POST-Requests/helperModules/helperFunctions";
import { BookingPayloadModel } from "../apiCaching/POST-Requests/interface/booking/bookingInterface";
import { errorMessage } from "../common/errorMessages";
import { apiConfig } from "../config/apiConfig";
import { apiCaching } from "../config/cachingConfig";
import { REGEX } from "../constants/regex";
import { getCountryCode } from "../location-search-map/getCountryCode";
import { disclaimerHandleCheck, DisclaimerServiceIdentifier } from "../util/disclaimerService";
import { allAddressList, discoverSuggestions, getAddressDetails, handleMissingData } from "../util/discovery";
import { DomainIdentifier } from "../util/domainService";
import { handleMobileInput } from "../util/errorHandlers";
import { gtmCustomEventLeadFlow } from "../util/gtm-custom-event-lead-flow";
import { hideTermsPolicy, toggleTermsPolicyBasedOnCountry } from "../util/hideTermsPolicy";
import { startLoader, stopLoader } from "../util/loader";
import { LocalizationIdentifier } from "../util/localizationService";
import { debounce, getBrandDetailsFromBrandJson, getCampaignName, storeBookingApiPayload } from "../util/share";

export class RewardForm {
    private rewardFname!: HTMLInputElement;
    private rewardLname!: HTMLInputElement;
    private rewardZipCode!: HTMLInputElement;
    private rewardAddress!: HTMLInputElement;
    private rewardAddress2!: HTMLInputElement;
    private rewardEmail!: HTMLInputElement;
    private rewardPhone!: HTMLInputElement;
    private rewardServiceReminder!: HTMLInputElement;
    private checkboxList!: NodeListOf<HTMLInputElement>;
    private rewardComments!: HTMLTextAreaElement;
    private submitButton!: HTMLButtonElement;
    private rewardLeadFlowService!: NodeListOf<HTMLInputElement>;

    constructor() {
        this.initFormFields();
        this.addEventListeners();

        // Adding a event listener for Address Property Modal

        document.querySelector('.cta-wrapper .primary-btn')?.addEventListener("click", (e) => {
                    const enteredAddr:any = this.rewardAddress?.value.concat(" ", this.rewardAddress2.value, " ", this.rewardZipCode?.value);
                    const suggestAddr = this.rewardAddress?.getAttribute("data-suggestion") as any;
                    let output: any;
                    const suggestAddrInp = document.getElementById('suggested-address-radio') as HTMLInputElement;
                    sessionStorage.setItem("suggestion", "true");
                    if (suggestAddrInp?.checked) {
                        const suggestJSON = suggestAddr !=='undefned' && JSON.parse(suggestAddr);
                        output = suggestJSON?.address.label;
                        this.rewardAddress.value = output;
                        const suggestedAddress = getAddressDetails(suggestJSON?.address);
                        sessionStorage.setItem('suggestedAddress', JSON.stringify(suggestedAddress));
                    }
                    else {
                        output = enteredAddr;
                    }
                    sessionStorage.setItem('sAddressParam', output);
                    this.handleSubmit(e);
                });
    }

    private initFormFields(): void {
        const rewardFormSection = document.querySelector('.reward-form-section') as HTMLElement;

        this.rewardFname = rewardFormSection?.querySelector('#reward-fname') as HTMLInputElement;
        this.rewardLname = rewardFormSection?.querySelector('#reward-lname') as HTMLInputElement;
        this.rewardZipCode = rewardFormSection?.querySelector('#reward-zip-code') as HTMLInputElement;
        this.rewardAddress = rewardFormSection?.querySelector('#reward-address') as HTMLInputElement;
        this.rewardAddress2 = rewardFormSection?.querySelector('#reward-address-2') as HTMLInputElement;
        this.rewardEmail = rewardFormSection?.querySelector('#reward-email') as HTMLInputElement;
        this.rewardPhone = rewardFormSection?.querySelector('#reward-pnumber') as HTMLInputElement;
        this.rewardServiceReminder = rewardFormSection?.querySelector('#reward-service-reminder') as HTMLInputElement;
        this.checkboxList = rewardFormSection?.querySelectorAll('.checkbox-list input[type="checkbox"]');
        this.rewardComments = rewardFormSection?.querySelector('#reward-comments') as HTMLTextAreaElement;
        this.submitButton = rewardFormSection?.querySelector('.submit-btn') as HTMLButtonElement;
        this.rewardLeadFlowService = document.querySelectorAll('[id*="reward-service-reminder]');
        this.rewardLeadFlowService?.forEach((checkbox) => {
            if(checkbox.type === 'checkbox'){
                checkbox.addEventListener('change', this.handleCheckboxChange.bind(this));
            }
          });
          const localisation = DomainIdentifier();
          const countryCode = getCountryCode();
          const localisedcountryCode = sessionStorage.getItem('countryCode');
          if (localisation == 'ca' || countryCode === 'ca' || localisedcountryCode === 'ca') {
            if (rewardFormSection) {
                hideTermsPolicy('.reward-form-section .reward-form-main', 'terms-policy-text');
            }
          }
    }

    private readonly setAutoSuggested = (e: any) => {
        e.preventDefault()
        sessionStorage.setItem("suggestion", "false");
        this.rewardAddress?.removeAttribute("suggestion");
        sessionStorage.setItem("manualCity", "");
        sessionStorage.setItem("manualState", "");
        this.debouncedSearch(this.rewardZipCode, this.rewardAddress);
    }

    private readonly debouncedSearch = debounce(async (zipCode: string, address: string) => {
        await discoverSuggestions(zipCode, address);
    }, 500);

    private addEventListeners(): void {

        if (this.rewardFname) {
            this.rewardFname.addEventListener('input', () => this.handleCommonInputError(this.rewardFname, `${this.rewardFname.id}-error-msg`, REGEX.sendName, errorMessage.fname));
        }
        if (this.rewardLname) {
            this.rewardLname.addEventListener('input', () => this.handleCommonInputError(this.rewardLname, `${this.rewardLname.id}-error-msg`, REGEX.sendName, errorMessage.lname));
        }
        if (this.rewardPhone) {
            this.rewardPhone.addEventListener('input', handleMobileInput.bind(this));
        }

        if (this.rewardZipCode) {
            this.rewardZipCode.addEventListener('input', () => this.handleCommonInputError(this.rewardZipCode, `${this.rewardZipCode.id}-error-msg`, REGEX.sendZip, errorMessage.zip));
            this.rewardZipCode.addEventListener('focusout', this.handleFocusOut.bind(this));
        }
        if (this.rewardEmail) {
            this.rewardEmail.addEventListener('input', () => this.handleCommonInputError(this.rewardEmail, `${this.rewardEmail.id}-error-msg`, REGEX.sendEmail, errorMessage.email));
        }

        if (this.rewardAddress) {
            this.rewardAddress?.addEventListener('input', () => this.handleCommonInputError(this.rewardAddress, `${this.rewardAddress.id}-error-msg`, REGEX.sendAddress1, errorMessage.address));
            // Set Auto address suggestion
            const debouncedSetAutoSuggested = debounce(this.setAutoSuggested.bind(this), 300);
            this.rewardAddress?.addEventListener('input', debouncedSetAutoSuggested);
        }

        if (this.rewardAddress2) {
            this.rewardAddress2.addEventListener('input', () => this.handleCommonInputError(this.rewardAddress2, `${this.rewardAddress2.id}-error-msg`, REGEX.sendAddress2, errorMessage.address2));
        }

        this.submitButton?.addEventListener('click', this.handleSubmit.bind(this));
    }

    private async handleSubmit(event: Event): Promise<void> {
        event.preventDefault();
        if (this.validateFields()) {
            console.log('submit button clicked');
            try {
                const data = {
                    rewardFname: this.rewardFname.value,
                    rewardLname: this.rewardLname.value,
                    rewardZipCode: this.rewardZipCode.value,
                    rewardAddress: this.rewardAddress.value,
                    rewardAddress2: this.rewardAddress2.value,
                    rewardEmail: this.rewardEmail.value,
                    rewardPhone: this.rewardPhone.value,
                    rewardComments: this.rewardComments.value
                };
                console.log('Reward form data:', data);
                const addrFlag = sessionStorage.getItem("suggestion");
                const enteredAddr = this.rewardAddress?.value.concat(" ", this.rewardAddress2.value, " ", this.rewardZipCode?.value);
                const suggestAddr = this.rewardAddress?.getAttribute("data-suggestion") as string;
                if (addrFlag === "false" && suggestAddr != null && suggestAddr !== 'undefined' && enteredAddr) {
                    this.openPropertyValidationModal(enteredAddr, suggestAddr);
                } else {
                    let zipCode = data.rewardZipCode;

                    if (zipCode) {
                        this.franchiseLookupAddressApi(zipCode);
                    } else {
                        // Display the error msg if zipcode is empty
                        stopLoader();
                        this.handleButtonClickAndError('reward-form-error-modal-id', 'reward-form-system-error');
                    }

                }
            } catch (e) {
                console.log('Error while submitting reward form', e);
            }
        }
    }

    openPropertyValidationModal(addressParam: string, suggestStr: any) {
        stopLoader();
        const callPopupModal = document.getElementById('address-modal')
        callPopupModal?.click()
        const enteredAddr = document.querySelector('label[for="entered-address-radio"]');
        const suggestAddr = document.querySelector('label[for="suggested-address-radio"]');
        const suggestJSON = suggestStr !== 'undefined' && JSON.parse(suggestStr);
        if (enteredAddr && suggestAddr) {
            enteredAddr.textContent = addressParam;
            suggestAddr.textContent = suggestJSON.address.label;
        }
    }

    public handleCommonInputError(inputElement: any, errorId: any, validationRegex: any, errorMessage: any) {
        const getErrMsg = (document.getElementById(errorId) as HTMLElement)?.getAttribute('aria-describedby');
        const fieldError = document.getElementById(errorId) as HTMLElement;

        if (inputElement.value !== '' && !validationRegex.test(inputElement?.value?.trim())) {
            if (fieldError) {
                fieldError.classList.remove('hidden');
                fieldError.innerHTML = errorMessage ?? getErrMsg;
            }
        } else {
            inputElement.classList.remove("invalid-field");
            if (fieldError) {
                fieldError.classList.add('hidden');
            }
        }
    }

    public validateFields(): boolean {
        try {
            document.querySelectorAll('.invalid-field').forEach((e: any) => e.classList.remove('invalid-field'));
            const errors: any = {};

            const validateField = (field: any, regex: RegExp, errorMessage: string) => {
                if (field?.value === '' && field?.required) {
                    errors[field?.id] = '';
                } else if (field?.value !== '' && !regex.test(field?.value)) {
                    errors[field?.id] = errorMessage;
                } else if (field?.value && (field?.id === 'reward-pnumber') && field?.value.length < 14) {
                    errors[field?.id] = errorMessage;
                }
            };

            if (this.rewardFname) {
                validateField(this.rewardFname, REGEX.sendName, errorMessage.fname);
            }
            if (this.rewardLname) {
                validateField(this.rewardLname, REGEX.sendName, errorMessage.lname);
            }
            if (this.rewardPhone) {
                validateField(this.rewardPhone, REGEX.mobileNumberRegex, errorMessage.phone);
            }
            if (this.rewardZipCode) {
                validateField(this.rewardZipCode, REGEX.sendZip, errorMessage.zip);
            }
            if (this.rewardEmail) {
                validateField(this.rewardEmail, REGEX.sendEmail, errorMessage.email);
            }
            if (this.rewardAddress) {
                validateField(this.rewardAddress, REGEX.sendAddress1, errorMessage.address);
            }

            if(this.rewardAddress2){
                validateField(this.rewardAddress2, REGEX.sendAddress2, errorMessage.address2);
            }

            if(this.rewardComments){
                validateField(this.rewardComments, REGEX.matchAny, errorMessage.comment);
            }

            const errorIdChekboxSpan = this.handleCheckboxChange();
            if(errorIdChekboxSpan){
                errors[errorIdChekboxSpan] = '';
            }

            Object.keys(errors).forEach((fieldId, index) => {
                const field: any = document.getElementById(`${fieldId}`);
                const fieldError: any = document.getElementById(`${fieldId}-error-msg`);
                if (field?.parentNode) {
                    const errorMessage = errors[fieldId];
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${fieldId}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    if (fieldError?.classList.contains('hidden')) {
                        fieldError.classList.remove('hidden');
                    }
                }
                if (index === 0 && field) {
                    field.focus();
                }
            });

            return Object.keys(errors).length === 0;
        } catch (error) {
            console.error('Error validating fields:', error);
            return false;
        }
    }

    public optinEmailCheck(requestBody: any): any {
        try {
            const domain = DomainIdentifier();
            const localisation = LocalizationIdentifier();

            let checkboxId = '';
            checkboxId = (domain === "us" && (!localisation || localisation === "us"))
                ? 'reward-service-reminder-us'
                : 'reward-service-reminder-ca';
            const rewardForm: any = document.querySelector('.reward-form-section');
            const noteWithRequestBody = disclaimerHandleCheck(false, rewardForm, requestBody, requestBody.zipCode, checkboxId);
            return noteWithRequestBody;

        } catch (error) {
            console.error('An error occurred in disclaimerHandleCheck in Reward file:', error);
            return requestBody;
        }
    }

    public redirectConfirmationPage() {
        const rewardRedirectConfirmationUrl = (document.getElementById('reward-redirect') as HTMLInputElement)?.value;
        if (rewardRedirectConfirmationUrl) {
            window.location.href = rewardRedirectConfirmationUrl;
        }
    }

    public async franchiseLookupAddressApi(zipCode: any) {
        if (!zipCode) return;

        const request = {
            url: apiConfig.LocateLocationApiWithRoundRobinTrue.replace('sAddressParamValue', encodeURIComponent(zipCode))
        };

        try {
            const result = await getCachedAPIResponse(request.url, apiCaching.LocateLocationApiWithRoundRobinTrue);
            await this.handleApiResponse(result);
        } catch (error: any) {
            await this.handleError(error);
        }
    }

    public closeAddressBumperForm() {
        const findLocalBtn = document.querySelector(".find-local") as HTMLElement;
        findLocalBtn?.click();
    }
    public async handleApiResponse(result: any) {
        if (result?.length == 0) {
            await this.handleNoServicedArea();
        } else if (result?.length > 0) {
            this.bookingApiCall(result[0], this.rewardZipCode.value);
        }
    }

    public async handleNoServicedArea() {
        stopLoader();
        const btnClick = document.getElementById('reward-form-error-modal-id');
        btnClick?.click();
        const noServiceEle = document.getElementById('reward-form-no-service');
        if (noServiceEle) {
            noServiceEle.classList.remove('hidden');
            const rewardSystemError = document.getElementById('reward-form-system-error');
            if (rewardSystemError) {
                rewardSystemError.classList.add('hidden');
            }
        }

    }

    public createRequestBody(payLoadObj: any) {
        const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value;
        const url = window.location.href;
        let vendorId;
        let vendorName;
        const { formData, city, state, finalAddress, zipCode, conceptCode, campaignName, brandData, franchiseWebLocationId, franchiseId } = payLoadObj;
        if (payLoadObj.brandData) {
            vendorId = brandData.vendorId;
            vendorName = brandData.vendorName;
        }

        const emailOptOut = brandData.enable_emailOptOut;
        const defaultLeadSourceId = brandData.default_leadSourceID || 0;

        const rewardMemberOfferList: string[] = [];
        this.checkboxList?.forEach((e: any) => {
            if (e.checked && e.parentNode.querySelector('label')?.innerHTML) {
                rewardMemberOfferList.push(e.parentNode.querySelector('label')?.innerHTML);
            }
        });

        let rewardMemberOfferLabel: any = '';
        if (rewardMemberOfferList?.length > 0) {
            rewardMemberOfferLabel = document.querySelector('.reward-form-section .irving-reward-member-offer .reward-form-title')?.innerHTML;
            rewardMemberOfferLabel = rewardMemberOfferLabel ?? 'Irving Rewards Member Offers';
        }


        let noteComment = rewardMemberOfferList?.length > 0 ? rewardMemberOfferLabel + ':' + rewardMemberOfferList.join(', ') : '';
        if (formData?.comments) {
            noteComment = noteComment + (noteComment ? ', ' : '') + `Anything you want us to know:` + formData.comments;
        }
        const requestBody: BookingPayloadModel = {
            isLocalized: true,
            firstName: formData.name.split(' ')[0] || '',
            lastName: formData.name?.split(' ')[1] || ' ',
            zipCode: zipCode.trim(),
            postalCode: zipCode.trim(),
            email: formData.email,
            state: state,
            city: city,
            phone: formData.phone,
            address: finalAddress,
            address2: formData?.address2 ?? '',
            comments: noteComment,
            signUpForUpdates: false,
            conceptId: Number(conceptId),
            currentPage: url,
            leadSource: "WEB",
            leadOrigin: "WEB",
            phoneType: '',
            isLeadOnly: true,
            campaignName: campaignName || 'N/A',
            callType: "WEB",
            conceptCalledId: Number(conceptId),
            emailOptOut: emailOptOut,
            vendorId: Number(vendorId),
            isTest: isTestEnvironment(),
            conceptCode: conceptCode,
            vendorName: vendorName,
            customerType: 'Residential',
            leadSourceId: defaultLeadSourceId,
            note: noteComment,
            webLocationId: 0
        };

        if (franchiseWebLocationId) {
            requestBody.webLocationId = franchiseWebLocationId;
        }
        if (franchiseId) {
            requestBody.franchiseId = franchiseId;
        }
        const rewardConfirmation = {
            specialOffers: rewardMemberOfferList,
            serviceAddress: (requestBody.address) + (requestBody.address2 ? ' , ' +requestBody.address2:''),
            contactInfo:{
                name: requestBody.firstName + ' ' + requestBody.lastName,
                email: requestBody.email,
                phone: requestBody.phone
            },
            city: requestBody.city,
            state: requestBody.state,
            zipCode: requestBody.zipCode,
            postalCode: requestBody.postalCode,
            address: requestBody.address,
            address2: requestBody.address2,
            comment: formData.comments

        }
        sessionStorage.setItem('rewardConfirmation', JSON.stringify(rewardConfirmation));

        return requestBody;
    }

    public async bookingApiCall(result: any, zipCode: any) {
        startLoader();
        const { franchiseWebLocationId, franchiseId } = result;
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        const campaignName = getCampaignName();
        const brandData = await getBrandDetailsFromBrandJson(conceptCode);
        let formData: any = {
            name: this.rewardFname.value + ' ' + this.rewardLname.value,
            address: this.rewardAddress.value,
            address2: this.rewardAddress2.value,
            city: '',
            state: '',
            zipCode: this.rewardZipCode.value,
            email: this.rewardEmail.value,
            phone: this.rewardPhone.value,
            comments: this.rewardComments.value
        };

        let { city, state, finalAddress } = this.getAddressDetails(formData);
        const payLoadObj = { formData, city, state, finalAddress, zipCode, conceptCode, campaignName, brandData, franchiseWebLocationId, franchiseId };
        let requestBody = this.createRequestBody(payLoadObj);
        const out = await handleMissingData(requestBody.city, requestBody.state, requestBody.zipCode);
        requestBody.city = out?.city || requestBody.city;
        requestBody.state = out?.state || requestBody.state;

        //Optin OputOut Email & Text In 
        requestBody = this.optinEmailCheck(requestBody);

        await bookingUsPostCallWrapper(apiConfig.BOOKING_API_URL, requestBody)
            .then((response: any) => {
                stopLoader();
                storeBookingApiPayload(requestBody, response); //store booking api payload
                // Removed campaign name session storage
                sessionStorage.removeItem('campaignName');

                const submitObj = {
                    event: 'opus_reward_form_submit_ev',
                    status: 'success'
                };
                gtmCustomEventLeadFlow(submitObj);
                this.redirectConfirmationPage();

            })
            .catch((err) => {
                stopLoader();
                this.handleButtonClickAndError('reward-form-error-modal-id', 'reward-form-system-error');
                const submitObj = {
                    event: 'opus_reward_form_submit_ev',
                    status: 'failure'
                };
                gtmCustomEventLeadFlow(submitObj);
            });
    }
    async getBrandData(): Promise<any> {
        const conceptCodeElement = document.getElementById('conceptCode') as HTMLInputElement;
        if (!conceptCodeElement) {
            throw new Error('Concept code element not found');
        }
        const conceptCode = conceptCodeElement.value;
        if (!conceptCode) {
            throw new Error('Concept code is empty');
        }
        return await getBrandDetailsFromBrandJson(conceptCode);
    }

    private async handleError(error: any) {
        stopLoader();
        const brandData = await this.getBrandData();
        let errorMessage = JSON.parse(error?.message);
        if (errorMessage?.message) {
            if (errorMessage.message === 'Full address is needed to narrow down results') {
                const address: any = this.rewardAddress?.value;
                const completeAddress = `${address}, ${this.rewardZipCode?.value}`;
                this.franchiseLookupAddressApi(completeAddress);
            }
        } else {
            this.handleGenericError(brandData);
        }
    }

    public handleGenericError(brandData: any) {
        stopLoader();
        const btnClick = document.getElementById('reward-form-error-modal-id');
        btnClick?.click();
        const zipMessage = document.getElementById('modal-zipcode-error');
        if (zipMessage) {
            zipMessage.innerHTML = `We're sorry. Something went wrong. Please try again later.`;
        }

    }

    public getAddressDetails(formData: any) {
        let item = allAddressList?.filter((item: any) => item.title.trim() == formData?.address?.trim())[0];
        let manualCity = sessionStorage.getItem("manualCity");
        let manualState = sessionStorage.getItem("manualState");
        

        let finalAddress: any = formData?.address;
        let getsuggestionFlag = sessionStorage.getItem("suggestion");
        const suggestAddr = sessionStorage.getItem('suggestedAddress') as string;
        let city = manualCity ?? (item?.address.city || "Not Specified");
        let state = manualState ?? (item?.address.state || "Not Specified");

        if (getsuggestionFlag === "true" && suggestAddr) {
            city = JSON.parse(suggestAddr).city;
            state = JSON.parse(suggestAddr).stateCode;
            finalAddress = JSON.parse(suggestAddr).addr1;
        }

        return { city, state, finalAddress };
    }
    public handleButtonClickAndError(buttonId: string, errorMessageId: string) {
        const btnClick = document.getElementById(buttonId);
        const errorMessage = document.getElementById(errorMessageId);

        if (btnClick) {
            btnClick.click();
        }

        if (errorMessage) {
            errorMessage.classList.remove('hidden');
            const rewardNoServiceError = document.getElementById('reward-form-no-service');
            if (rewardNoServiceError) {
                rewardNoServiceError.classList.add('hidden');
            }
        }
    }
    handleFocusOut() {
        const zipCode = this.rewardZipCode?.value.trim();
        DisclaimerServiceIdentifier(zipCode);
        toggleTermsPolicyBasedOnCountry('.reward-form-section .reward-form-main', zipCode);
    }

    handleCheckboxChange() {
        const serviceReminderDivs = document.querySelectorAll('.service-reminder-main');

    // Check if any divs with the class 'service-reminder-main' are found
    if (serviceReminderDivs.length === 0) {
        console.log('Servcie Disclaimer not authored in CP');
        return; // Exit the function early if no divs are found
    }
        let errorID = null;
        // Loop through each div
        serviceReminderDivs.forEach(div => {
            // Check if the div is visible (does not have the 'hidden' class)
            if (div && !div.classList.contains('hidden')) {
                // Find the checkbox input inside this div
                const checkbox = div.querySelector('input[type="checkbox"]');
                // Find the corresponding error message element
                const errorMsg = div.querySelector('.error-msg');

                // Check if the checkbox and errorMsg exist and if the checkbox is required
                if (checkbox && errorMsg && checkbox instanceof HTMLInputElement && checkbox.required) {
                    // Add event listener to handle checkbox state change
                    checkbox.addEventListener('change', () => {
                        // If the checkbox is checked, hide the error message
                        if (checkbox.checked) {
                            errorMsg.classList.add('hidden');
                        } else {
                            // If the checkbox is unchecked, show the error message
                            errorMsg.classList.remove('hidden');
                            errorID = errorMsg?.id;
                        }
                    });

                    // Initial check: if the checkbox is required and unchecked when the page loads
                    if (checkbox.required && !checkbox.checked) {
                        errorMsg.classList.remove('hidden');
                        errorID = errorMsg?.id;
                    }
                }
            }
        });
        return errorID;
      }
}

// Initialize the RewardForm class
document.addEventListener('DOMContentLoaded', () => {
    const rewardSection =  document.querySelector('.reward-form-section');
    if(rewardSection){
        const rewardForm = new RewardForm();
    }

});