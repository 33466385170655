import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiCaching } from "../config/cachingConfig";
import { useMemo } from "../util/memoization";
import { tns } from "tiny-slider"
interface LiArrayInterface {
    src: string;
    title: boolean;
    description: boolean;
    longdescription: boolean;
    couponexpirydate: boolean;
    printbuttonlabel: boolean;
    printicon: boolean;
    claimbuttonlabel: boolean;
    claimbuttonlink: boolean;
    claimbuttonlinktarget: boolean;
    couponcode: boolean;
    enableFeaturedOffers: boolean;
}
export class OffersTeaser {
    private static instance: OffersTeaser;

    featuredItems: any[] = []; 
    otherItems: any[] = []; 

    flagToCheckIfOffersArePresent = false;

    maxCharactersLength = 30;
    
    constructor() {
        this.init();
    }
    static getInstance(): OffersTeaser {
        if (!OffersTeaser.instance) {
            OffersTeaser.instance = new OffersTeaser();
        }
        return OffersTeaser.instance;
    }

    init() {
        const pageURL = 'special-offers';
        const fullUrl = window.location.origin.split('?')[0];
        this.flagToCheckIfOffersArePresent = this.checkIfUserIsLocalized();
    
        const offerJSONURL = this.flagToCheckIfOffersArePresent
          ? this.getLocalOffersPath() ?? `${fullUrl}/${pageURL}.json`
          : `${fullUrl}/${pageURL}.json`;
    
        // Initial fetch attempt
        this.fetchOffers(offerJSONURL, fullUrl, pageURL);
      }
    
      async fetchOffers(offerJSONURL: string, fullUrl: string, pageURL: string) {
        try {
          const response = await getCachedAPIResponse(offerJSONURL.replace("#", ""), apiCaching.OffersLanding);
          if (!response || typeof response !== 'object') {
            if(this.flagToCheckIfOffersArePresent){
                console.log("No offers present locally, attempting to fetch from National.");
                this.flagToCheckIfOffersArePresent = false;
                await this.fetchNationalOffers(fullUrl, pageURL);
            }
            else{
                this.hideOfferSection()
                throw new Error("Invalid national response data");
            }
          } else {
            this.handleOfferData(response);
          }
        } catch (error) {
          console.error(`${error} - Failed to load the offers JSON from CP`);
          this.hideOfferSection();
        }
      }
    
      async fetchNationalOffers(fullUrl: string, pageURL: string) {
        try {
          const nationalOfferURL = `${fullUrl}/${pageURL}.json`;
          const nationalResponse = await getCachedAPIResponse(nationalOfferURL.replace("#", ""), apiCaching.OffersLanding);
          
          if (!nationalResponse || typeof nationalResponse !== 'object') {
            this.hideOfferSection()
            throw new Error("Invalid national response data");
          }
          
          this.handleOfferData(nationalResponse);
        } catch (error) {
          console.error(`${error} - Failed to load the National offers JSON from CP`);
          this.hideOfferSection();
        }
      }
    
      handleOfferData(response: any) {
        const filteredArray = useMemo(() => this.splitOffersIntoFeaturedAndOther(response?.coupondetails));
        if (!filteredArray) return;
    
        this.featuredItems = filteredArray.featuredItems;
        this.constructHTMLStructure(this.featuredItems);
      }

    hideOfferSection(){
        const offersBlock = document.querySelector(".offers-block-section.coupons-section")as HTMLElement;
        if(offersBlock)
            offersBlock.classList.add("hidden");
    }

    checkIfUserIsLocalized():boolean{
        if(localStorage.getItem('dbaName')){
            return true;
        }
        return false;
    }

    getLocalOffersPath() {
        const dbaName =  localStorage.getItem('dbaName');
        if (dbaName) {
            const brandData = localStorage?.brandDetails ? JSON.parse(localStorage.brandDetails) : null;
            const { weblocation_path_group: hostMapWebLocation} = brandData ?? {};
            const finalPathName = 'special-offers'; //(window.location.pathname).split('/').pop();
            if(hostMapWebLocation){
                return `${window.location.origin}/${hostMapWebLocation}/${dbaName}/${finalPathName}.json`;
            }
            return `${window.location.origin}/${dbaName}/${finalPathName}.json`;
        }
        return null;
    }

    splitOffersIntoFeaturedAndOther(res:any) {
        if(!res)
            return
        this.featuredItems = [];
        this.otherItems = [];
        res.forEach((item: any) => {
            if (item.enableFeaturedOffers) {
                // If enableFeaturedOffers is true, push to featuredItems
                this.featuredItems.push(item);
            } else {
                // If enableFeaturedOffers is false, push to otherItems
                this.otherItems.push(item);
            }
        });
        return {
            featuredItems: this.featuredItems,
            otherItems: this.otherItems
        }
    }

    constructHTMLStructure(featuredItems: LiArrayInterface[]) {
        if (!this.validateFeaturedItems(featuredItems)) return;
    
        const moreOffersList = this.getMoreOffersList();
        if (!moreOffersList) return;
        moreOffersList.style.display = "block";
        const fragment = document.createDocumentFragment();
        featuredItems.forEach((item, i) => {
            const listItem = this.createListItem(item, i);
            if (listItem) {
                fragment.appendChild(listItem);
            }
        });
    
        if (fragment.childNodes.length > 0) {
            moreOffersList.innerHTML = '';
            moreOffersList.appendChild(fragment);
            moreOffersList.addEventListener("click", this.handleClaimClick);
            this.readmoreFunctionality();
            this.setTinySlider.call(this, 0);
            this.attachPrintEvntListner();
        } else {
            console.log(`No offers present so hiding Offers`);
            this.hideOfferSection();
        }
    }
    
    attachPrintEvntListner(){
        const printOfferLinks = document.querySelectorAll('.print-offer-link .print-offer-link-wrap');
        printOfferLinks?.forEach(link => {
            link.addEventListener('click', this.handlePrintClick.bind(this));
        });
    }

    validateFeaturedItems(featuredItems: LiArrayInterface[]): boolean {
        if (!featuredItems || featuredItems.length === 0) {
            console.log(`No offers present so hiding Offers`);
            this.hideOfferSection();
            return false;
        }
        return true;
    }
    
    getMoreOffersList(): HTMLElement | null {
        const moreOffers = document.querySelector(".offers-block-section .offers-block-wrapper") as HTMLElement;
        if (!moreOffers) {
            console.log(`".offers-block-section .offers-block-wrapper" is missing from the DOM so hiding Offers`);
            this.hideOfferSection();
            return null;
        }
    
        const moreOffersList = moreOffers.querySelector(".offers-block-list.offers-slider");
        if (!moreOffersList) {
            console.log(`".offers-block-list.offers-slider" is missing from the DOM so hiding Offers`);
            this.hideOfferSection();
            return null;
        }
    
        return moreOffersList as HTMLElement;
    }
    
    createListItem(item: LiArrayInterface, index: number): HTMLElement | null {
        try {
            const description = item.longdescription ? String(item.longdescription) : '';
            const listItem = document.createElement('li');
            listItem.className = 'offers-block-item tns-item tns-slide-active';
            listItem.id = `tns1-item${index}`;
            listItem.innerHTML = `
                <div class="offers-block-main">
                    <div class="offers-block-data">
                        <div class="offers-block-details">
                            <img src="${item.src}" class="brand-logo" alt="" width="144" height="28" loading="lazy">
                            <p class="block-offer">${item.title}</p>
                            <p class="offers-block-desc">${item.description}</p>
                        </div>
                    </div>
                    <div class="offers-block-disclaimer text-more">${description}</div>
                    <span class="offers-block-expire">Expires: ${item.couponexpirydate}</span>
                    <div class="print-offer-link">
                        <div class="print-offer-link-wrap">
                            <svg class="icon">
                                ${item.printicon}
                            </svg>
                            <a href="#">${item.printbuttonlabel}</a>
                        </div>
                    </div>
                </div>
                <a href="${item.claimbuttonlink}" class="secondary-btn claim-offer" title="" target="${item.claimbuttonlinktarget}">${item.claimbuttonlabel}</a>
                <span style="display:none" id="nblyCpnCode${index}">${item.couponcode}</span>
            `;
    
            return listItem;
        } catch (e) {
            console.log(`Malformed JSON while creating li in loop so hiding Offers`);
            this.hideOfferSection();
            console.log(e);
            return null;
        }
    }

    readmoreFunctionality(){
        const paragraphs = document.querySelectorAll(".text-more");
        const charLimit = this.maxCharactersLength;
        if(!paragraphs)
            return;
        paragraphs.forEach(function(paragraph) {
            const fullText = paragraph.innerHTML;
            const visibleText = fullText?.split(" ").slice(0, charLimit).join(" ");
            const hiddenText = fullText?.split(" ").slice(charLimit).join(" ");
            
            paragraph.innerHTML = visibleText + '<span class="dots">...</span><span class="more-content" style="display:none;"> ' + hiddenText + '</span>';
    
            const button = document.createElement("button");
            button.className = "read-btn";
            button.innerHTML = "Read More";
            button.style.border = "none";
            button.style.background = "none";
            button.style.cursor = "pointer";
            button.style.padding = "0";
            paragraph.appendChild(button); 
    
        
            button.addEventListener("click", function() {
                const dots = paragraph.querySelector(".dots") as HTMLElement;
                const moreContent = paragraph.querySelector(".more-content") as HTMLElement;
                if(!dots || !moreContent)
                    return;
                if (moreContent.style.display === "none") {
                    moreContent.style.display = "inline";
                    dots.style.display = "none";
                    button.innerHTML = "Read Less";
                } else {
                    moreContent.style.display = "none";
                    dots.style.display = "inline";
                    button.innerHTML = "Read More";
                }
            });
        });
    } 

    handlePrintClick = (e: Event): void => {
        e.preventDefault();
        console.log("clicked");
      
        const target = e.target as HTMLElement;
        const li = target.closest('li') as HTMLElement | null;
        const parentUl = li?.closest('ul') as HTMLElement | null;
      
        if (!li || !parentUl) {
          console.warn("No parent <li> or <ul> element found.");
          return;
        }
      
        // Clone the li element to modify it without affecting the original page
        const clonedLi = li.cloneNode(true) as HTMLElement;
        const disclaimer = clonedLi.querySelector('.offers-block-disclaimer') as HTMLElement | null;
      
        if (disclaimer) {
          // Remove unwanted elements from the disclaimer
          const dots = disclaimer.querySelector('.dots');
          const moreContent = disclaimer.querySelector('.more-content');
          const readButton = disclaimer.querySelector('.read-btn');
      
          dots?.remove();
          moreContent?.remove();
          readButton?.remove();
        }
      
        const ulClasses = parentUl.className;
        const liContent = clonedLi.innerHTML;
      
        // Open the print window in fullscreen
        const printWindow = window.open('', '', 'width=' + screen.width + ',height=' + screen.height);
      
        if (printWindow) {
          const inlineStyles = `
           
            .print-offer-link, .claim-offer, .read-btn {
              visibility: hidden !important;
            }
            .more-content{
                display: block !important;
            }
            /* Additional styles can go here */
          `;
      
          // Wrap `ul` inside `div` with `offers-block-wrapper` and add content to print window
          printWindow.document.write(`
            <html>
              <head>
                <title>Print</title>
                <style>${inlineStyles}</style>
                <link rel="stylesheet" type="text/css" href="/brand/_assets/css/service.css" media="all">
                <link rel="stylesheet" type="text/css" href="/brand/_assets/css/main.css" media="all">
                <link rel="stylesheet" type="text/css" href="/brand/_assets/css/brand-config.css" media="all">
                <link rel="stylesheet" type="text/css" href="/brand/_assets/css/header.css" media="all">
                <link rel="stylesheet" type="text/css" href="/brand/_assets/css/footer.css" media="all">
                <link rel="stylesheet" type="text/css" href="/brand/_assets/css/glossary.css" media="all">
              </head>
              <body>
                <div class="offers-block-wrapper">
                  <ul class="${ulClasses}">
                    <li>${liContent}</li>
                  </ul>
                </div>
              </body>
            </html>
          `);
      
          // Wait for the new content to load before printing
          printWindow.document.close();
          printWindow.addEventListener('load', () => {
            printWindow.focus();
            printWindow.print();
            printWindow.close();
          });
        } else {
          console.error("Failed to open print window.");
        }
    };
      
    isAnchorWithClass = (target: HTMLElement, className: string): boolean => {
        return target.tagName.toLowerCase() === 'a' && target.classList.contains(className);
    };

    getLastDigitFromId = (id: string): string | null => {
        const regex = /tns1-item(\d+)$/;
        const match = regex.exec(id);
        return match ? match[1] : null;
    };
    
    getSiblingSpanValue = (lastDigit: string): string | null => {
        const newId = `nblyCpnCode${lastDigit}`;
        const siblingSpan = document.getElementById(newId) as HTMLSpanElement;
        return siblingSpan?.textContent ?? null;
    };
    
    handleClaimClick = (e: Event) => {
        e.preventDefault();
        const target = e.target as HTMLElement;
        if (this.isAnchorWithClass(target, 'claim-offer')) {
            const href = (target as HTMLAnchorElement).href;
            const parentDom = target.closest('li') as HTMLElement;
            if(parentDom){
                this.setOffersInSession(parentDom, href);
            }
        }
    };

    setOffersInSession(targetElement: HTMLElement, href:any) {
        const obj = {
            'title' : '',
            'description' : '',
            'couponexpirydate': '',
            'longdescription': '',
            'couponcode': ''
        }
        const parentId = targetElement.id;
        if(parentId){
            const lastDigit = this.getLastDigitFromId(parentId);
                const spanValue = this.getSiblingSpanValue(lastDigit ?? '0');
                if (spanValue) {
                    obj.couponcode = spanValue;
                } else {
                    console.log("No Coupon code found");
                }
            } else {
                console.log("Parent <li> id not found");
        }
        obj.title = targetElement.querySelector('.offers-block-details .block-offer')?.textContent ?? '';
        obj.description = targetElement.querySelector('.offers-block-details .offers-block-desc')?.textContent ?? '';
        obj.couponexpirydate = targetElement.querySelector('.offers-block-expire')?.textContent ?? '';
        const firstTxt = targetElement.querySelector('.offers-block-disclaimer')?.textContent ?? '';
        const secondTxt = targetElement.querySelector('.offers-block-disclaimer .more-content')?.textContent ?? '';
        obj.longdescription = `${firstTxt} ${secondTxt}`;
        sessionStorage.setItem('offerDetails', JSON.stringify(obj));
        window.location.href = href;
    }

    setTinySlider(currentIndex: any) {

        try {
            tns({
                container: '.offers-slider',
                loop: false,
                gutter: 8,
                items: 4,
                slideBy: 'page',
                nav: false,
                autoplay: false,
                speed: 400,
                swipeAngle: false,
                autoplayButtonOutput: false,
                mouseDrag: true,
                lazyload: true,
                controlsContainer: "#customize-controls",
                responsive: {
                    350: {
                        items: 1,
                    },
                    640: {
                        items: 2,
                    },
                    768: {
                        items: 4,
                    },
                    1024: {
                        items: 4,
                    }
                }
            });

        } catch (error) {

        }
    }
    
    
}

document.addEventListener("DOMContentLoaded", () => {
    const DomCheck = document.querySelector(".offers-block-wrapper .offers-block-list.offers-slider") as HTMLElement;
    if(DomCheck)
        OffersTeaser.getInstance()
});