import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import { hmsProgressBarUpdate, removeAllEventListeners } from "../util/share";

interface Window {
    sharedData?: Record<string, any>;
}

export class GiftPurchase {
    readonly PHONE_NUMBER_LENGTH: number = 14;

    constructor() {
        this.init();
    }

    private init() {
        this.addNextBtnEventListener();
        this.addInputEventListeners();
        this.backBtn();

        const phoneNumberInput = document.getElementById(`purchaser-phone-number`) as HTMLInputElement;

        if(phoneNumberInput){
            phoneNumberInput.addEventListener('input', this.handleMobileInput.bind(this));
        }
        
    }

    private backBtn() {

        const backButton = removeAllEventListeners('#gc-purchaser-details .btn-cancel');
        if (backButton) {
            backButton.addEventListener('click', this.handleBackButtonClick.bind(this));
        }
    }

    private handleBackButtonClick() {
        const step1 = document.getElementById("gc-recipients-details");
        const step2 = document.getElementById("gc-purchaser-details");
        hmsProgressBarUpdate('0%', 0);
        window.scrollTo(0,0);

        if (step1 && step2) {
            step1.classList.add('form-step-active');
            step2.classList.remove('form-step-active');
        }
    }

    private addNextBtnEventListener() {

        const nextButton = removeAllEventListeners('#gc-purchaser-details .submit-gift');
        if (nextButton) {
            nextButton.addEventListener('click', this.handleNextButtonClick.bind(this));
        }
    }

    private handleNextButtonClick() {
        if (this.isValidForm()) {

            const firstNameInput = (document.getElementById(`purchaser-first-name`) as HTMLInputElement)?.value;
            const lastNameInput = (document.getElementById(`purchaser-last-name`) as HTMLInputElement)?.value;;
            const phoneNumber = (document.getElementById(`purchaser-phone-number`) as HTMLInputElement)?.value;;
            const emailInput = (document.getElementById(`purchaser-email`) as HTMLInputElement)?.value;;
            
            if (window?.giftRecipient) {
                window.giftRecipient = window.giftRecipient.map((dd: Record<string, any>) => {
                    if (dd) {
                        return {
                            ...dd,
                            PurchaserEmail: emailInput,
                            PurchaserFirstName: firstNameInput,
                            PurchaserLastName: lastNameInput,
                            PurchaserPhoneNumber: phoneNumber,
                            PurchaserPhoneType: "",
                            Campaign: ""
                        };
                    }
                    return dd;
                });

                this.prePurchaseCertificate(); 
            }

        }
    }

    private async prePurchaseCertificate(){

        try {
            const apiUrl = `${process.env.JS_CORE_API_BASE_URL}/GiftCertificateGroups/PrePurchase`;
            const request = {
                method: 'POST',
                url: apiUrl,
                data: window?.giftRecipient
            };
    
            const prePurchaseRes: any = await apiRequest(request);
            
            if(prePurchaseRes){
                const invoicenNumber = prePurchaseRes?.GiftCertificateGroupId
                const paymentPostUrl = `${apiConfig.paymentPostUrl}?invoicenumber=${invoicenNumber}&appkey=${process.env.JS_MERCHANT_KEY}`
                console.log(paymentPostUrl,'PaymentPostUrl');
                //  const step1 = document.querySelectorAll(".contact-us-section.form-step") as NodeListOf<Element>;
                // let form = step1[0] as HTMLFormElement;
                    const form = removeAllEventListeners('#gc-purchaser-details')
                    if(form) {
                        form.action = paymentPostUrl;
                        form.method = 'POST';
                        form.submit();
                    }
            }

        } catch (error) {
            const errorModal = document.querySelector('.gift-certificate-form #error-modal') as HTMLElement;

            if(errorModal){
                errorModal.classList.remove('hidden');
                (document.querySelector('#gc-error-modal-btn') as HTMLAnchorElement)?.click()
            }
            
        }
       
    }


    private addInputEventListeners() {
        const inputs = document.querySelectorAll('#gc-purchaser-details input');
        inputs.forEach((input: any) => {
            input?.addEventListener('input', this.handleInputEvent.bind(this, input));
        });
    }

    private handleInputEvent(input: HTMLInputElement | HTMLTextAreaElement) {
        const errors: Record<string, string> = {};
       if (input.name.includes('first-name')){
            this.validateFirstName(input, errors);
        }
        else if(input.name.includes('last-name')) {
            this.validateLastName(input, errors);
        } else if (input.name.includes('email')) {
            if (input.name.includes('confirm')) {
                this.validateConfirmEmail(input, errors);
            } else {
                this.validateEmail(input, errors);
            }
        } 
      
        if (Object.keys(errors).length > 0) {
            this.showErrors(errors);
        }
        if (!errors[input?.id]) {
            this.clearFieldError(input?.id);
        }
    }

    private showErrors(errors: Record<string, string>) {
        Object.keys(errors).forEach(fieldId => {
            const field = document.getElementById(fieldId);
            const fieldError = document.getElementById(`${fieldId}-error-msg`);
            if (field) {
                field.classList.add("invalid-field");
                if (fieldError) {
                    fieldError.innerHTML = errors[fieldId];
                    fieldError.classList.remove('hidden');
                }
            }
        });

        const firstErrorFieldId = Object.keys(errors)[0];
        if (firstErrorFieldId) {
            const firstErrorField = document.getElementById(firstErrorFieldId);
            if (firstErrorField) {
                firstErrorField.focus();
            }
        }
    }
    private clearFieldError(fieldId: string) {
        const field = document.getElementById(fieldId);
        const fieldError = document.getElementById(`${fieldId}-error-msg`);
        if (field) {
            field.classList.remove("invalid-field");
            if (fieldError) {
                fieldError.classList.add('hidden');
            }
        }
    }

    private validateLastName(field: any, errors: Record<string, string>) {
        if(field?.value ==''){
            this.hideError(field?.id);
            return
        }
        if (!field.value || !REGEX.sendName.test(field.value)) {
            const errorMessage = `Please enter a valid last name`;
            errors[field.id] = errorMessage;
        }
    }
    private validateFirstName(field: any, errors: Record<string, string>) {
        if(field?.value ==''){
            this.hideError(field?.id);
            return
        }
        if (!field.value || !REGEX.sendName.test(field.value)) {
            const errorMessage = `Please enter a valid first name`;
            errors[field.id] = errorMessage;
        }
    }

    private validateEmail(field: any, errors: Record<string, string>) {
        if(field?.value ==''){
            this.hideError(field?.id);
            return
        }
        if (!field.value || !REGEX.sendEmail.test(field.value)) {
            const errorMessage = 'Please enter a valid email address';
            errors[field.id] = errorMessage;
        }
    }

    private validateConfirmEmail(field: any, errors: Record<string, string>) {
        if(field?.value ==''){
            this.hideError(field?.id);
            return
        }
        const emailField = document.getElementById('purchaser-email') as HTMLInputElement;
        if (!field.value || field.value !== emailField.value) {
            const errorMessage = 'Email addresses do not match';
            errors[field.id] = errorMessage;
        }
    }
    hideError(id: any) {
        const fieldError: any = document.getElementById(`${id}-error-msg`);
        fieldError.classList?.add('hidden');
    }

    private handleMobileInput(e: Event): void {
        e.preventDefault();
        const reg = REGEX.mobileNumberRegex;
        const phoneNumberInput = document.getElementById(`purchaser-phone-number`) as HTMLInputElement;
        const val = phoneNumberInput?.value;
        const x = val?.replace(/\D/g, '').match(reg);
        const formattedVal = x ? `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}` : '';

        if (phoneNumberInput.value != '' && phoneNumberInput?.value.length < 14 && formattedVal.length < 14) {
            const fieldError: any = document.getElementById(`purchaser-phone-number-error-msg`);
            if(fieldError){
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = 'Invalid phone format.'  
            }
        }
        else{
            this.hideError('purchaser-phone-number')
        }
        if (x) {
            phoneNumberInput!.value = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}`;
        }

    }
    private isValidForm() {

        document.querySelectorAll('.error-msg').forEach(e => e.classList.add('hidden'));
        document.querySelectorAll('.invalid-field').forEach(e => e.classList.remove('invalid-field'));

        const errors: Record<string, string> = {};

        const firstNameInput = document.getElementById(`purchaser-first-name`) as HTMLInputElement;
        const lastNameInput = document.getElementById(`purchaser-last-name`) as HTMLInputElement;
        const phoneNumber = document.getElementById(`purchaser-phone-number`) as HTMLInputElement;
        const emailInput = document.getElementById(`purchaser-email`) as HTMLInputElement;
        const confirmEmailInput = document.getElementById(`purchaser-confirmemail`) as HTMLInputElement;

          // Validate first name input
          if (firstNameInput?.required && !firstNameInput.value) {
            const errorMessage = document.getElementById(`${firstNameInput.id}-error-msg`)?.innerText ?? 'Please enter a valid first name';
            errors[firstNameInput.id] = errorMessage;
        } else if (firstNameInput && firstNameInput?.required && !REGEX.sendName.test(firstNameInput.value)) {
            errors[firstNameInput.id] = "Please enter a valid first name";
        }

        // Validate last name input
        if (lastNameInput?.required && !lastNameInput.value) {
            const errorMessage = document.getElementById(`${lastNameInput.id}-error-msg`)?.innerText ?? 'Please enter a valid last name';
            errors[lastNameInput.id] = errorMessage;
        } else if (lastNameInput && lastNameInput?.required && !REGEX.sendName.test(lastNameInput.value)) {
            errors[lastNameInput.id] = "Please enter a valid last name";
        }

        // Validate email input
        if (emailInput?.required && !emailInput.value) {
            const errorMessage = document.getElementById(`${emailInput.id}-error-msg`)?.innerText ?? 'Please enter a valid email address';
            errors[emailInput.id] = errorMessage;
        }

        // Validate email format
        if (emailInput?.value && !REGEX.sendEmail.test(emailInput.value)) {
            const errorMessage = 'Please enter a valid email address';
            errors[emailInput.id] = errorMessage;
        }

        // Validate confirm email input
        if (confirmEmailInput?.required && !confirmEmailInput.value) {
            const errorMessage = document.getElementById(`${confirmEmailInput.id}-error-msg`)?.innerText ?? 'Please confirm the email address';
            errors[confirmEmailInput.id] = errorMessage;
        }

        // Validate email match
        if (emailInput && confirmEmailInput && emailInput.value !== confirmEmailInput.value) {
            const errorMessage = 'Email addresses do not match';
            errors[confirmEmailInput.id] = errorMessage;
        }

        if (phoneNumber?.required && !phoneNumber.value) {
            const errorMessage = document.getElementById(`${phoneNumber.id}-error-msg`)?.innerText ?? 'Please enter a valid Phone Number';
            errors[phoneNumber.id] = errorMessage;
        } else if (phoneNumber && phoneNumber?.required && phoneNumber.value.length < this.PHONE_NUMBER_LENGTH) {
            errors[phoneNumber.id] = "Please enter a valid Phone Number";
        }

        const firstErrorFieldId = Object.keys(errors)[0];
        if (firstErrorFieldId) {
            const firstErrorField = document.getElementById(firstErrorFieldId);
            if (firstErrorField) {
                firstErrorField.focus();
            }
        }

        Object.keys(errors).forEach(fieldId => {
            const field = document.getElementById(fieldId);
            const fieldError = document.getElementById(`${fieldId}-error-msg`);
            if (field?.parentNode) {
                field.classList.add("invalid-field");
                if (fieldError) {
                    fieldError.innerHTML = errors[fieldId];
                    fieldError.classList.remove('hidden');
                }
            }
        });

        return Object.keys(errors).length === 0;
    }
}