import { getBrandDetailsFromBrandJson } from '../util/share';
import { SearchServiceInitial } from './search-service-initial';

export class SearchServiceInput {
  public searchService: SearchServiceInitial;
  public searchInput: HTMLInputElement | null = null;
  public debounceTimeout: ReturnType<typeof setTimeout> | null = null;

  constructor() {
    this.searchInput = document.getElementById('glb-search-service') as HTMLInputElement;

    const searchInputForm = document.querySelector('.header-search-form');
    const searchInputForm2 = document.querySelector('.sidenav-search');
    const searchInputForm3 = document.querySelector('.service-search');
    
    if (searchInputForm) {
      searchInputForm.addEventListener('submit',(e)=> this.handleSearch.call(this,e, searchInputForm));
    }

    if (searchInputForm2) {
      searchInputForm2.addEventListener('submit',(e)=> this.handleSearch.call(this, e, searchInputForm2));
    }
    if (searchInputForm3) {
      searchInputForm3.addEventListener('submit',(e)=> this.handleSearch.call(this, e, searchInputForm3));
    }


    const urlParams = new URLSearchParams(window.location.search);
    const getSearch = urlParams.get('search');

    if (getSearch) {
      const s1 = document.querySelector(`.${searchInputForm?.className} input`) as HTMLInputElement;
      const s2 = document.querySelector(`.${searchInputForm2?.className} input`) as HTMLInputElement;
      const s3 = document.querySelector(`.${searchInputForm3?.className} input`) as HTMLInputElement;
      if(s1)
      s1.value = getSearch
      if(s2)
      s2.value = getSearch
      if(s3)
      s3.value = getSearch

      
    }

    this.searchService = new SearchServiceInitial();
  }

  public async fetchData(searchTerm: string, event: Event): Promise<void> {
    try {
      const serviceData = sessionStorage.getItem('filterService');
      const filterService: any[] | null = JSON.parse(serviceData || 'null');
      if (filterService !== null) {
        this.searchService.searchServiceFunctionality();
      }
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  }

  public async handleSearch(event: Event, ids:any): Promise<void> {
    event.preventDefault();
    event.stopPropagation();

    const valINput = document.querySelector(`.${ids.className} input`) as HTMLInputElement;
    // const searchInput2 = document.querySelector('.sidenav-search #glb-search-service') as HTMLInputElement;
    const searchTerm = valINput?.value;

    const origin = window.location.origin;
    let url = searchTerm ? `${origin}/search?search=${searchTerm}` : `${origin}/search`;

    try {

      const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;

      if(searchTerm && conceptCode){
        const brandDetails = await getBrandDetailsFromBrandJson(conceptCode) as any ;
        if(brandDetails?.includeTrailingSlash){
          url = `${origin}/search/?search=${searchTerm}`;
        }
      }
      
    } catch (error) {
      
    }
  

    window.location.href = url;

    this.fetchData(searchTerm, event);
  }
}

new SearchServiceInput();
