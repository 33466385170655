import axios from "axios";
import { getBrandDetailsFromBrandJson } from "../util/share";

type address = {
    locationDoingBusinessAs: string,
    address: string,
    address2: string,
    state: string,
    country: string,
    city: string,
    zip: string,
    stateLicenseNumbers: string,
    displayAddressSwitch: boolean
}
export class DisplayAddress {
    weblocationID: any;
    hostname: string;
    isLower: boolean;
    constructor() {
        const localWebID = sessionStorage.getItem("franchiseWebLocationId");
        const webID = document.getElementById('weblocationId') as HTMLInputElement;
        this.weblocationID = webID?.value ? webID?.value : localWebID;
        this.hostname = window.location.hostname;
        this.isLower = (this.hostname.indexOf("nblydev") != -1 || this.hostname.indexOf("nblysbx") != -1 || this.hostname.indexOf("nblytest") != -1);
        this.getFullAttrData();
    }
    async getFullAttrData() {
        if (this.weblocationID) {
            // const apiDomain = this.isLower ? process.env.JS_API_URL : "https://api.neighborly.com";
            // const apiKey = this.isLower ? process.env.JS_API_KEY : "pneiweb-9pbaOywNKQj853D";
            // const franchiseGroup = this.isLower ? process.env.JS_FranchiseGroup_URL : "franchisegroup-api";

            const apiDomain = process.env.JS_API_URL;
            const apiKey = process.env.JS_API_KEY;
            const franchiseGroup = process.env.JS_FranchiseGroup_URL;

            const response = await axios.get(`${apiDomain}/${franchiseGroup}/v1/public/fullattribute/franchiseWeblocations/search?franchiseWeblocationId=${this.weblocationID}&apikey=${apiKey}`);

            localStorage.setItem("fullAttrOnLoad", JSON.stringify(response));
            this.displayAddressHandler(response.data);
            this.displayPhoneNumber(response.data);

            const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
            const brandData = await getBrandDetailsFromBrandJson(conceptCode);

            const mapOnOffSwitch = response?.data?.mapOnOffSwitch;
            if(brandData?.check_map_ces){
                if(!mapOnOffSwitch && response.data?.hasOwnProperty("mapOnOffSwitch")){
                    document.querySelectorAll('.map-img-box')?.forEach((element)=> element?.remove())
                }
            }
        }
    }
    public displayPhoneNumber(data: any) {
        let phoneNumber:any;
        if(data.callTrackings?.length !=0){
            data?.callTrackings.forEach((type:any)=>{
                if(type.callTrackingTypeDescription === "WebsiteTracking"){
                    phoneNumber = type.callTrackingNumber;
            }
            });
        }
        if(!phoneNumber){
            phoneNumber = data.mainPhoneNumber;
        }
        
        const phoneFormat:any = this.formatPhoneNumber(phoneNumber);
        const mobNumber = document.querySelector('.icon-block-text .card-text') as HTMLAnchorElement;

        if (phoneFormat && mobNumber) {
            mobNumber.innerHTML = phoneFormat;
            mobNumber.href = `tel:${phoneNumber}`;
        }
        return mobNumber
    }

    public formatPhoneNumber(phoneNumberString:any) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          var intlCode = (match[1] ? '+1 ' : '');
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }

    public displayAddressHandler(data: address) {
        const { locationDoingBusinessAs, address, address2, state, country, city, zip, stateLicenseNumbers, displayAddressSwitch } = data;
        const addressContainer: any = document.querySelector('.contact-info .icon-block-text');
        if(addressContainer){
            addressContainer.innerHTML = ''; // Clearing existing contents
            const formattedAddress = `${city}, ${state} ${zip}`;

            const addressElement = document.createElement('address');

            const titleElement = document.createElement('p');
            titleElement.className = 'card-title';
            titleElement.textContent = locationDoingBusinessAs;

            const addressLine1Element = document.createElement('p');
            addressLine1Element.className = 'card-subtitle';
            addressLine1Element.textContent = address;

            const addressLine2Element = document.createElement('p');
            addressLine2Element.className = 'card-subtitle';
            addressLine2Element.textContent = address2;

            const formattedAddressElement = document.createElement('p');
            formattedAddressElement.className = 'card-subtitle';
            formattedAddressElement.textContent = formattedAddress;

            const licenseNumberElement = document.createElement('p');
            licenseNumberElement.className = 'card-subtitle';
            licenseNumberElement.textContent = (stateLicenseNumbers[0] != null) ? `License #${stateLicenseNumbers}` : '';

            addressElement.appendChild(titleElement);

            if(displayAddressSwitch){
            addressElement.appendChild(addressLine1Element);
            addressElement.appendChild(addressLine2Element);
            }

            addressElement.appendChild(formattedAddressElement);
            addressElement.appendChild(licenseNumberElement);

            addressContainer.appendChild(addressElement);
        }
    }
}

new DisplayAddress();