import axios from 'axios';

export class UseMyLocationSearch {
    constructor(){
        const useLocationButton = document.querySelector('.modal-search .use-my-location-btn');
        const useLocationButtonHms = document.querySelector('.location-search-form .use-my-location-btn'); 
        if(useLocationButton){
            useLocationButton.addEventListener('click', this.getCurrentLocation.bind(this))
        }
        if(useLocationButtonHms){
            useLocationButtonHms?.addEventListener('click', this.getCurrentLocation.bind(this))
        }
    }
    async getCurrentLocation(event:any) {
        event.stopPropagation();
        event.preventDefault();
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(e) {
                let LatValue = e.coords.latitude;
                let LngValue = e.coords.longitude;
                getZipFromLatLng(LatValue, LngValue)
            });

            async function getZipFromLatLng(LatValue: any, LngValue: any) {
                const localSearchModal = document.getElementById('local-search-modal') as HTMLInputElement;
                const localSearchModalInputHms = document.getElementById('location-search-input') as HTMLInputElement;
                if (LatValue != '' && LngValue != '') {
                    let api_Key = 'NSFzJWiXqllZTvP4jbKP6vNbb_K9NHSIJVKJt0uFfTo';
                    let ZipCodeValue: any;
                      let getLocDetails_hereapinURL = `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${LatValue}%2C${LngValue}&lang=en-US&apikey=${api_Key}`;
                      const response = await axios.get(getLocDetails_hereapinURL);
                            if (response.data) {
                                 var results = response.data.items;
                                for (let key in results[0].address) {
                                    if(key.indexOf('postalCode') > -1) {
                                        ZipCodeValue = results[0].address.postalCode;
                                    }
                                }
                               if(ZipCodeValue.includes('-')) {
                                ZipCodeValue = ZipCodeValue.split('-')[0];
                                localSearchModal && (localSearchModal.value = ZipCodeValue);
                                localSearchModalInputHms && (localSearchModalInputHms.value = ZipCodeValue);
                                }else {
                                localSearchModal && (localSearchModal.value = ZipCodeValue)
                                localSearchModalInputHms && (localSearchModalInputHms.value = ZipCodeValue);

                               }
                            }
                            else {
                                console.log("Geolocation is not available in this browser.");
                            }
                }
            }
        }
    }
}


const useMyLocation = new UseMyLocationSearch();

