import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiCaching } from "../config/cachingConfig";
import { useMemo } from "../util/memoization";

interface FreshDataFromPagination {
    LoadMoreOffersJson: string;
    EnableLoadMore: boolean;
}

export class OffersLanding {
    private static instance: OffersLanding;
    maxOffersToDisplay: number = 8; // This is the initial max items to display
    paginationOffset = 4; // This is to check how many items to display on click of load more
    featuredItems: any[] = [];
    otherItems: any[] = [];
    skipItems: number = 8;

    freshDataFromPagination: FreshDataFromPagination = {
        LoadMoreOffersJson: "",
        EnableLoadMore: false
    };

    urlForPagination: string = '';
    urlForLocalization: string = '';

    constructor() {
        this.init();
    }

    static getInstance(): OffersLanding {
        if (!OffersLanding.instance) {
            OffersLanding.instance = new OffersLanding();
        }
        return OffersLanding.instance;
    }

    init() {
        let offerJSONURL = '';
        // const fullUrl = window.location.href.split('?')[0];
        const fullUrl = `${window.location.origin}/special-offers`;
        if (!this.checkIfUserIsLocalized()) {
            offerJSONURL = `${fullUrl}.json`;
        } else {
            offerJSONURL = this.getLocalOffersPath() ?? `${fullUrl}.json`;
        }
        this.constructURLs();

        const isLocalUserInNational = this.checkIfLocalUserInNational();
        if(isLocalUserInNational)
            this.reconstructHTML(offerJSONURL);
        else{
            this.getRawJSONObject(offerJSONURL)
        }
    }

    async getRawJSONObject(offerJSONURL: string) {
        try {
            // Helper function to fetch and process response
            const fetchAndProcessResponse = async (url: string) => {
                const response = await getCachedAPIResponse(this.transformURL(url), apiCaching.OffersLanding);
                if (!response || typeof response !== 'object') throw new Error("Invalid response data");
    
                const filteredArray = useMemo(() => this.splitOffersIntoFeaturedAndOther(response?.coupondetails));
                if (!filteredArray) return;
    
                this.featuredItems = filteredArray.featuredItems;
                this.otherItems = filteredArray.otherItems;
    
                if (this.otherItems.length > this.maxOffersToDisplay) {
                    this.showLoadMoreButton(true);
                    this.handleBackgroundCalls();
                }
                else{
                    this.showLoadMoreButton(false);
                }
                this.attachEventListnerForClaim();
                this.readmoreFunctionality();
                this.attachPrintEvntListner();
            };
    
            // Try the main URL first, fall back to secondary URL if needed
            try {
                await fetchAndProcessResponse(offerJSONURL);
            } catch (error) {
                if (!this.checkIfLocalUserInNational()) throw error;
    
                // Fallback to secondary URL if local user is national
                const finalPathName = ((/^\/?(.*?)(\/?)$/.exec(window.location.pathname) ?? [])[1]?.split('/').join('^')) ?? '';
                const fallbackURL = `${window.location.origin}/special-offers.json`;
                this.urlForPagination = `${window.location.origin}/specialofferloadmore/${this.skipItems}/${finalPathName}/none`;
                await fetchAndProcessResponse(fallbackURL);
            }
        } catch (error) {
            console.error(error + " Failed to load the offers JSON from CP");
        }
    }
    

    reconstructHTML(offerJSONURL:string){
        const DOMToReplace = document.querySelector(".sp-offers-section")as HTMLElement;
        if(!DOMToReplace)
            return
        DOMToReplace.innerHTML = "";
        getCachedAPIResponse(this.transformURL(this.urlForLocalization), apiCaching.OffersLanding).then((response) => {
            if (!response) {
                throw new Error("Invalid response data");
            }
            DOMToReplace.innerHTML = response;
            this.getRawJSONObject(offerJSONURL);
            const loadMoreButton = this.getLoadMoreButton();
            if (!loadMoreButton) return;
            loadMoreButton.addEventListener("click", this.handleLoadMoreClick);
            this.attachEventListnerForClaim();
            this.readmoreFunctionality();
            this.attachPrintEvntListner();
        }).catch((error) => {
            console.error(error + " Failed to load localized HTML from URL Transformer");
        });
    }

    transformURL(URL:string){
        if(URL.includes("localhost")){
            URL = URL.replace("localhost:7032","dig-www-nei-asv2-test.nblydev.com")
        }
        URL = URL.replace("#", "");
        URL = URL.replace("/.json", ".json");
        return URL;
    }

    checkIfLocalUserInNational(){
        const isUserLocallized = this.checkIfUserIsLocalized();
        if(!isUserLocallized)
            return false
        const currentUrl = window.location.href;
        const dbaName = localStorage.getItem('dbaName')
        if(dbaName){
            if (!currentUrl.includes(dbaName))
                return true
        }
        return false;
    }

    constructURLs() {
        const dbaName = localStorage.getItem('dbaName');
        const finalPathName = ((/^\/?(.*?)(\/?)$/.exec(window.location.pathname) ?? [])[1]?.split('/').join('^')) ?? '';
        if (dbaName) {
            this.urlForPagination = `${window.location.origin}/specialofferloadmore/${this.skipItems}/${finalPathName}/${dbaName}`;
            this.urlForLocalization = `${window.location.origin}/specialofferdata/${finalPathName}/${dbaName}`;
        } else {
            this.urlForPagination = `${window.location.origin}/specialofferloadmore/${this.skipItems}/${finalPathName}/none`;
            this.urlForLocalization = `${window.location.origin}/specialofferdata/${finalPathName}/none`;
        }
    }

    getLocalOffersPath() {
        const dbaName = localStorage.getItem('dbaName');
        if (dbaName) {
            const brandData = localStorage?.brandDetails ? JSON.parse(localStorage.brandDetails) : null;
            const { weblocation_path_group: hostMapWebLocation } = brandData ?? {};
            const finalPathName = 'special-offers'; //(window.location.pathname).split('/').pop();
            if (hostMapWebLocation) {
                return `${window.location.origin}/${hostMapWebLocation}/${dbaName}/${finalPathName}.json`;
            }
            return `${window.location.origin}/${dbaName}/${finalPathName}.json`;
        }
        return null;
    }

    splitOffersIntoFeaturedAndOther(res: any) {
        if (!res) return;
        this.featuredItems = [];
        this.otherItems = [];
        res.forEach((item: any) => {
            if (item.enableFeaturedOffers) {
                // If enableFeaturedOffers is true, push to featuredItems
                this.featuredItems.push(item);
            } else {
                // If enableFeaturedOffers is false, push to otherItems
                this.otherItems.push(item);
            }
        });
        return {
            featuredItems: this.featuredItems,
            otherItems: this.otherItems
        };
    }

    private showLoadMoreButton(display: boolean) {
        const loadMoreButton = this.getLoadMoreButton();
        if (!loadMoreButton) return;

        if (display) {
            loadMoreButton.classList.remove("hidden");
            loadMoreButton.addEventListener("click", this.handleLoadMoreClick);
        } else {
            loadMoreButton.classList.add("hidden");
            loadMoreButton.removeEventListener("click", this.handleLoadMoreClick);
        }
    }

    private getLoadMoreButton(): HTMLElement | null {
        const moreOffers = document.querySelectorAll(".sp-offers-section .sp-offers-wrap")[1] as HTMLElement;
        return moreOffers?.querySelector(".cta-wrapper");
    }

    checkIfUserIsLocalized(): boolean {
        return !!localStorage.getItem('dbaName');
    }

    handleLoadMoreClick = (e: Event) => {
        e.preventDefault();
        this.displayMoreItems();
    };

    handleBackgroundCalls() {
        getCachedAPIResponse(this.urlForPagination, apiCaching.OffersLanding).then((response) => {
            if (!response || typeof response !== 'object') {
                throw new Error("Invalid response data / Failed to load new offers from Backend");
            }
            this.skipItems += this.paginationOffset;
            this.freshDataFromPagination = response;
            this.constructURLs();
        }).catch((error) => {
            console.error(error + " Failed to load new offers from Backend");
        });
    }

    private displayMoreItems() {
        const moreOffersElement = document.querySelectorAll(".sp-offers-section .sp-offers-wrap")[1] as HTMLElement;
        if (!moreOffersElement) return;

        const moreOffersListElement = moreOffersElement.querySelector(".sp-offers-list.card-tile");
        if (!moreOffersListElement) return;

        if (this.freshDataFromPagination) {
            const tempContainer = document.createElement('div');
            tempContainer.innerHTML = this.freshDataFromPagination.LoadMoreOffersJson;
    
            const fragment = document.createDocumentFragment();
            const liItems = tempContainer.querySelectorAll('li');
            liItems.forEach(li => fragment.appendChild(li));
    
            moreOffersListElement.appendChild(fragment);
        }
        if (this.freshDataFromPagination.EnableLoadMore) {
            this.showLoadMoreButton(true);
            this.handleBackgroundCalls();
        } else {
            this.showLoadMoreButton(false);
        }
    }

    attachEventListnerForClaim(){
        const mainContainer = document.querySelector(".sp-offers-section .container")as HTMLElement;
        if(!mainContainer)
            return;
        mainContainer.addEventListener("click", this.handleClaimClick);
    }

    isAnchorWithClass = (target: HTMLElement, className: string): boolean => {
        return target.tagName.toLowerCase() === 'a' && target.classList.contains(className);
    };
    
    getLastDigitFromId = (id: string): { digits: string | null, baseId: string | null } => {
        const regex = /(tns1-item|moreoffer-item|featured-offer-item)(\d+)$/;
        const match = regex.exec(id);
        if (match) {
            return {
                digits: match[2],
                baseId: match[1]
            };
        }
        return {
            digits: null,
            baseId: null
        };
    };
    
    getSiblingSpanValue = (targetVal: any): string | null => {
        let baseId = ""
        switch (targetVal?.baseId){
            case 'moreoffer-item':
                baseId = 'nblyMoreOfferCpnCode';
                break;
            case 'featured-offer-item':
                baseId = 'nblyFeaturedOfferCpnCode';
                break;
            case 'tns1-item':
                baseId = 'nblyCpnCode';
                break;
            default:
                baseId = 'nblyCpnCode';
                break;
        }
        const newId = `${baseId}${targetVal?.digits}`;
        const siblingSpan = document.getElementById(newId) as HTMLSpanElement;
        return siblingSpan?.textContent ?? null;
    };

    handleClaimClick = (e: Event) => {
        e.preventDefault();
        const target = e.target as HTMLElement;
        if (this.isAnchorWithClass(target, 'claim-offer')) {
            const href = (target as HTMLAnchorElement).href;
            const parentDom = target.closest('li') as HTMLElement;
            if(parentDom){
                this.setOffersInSession(parentDom, href);
            }
        }
    };
    setOffersInSession(targetElement: HTMLElement, href:any) {
        const obj = {
            'title' : '',
            'description' : '',
            'couponexpirydate': '',
            'longdescription': '',
            'couponcode': ''
        }
        const parentId = targetElement.id;
        if(parentId){
            const lastDigit = this.getLastDigitFromId(parentId);
                const spanValue = this.getSiblingSpanValue(lastDigit ?? '0');
                if (spanValue) {
                    obj.couponcode = spanValue;
                } else {
                    console.log("No Coupon code found");
                }
            } else {
                console.log("Parent <li> id not found");
        }
        obj.title = targetElement.querySelector('.sp-offers-details .sp-offer')?.textContent ?? '';
        obj.description = targetElement.querySelector('.sp-offers-details .sp-offer-desc')?.textContent ?? '';
        obj.couponexpirydate = targetElement.querySelector('.expire-mobile')?.textContent ?? '';
        const firstTxt = targetElement.querySelector('.sp-offers-disclaimer')?.textContent ?? '';
        const secondTxt = targetElement.querySelector('.sp-offers-disclaimer .more-content')?.textContent ?? '';
        obj.longdescription = `${firstTxt} ${secondTxt}`;
        sessionStorage.setItem('offerDetails', JSON.stringify(obj));
        window.location.href = href;
    }

    readmoreFunctionality(){
        const paragraphs = document.querySelectorAll(".text-more");
        if(!paragraphs)
            return;
        paragraphs.forEach(function(paragraph) {
            const button = paragraph.querySelector("button");
            if(!button)
                return;
            button.addEventListener("click", function() {
                const dots = paragraph.querySelector(".dots") as HTMLElement;
                const moreContent = paragraph.querySelector(".more-content") as HTMLElement;
                if(!dots || !moreContent)
                    return;
                if (moreContent.style.display === "none") {
                    moreContent.style.display = "inline";
                    dots.style.display = "none";
                    button.innerHTML = "Read Less";
                } else {
                    moreContent.style.display = "none";
                    dots.style.display = "inline";
                    button.innerHTML = "Read More";
                }
            });
        });
    }  

    attachPrintEvntListner(){
        const printOfferLinks = document.querySelectorAll('.print-offer-link .print-offer-link-wrap');
        printOfferLinks?.forEach(link => {
            link.addEventListener('click', this.handlePrintClick.bind(this));
        });
    }
    
    handlePrintClick = (e: Event): void => {
        e.preventDefault();
        console.log("clicked");
      
        const target = e.target as HTMLElement;
        const li = target.closest('li') as HTMLElement | null;
        const parentUl = li?.closest('ul') as HTMLElement | null;
      
        if (!li || !parentUl) {
          console.warn("No parent <li> or <ul> element found.");
          return;
        }
      
        // Clone the li element to modify it without affecting the original page
        const clonedLi = li.cloneNode(true) as HTMLElement;
        const disclaimer = clonedLi.querySelector('.sp-offers-disclaimer') as HTMLElement | null;
      
        if (disclaimer) {
          // Remove unwanted elements from the disclaimer
          const dots = disclaimer.querySelector('.dots');
          const moreContent = disclaimer.querySelector('.more-content');
          const readButton = disclaimer.querySelector('.read-btn');
      
          dots?.remove();
          moreContent?.remove();
          readButton?.remove();
        }
      
        const ulClasses = parentUl.className;
        const liContent = clonedLi.innerHTML;
      
        // Open the print window in fullscreen
        const printWindow = window.open('', '', 'width=' + screen.width + ',height=' + screen.height);
      
        if (printWindow) {
          const inlineStyles = `
           
            .print-offer-link, .sp-offers-section .sp-offers-wrap .claim-offer, .read-btn {
              visibility: hidden !important;
            }
            .more-content{
                display: block !important;
            }
          `;
      
          // Wrap `ul` inside `div` with `offers-block-wrapper` and add content to print window
          printWindow.document.write(`
            <html>
              <head>
                <title>Print</title>
                <style>${inlineStyles}</style>
                <link rel="stylesheet" type="text/css" href="/brand/_assets/css/service.css" media="all">
                <link rel="stylesheet" type="text/css" href="/brand/_assets/css/main.css" media="all">
                <link rel="stylesheet" type="text/css" href="/brand/_assets/css/brand-config.css" media="all">
                <link rel="stylesheet" type="text/css" href="/brand/_assets/css/header.css" media="all">
                <link rel="stylesheet" type="text/css" href="/brand/_assets/css/footer.css" media="all">
                <link rel="stylesheet" type="text/css" href="/brand/_assets/css/glossary.css" media="all">
              </head>
              <body>
                <section class="sp-offers-section">
                    <div class="sp-offers-wrap">
                    <ul class="${ulClasses}">
                        <li>${liContent}</li>
                    </ul>
                    </div>
                <section>
              </body>
            </html>
          `);
      
          // Wait for the new content to load before printing
          printWindow.document.close();
          printWindow.addEventListener('load', () => {
            printWindow.focus();
            printWindow.print();
            printWindow.close();
          });
        } else {
          console.error("Failed to open print window.");
        }
    };
    
}

document.addEventListener("DOMContentLoaded", () => {
    const specialOffers = document.querySelector(".sp-offers-section");
    if (specialOffers) {
        OffersLanding.getInstance();
    }
});
