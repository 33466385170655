import axios from "axios";
import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import { StateHandler } from "../util/getStateFun";
import { startLoader, stopLoader } from "../util/loader";
import { getMessagesData } from "../util/siteConfig"
import { LocateAddressBumper } from "../header/locateAddressBumper";
import { capitalizeString, getFranchiseWebLocationId, insertAfterUrl } from "../util/share";
import { getBrandDetailsFromBrandJson, getCampaignName, storeBookingApiPayload } from "../util/share";
import { cachedAPIWrapper, getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiCaching } from "../config/cachingConfig";
import { gtmCustomEventLeadFlow } from "../util/gtm-custom-event-lead-flow";
import { handleMissingData } from "../util/discovery";
import { ContactUsPayloadModel } from "../apiCaching/POST-Requests/interface/contact/contactInterface";
import { disclaimerHandleCheck } from "../util/disclaimerService";
import { modalVisibilityCheck } from "../util/modalVisibilityCheck";
import { DomainIdentifier } from "../util/domainService";
import { LocalizationIdentifier } from "../util/localizationService";
import { Modal } from "flowbite";
import { contactUsPostCallWrapper } from "../apiCaching/POST-Requests/apiContactCallWrapper";
import { BookingPayloadModel } from "../apiCaching/POST-Requests/interface/booking/bookingInterface";
import { bookingUsPostCallWrapper } from "../apiCaching/POST-Requests/apibookingCallWrapper";
import { requestEstimate } from "../util/requestEstimate";
import { getCountryCode } from "../location-search-map/getCountryCode";

let messageData: any = {}
interface LeadSourceArray {
    find(arg0: (source: LeadSource) => boolean): LeadSource | undefined;
}
declare global {
    interface Window {
        getFormFields?: Record<string, any>;
        isShortForm?: any
    }
}
interface LeadSource {
    syncGenericLeadSourceId: number;
    leadSourceName: string;
    isActive: boolean;
    referralTypeId: string;
    referralSourceId: any;
}
export class ResidentialForm {
    private nameInput: HTMLInputElement;
    private lastNameInput: HTMLInputElement;
    private emailInput: HTMLInputElement;
    private phoneInput: HTMLInputElement;
    private addressInput: HTMLInputElement;
    private submitButton: any;
    private privateSuggestedArray = [];
    private form: any;
    private isCAReminderModal: boolean = false;
    private readonly shortFormService: NodeListOf<HTMLElement>;
    private noteWithRequestBody: any;
    private serviceTypeID: number;


    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
            this.calculateShortFormCount();
            this.asideCardToMobile
        });

        window.addEventListener('resize', this.asideCardToMobile);
        const containerData = document.querySelector('.aside-card.tabs-mobile');
        this.asideCardToMobile();
        const nameInputDesk = document.getElementById('req-name') as HTMLInputElement;
        const lastNameInputDesk = document.getElementById('req-lname') as HTMLInputElement;
        const nameInputMob = document.getElementById('req-name-mobile') as HTMLInputElement;
        const emailInputDesk = document.getElementById('req-email') as HTMLInputElement;
        const emailInputMob = document.getElementById('req-email-mobile') as HTMLInputElement;
        const phoneInputDesk = document.getElementById('req-phone') as HTMLInputElement;
        const phoneInputMob = document.getElementById('req-phone-mobile') as HTMLInputElement;
        const addressInputDesk = document.getElementById('req-address') as HTMLInputElement;
        addressInputDesk?.addEventListener('focusout', this.handleFocusOut.bind(this, 'req-address'));
        const addressInput1 = document.getElementById('short-form-req-address') as HTMLInputElement;
        addressInput1?.addEventListener('focusout', this.handleFocusOut.bind(this, 'short-form-req-address'));
        const addressInputMob = document.getElementById('req-address-mobile') as HTMLInputElement;
        this.shortFormService = document.querySelectorAll('[id*="short-form-service-reminder"]');
        this.nameInput = nameInputDesk ? nameInputDesk : nameInputMob;
        this.lastNameInput = lastNameInputDesk;
        this.emailInput = emailInputDesk ? emailInputDesk : emailInputMob;
        this.phoneInput = phoneInputDesk ? phoneInputDesk : phoneInputMob;
        this.addressInput = addressInputDesk ? addressInputDesk : addressInputMob;
        this.submitButton = document.querySelectorAll('.residential-form .primary-btn');
        this.submitButton?.forEach((btn: any) => {
            btn.addEventListener('click', this.setAutoSuggested.bind(this))
        });
        this.serviceTypeID = NaN;

        const pageType = (document.getElementById('pageType') as HTMLInputElement)?.value;
        if (pageType && pageType.toLocaleLowerCase() !== "opus 2 location map") {
            getMessagesData()?.then(resp => {
                messageData = resp
            });
        }

        this.initializeInputNameRestriction();
        this.initializeInputLastNameRestriction();
        this.initializeInputEmailValidation();
        this.initializeInputPhoneValidation();
        this.initializeInputZipCodeValidation();
        this.allRadioButtonsEventListener();
        this.initializeInputCommentValidation();
        this.initializePreferredContactValidation();
        this.shortFormService?.forEach((checkbox) => {
            checkbox.addEventListener('change', this.handleCheckboxChange.bind(this));
          });
          const localisation = DomainIdentifier();
          const countryCode = getCountryCode();
          const localisedcountryCode = sessionStorage.getItem('countryCode');
        if (localisation == 'ca' || countryCode === 'ca' || localisedcountryCode === 'ca') {
            this.hideTermsPolicyForAllForms();
        }
    }

    hideTermsPolicyForAllForms() {
        // Get all .residential-form elements
        const forms = document.querySelectorAll('.residential-form');
        // Iterate all forms  
        forms.forEach(form => {
            // Check if there's a .short-form-req-new section inside the form
            const shortFormReqNew = form.querySelector('.short-form-req-new');
            const children = Array.from(form.children);
            if (shortFormReqNew) {
                const children = Array.from(shortFormReqNew.children);
                const termsPolicy = children.find(child => child.classList.contains('terms-policy-text'));
                if (termsPolicy) {
                    const termsPolicyElement = termsPolicy as HTMLElement;
                    termsPolicyElement.style.display = 'none'; // Hide for canada ZIP codes
                }
            } else {
                // to find .terms-policy-text child
                const termsPolicy = children.find(child => child.classList?.contains('terms-policy-text')) as HTMLElement;

                if (termsPolicy) {
                    termsPolicy.style.display = 'none'; // Hide for Canada ZIP codes
                }
            }
        });
    }

     getCountryByZip(zipCode: string): string {
        return !REGEX.sendCaPostalCode.test(zipCode) ? 'us' : 'ca';
    }

    toggleTermsPolicyDisplay(termsPolicy: HTMLElement, countryZip: string, zipCodeInput: any): void {
        const localisation = DomainIdentifier();
        const countryCode = getCountryCode(); 
        const localisedcountryCode = sessionStorage.getItem('countryCode');
        let zipCode = zipCodeInput?.value.trim();
        if ((countryZip === 'ca') && (localisation === 'ca' || countryCode === 'ca' || localisedcountryCode === 'ca') || (countryZip === 'ca') || ((zipCode === '') && (localisation == 'ca' || countryCode === 'ca' || localisedcountryCode === 'ca'))) {
            termsPolicy.style.display = 'none'; // Hide for Canada ZIP codes & Canada local
        } 
        else if((countryZip === 'us' && zipCode !== '') && (localisation === 'ca' || countryCode === 'ca' || localisedcountryCode === 'ca') ) {
            termsPolicy.style.display = 'block';
        }
        else {
            termsPolicy.style.display = 'block'; // Show for US ZIP codes
        }
    }
    handleFocusOut(zipCodeId: string) {
        let zipCodeInput = document.getElementById(zipCodeId) as HTMLInputElement;  
        const countryZip = this.getCountryByZip(zipCodeInput?.value.trim() || '');
        const form = zipCodeInput.closest('.residential-form'); // Get closest form
        if (form) {
            const shortFormReqNew = form.querySelector('.short-form-req-new');
            const children = Array.from(form.children);
            if (shortFormReqNew) {
                // Find the immediate child with class .terms-policy-text inside .short-form-req-new
                const children = Array.from(shortFormReqNew.children);
                const termsPolicy = children.find(child => child.classList.contains('terms-policy-text'));
                if (termsPolicy) {
                    const termsPolicyElement = termsPolicy as HTMLElement;
                    this.toggleTermsPolicyDisplay(termsPolicyElement, countryZip, zipCodeInput);
                }
            } else {
                const termsPolicy = children.find(child => child.classList?.contains('terms-policy-text')) as HTMLElement;
                if (termsPolicy) {
                    this.toggleTermsPolicyDisplay(termsPolicy, countryZip, zipCodeInput);
                }
            }
        }
    }
    handleCheckboxChange() {
        const serviceReminderDivs = this.form?.querySelectorAll('.service-reminder-main');
        let errorID = null;

    // Check if any divs with the class 'service-reminder-main' are found
    if (serviceReminderDivs.length === 0) {
        console.log('Servcie Disclaimer not authored in CP');
        return; // Exit the function early if no divs are found
    }
        // Loop through each div
        serviceReminderDivs.forEach((div: HTMLElement) => {
            // Check if the div is visible (does not have the 'hidden' class)
            if (div && !div.classList.contains('hidden')) {
                // Find the checkbox input inside this div
                const checkbox = div.querySelector('input[type="checkbox"]');
                // Find the corresponding error message element
                const errorMsg = div.querySelector('.error-msg');

                // Check if the checkbox and errorMsg exist and if the checkbox is required
                if (checkbox && errorMsg && checkbox instanceof HTMLInputElement && checkbox.required) {
                    // Add event listener to handle checkbox state change
                    checkbox.addEventListener('change', () => {
                        // If the checkbox is checked, hide the error message
                        if (checkbox.checked) {
                            errorMsg.classList.add('hidden');
                        } else {
                            // If the checkbox is unchecked, show the error message
                            errorMsg.classList.remove('hidden');
                            errorID = errorMsg?.id;
                        }
                    });

                    // Initial check: if the checkbox is required and unchecked when the page loads
                    if (checkbox.required && !checkbox.checked) {
                        errorMsg.classList.remove('hidden');
                        errorID = errorMsg?.id;
                    }
                }
            }
        });
        return errorID;
      }
    allRadioButtonsEventListener() {
        const labels = document.querySelectorAll('.req-call-btn');
        labels.forEach(label => {
            label.addEventListener('click', (event: any) => {
                const label = event.target.closest('.req-call-btn');
                const radio = label.closest('.req-call-tablist').querySelector('.req-call-radio');
                if (radio) {
                    radio.checked = true;
                }
            });
        });
    }

    private initializeInputNameRestriction(): void {
        const inputs = document.querySelectorAll('input.short-form-req-name,#req-name');
        inputs.forEach(input => {
            input.addEventListener('input', this.errorOnName.bind(this));
        });
    }
    private initializeInputLastNameRestriction(): void {
        const inputs = document.querySelectorAll('input.short-form-req-lname,#req-lname');
        inputs.forEach(input => {
            input.addEventListener('input', this.errorOnLastName.bind(this));
        });
    }

    private initializeInputEmailValidation(): void {
        const inputs = document.querySelectorAll('input.short-form-req-email,#req-email');
        inputs.forEach(input => {
            input.addEventListener('input', this.emailError.bind(this));
        });
    }
    private initializeInputPhoneValidation(): void {
        const inputs = document.querySelectorAll('input.short-form-req-phone,#req-phone');
        inputs.forEach(input => {
            input.addEventListener('input', this.handleMobileInput.bind(this));
        });
    }
    private initializeInputZipCodeValidation(): void {
        const inputs = document.querySelectorAll('input.short-form-req-address,#req-address');
        inputs.forEach(input => {
            input.addEventListener('input', this.zipCodeError.bind(this));
        });
    }

    private initializeInputCommentValidation(): void {
        const inputs = document.getElementById('short-form-req-comment');
        inputs?.addEventListener('input', this.errorOnComment.bind(this));

    }

    private initializePreferredContactValidation(): void {
        const inputs = document.querySelectorAll('input[id*="short-form-req-pref"]');
        inputs.forEach(input => {
            input.addEventListener('input', this.prefMethodError.bind(this));
        });
    }

    public scrollToThankYouSection() {
        if (window.innerWidth <= 768) {
            let targetScrollElement = document.querySelector('.hero-review') as HTMLElement;
            if (targetScrollElement) {
                let OffSetTop = targetScrollElement?.offsetTop;
                window.scrollTo({
                    top: OffSetTop,
                    behavior: 'smooth'
                })
            }
        }
    }

    errorOnComment(event: any) {
        const commentEle = event.target;
        this.form = event.target.closest('form');
        if (commentEle.value !== '' && !REGEX.matchAny.test(commentEle.value)) {
            const getErrMsg = (this.form.querySelector(`#${commentEle?.id}-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = this.form.querySelector(`#${commentEle?.id}-error-msg`);
            if (fieldError) {
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg ?? 'Invalid comment.'

            }
        }
        else {
            this.hideError(commentEle?.id)
        }

    }

    prefMethodError(event: any) {
        const ele = event.target;
        this.form = event.target.closest('form');
        const inputs = document.querySelectorAll('input[id*="short-form-req-pref"]');
        const arrChecked: boolean[] = [];
        inputs.forEach((input: any) => {
            arrChecked.push(input?.checked as boolean);
        });
        const validOutput = arrChecked.some(function (a) {
            return a;
        });
        if (validOutput) {
            this.hidePrefError('shortform_preferred-contact-error-msg');
        }
        else {
            this.addPrefError('shortform_preferred-contact-error-msg');
        }

    }

    public handleMobileInput(e: any): void {
        const phoneInput = e.target;
        this.form = e.target.closest('form');
        e.preventDefault();
        const reg = REGEX.mobileNumberRegex;
        const val = phoneInput?.value;
        const x = val?.replace(/\D/g, '').match(reg);
        const formattedVal = x ? `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}` : '';
        if (phoneInput?.value != '' && phoneInput?.value.length < 14 && formattedVal.length < 14) {
            const getErrMsg = (this.form.querySelector(`#${phoneInput?.id}-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = this.form.querySelector(`#${phoneInput?.id}-error-msg`);
            if (fieldError) {
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg || 'Invalid phone format.'

            }
            else {
                const field: any = phoneInput;
                if (field && field.parentNode) {
                    const errorMessage = getErrMsg || 'Invalid phone format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
        }
        else {
            this.hideError(phoneInput?.id)
        }

        if (x) {
            phoneInput!.value = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}`;
        }

    }
    errorOnName(event: any) {
        const nameInput = event.target;
        this.form = event.target.closest('form');
        if (nameInput.value !== '' && !REGEX.sendName.test(nameInput.value)) {
            const getErrMsg = (this.form.querySelector(`#${nameInput?.id}-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = this.form.querySelector(`#${nameInput?.id}-error-msg`);
            if (fieldError) {
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg || 'Invalid name format.'

            }
            else {
                const field: any = nameInput;
                if (field && field.parentNode) {
                    const errorMessage = getErrMsg || 'Invalid name format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
        }
        else {
            this.hideError(nameInput?.id)
        }

    }
    errorOnLastName(event: any) {
        const lastNameInput = event.target;
        this.form = event.target.closest('form');
        if (lastNameInput.value !== '' && !REGEX.sendName.test(lastNameInput.value)) {
            const getErrMsg = (this.form.querySelector(`#${lastNameInput?.id}-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = this.form.querySelector(`#${lastNameInput?.id}-error-msg`);
            if (fieldError) {
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg ?? 'Invalid last name format.'

            }
            else {
                const field: any = lastNameInput;
                if (field?.parentNode) {
                    const errorMessage = getErrMsg ?? 'Invalid last name format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
        }
        else {
            this.hideError(lastNameInput?.id)
        }

    }

    emailError(event: any) {
        const emailInput = event.target;
        this.form = event.target.closest('form');
        if (emailInput.value != '' && !REGEX.sendEmail.test(emailInput.value)) {
            const getErrMsg = (this.form.querySelector(`#${emailInput?.id}-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = this.form.querySelector(`#${emailInput?.id}-error-msg`);


            if (fieldError) {
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg || 'Invalid email address, please try again.'

            }
            else {
                const field: any = emailInput;
                if (field && field.parentNode) {
                    const errorMessage = getErrMsg || 'Invalid email address, please try again.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }


        }
        else {

            this.hideError(emailInput?.id)
        }

    }

    zipCodeError(event: any) {
        const addressInput = event.target;
        this.form = event.target.closest('form');
        if ((addressInput?.value != '' && !REGEX.sendZip.test(addressInput?.value.trim())) || addressInput?.value == '00000') {
            const getErrMsg = (this.form.querySelector(`#${addressInput?.id}-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = this.form.querySelector(`#${addressInput?.id}-error-msg`);
            if (fieldError) {
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg || 'Invalid zip code format.'
            }
            else {
                const field: any = addressInput;
                if (field && field.parentNode) {
                    const errorMessage = getErrMsg || 'Invalid zip code format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
        }
        else {
            this.hideError(addressInput?.id)
        }

    }

    hideError(id: any) {
        const field: any = this.form.querySelector(`#${id}`);
        const fieldError: any = this.form.querySelector(`#${id}-error-msg`);
        fieldError?.classList.add('hidden')
        field?.classList.remove('invalid-field');
    }

    hidePrefError(id: any) {
        const field: any = this.form.querySelector(`#${id}`);
        field?.classList.add('hidden')
        field?.classList.remove('invalid-field');
    }
    addPrefError(id: any) {
        const field: any = this.form.querySelector(`#${id}`);
        field?.classList.remove('hidden')
        field?.classList.add('invalid-field');
    }

    private getCheckedPreferedComunnication = () => {

        const checkedCheckboxes = this.form?.querySelectorAll('input[type="checkbox"].checkbox-basic:checked');
        let checkboxString = '';
        checkedCheckboxes?.forEach((checkbox: any, index: any) => {
            const preferredCommunication = checkbox.value;
            checkboxString += capitalizeString(preferredCommunication as any);
            if (index < checkedCheckboxes.length - 1) {
                checkboxString += ',';
            }
        });

        return checkboxString;

    };

    private getFormFields(): any {

    let residentialDesk = (this.form?.closest('.tabs-wrapper')?.querySelectorAll('input[name^="short-request-call-"]') as NodeListOf<HTMLInputElement>);
    let serviceModifierText = ''; 
        const filteredServiceModifier = Array.from(residentialDesk)?.filter(x => x.checked);
        const requestType: string | undefined = (filteredServiceModifier[0]?.parentElement?.querySelector('.lead-service-typeId') as HTMLSelectElement)?.value ?? (filteredServiceModifier[0]?.parentElement?.querySelector('.short-lead-service-typeId') as HTMLSelectElement)?.value;
        if(requestType){
            this.serviceTypeID = parseInt(requestType);
        }

        const getServiceModifier = (filteredServiceModifier:any,serviceText:string): string => {
            if (filteredServiceModifier.length > 0) {
                let serviceTextInput = filteredServiceModifier[0]?.parentElement.querySelector(serviceText)?.value;
                 serviceModifierText =  serviceTextInput?.replace(/-/g, " ") ?? '';
                 return capitalizeString(filteredServiceModifier[0]?.value?.replace(/-/g, " "));
            }
            return "Residential";
        };

        let serviceModilfier = getServiceModifier(filteredServiceModifier,'input[name^="lead-service-text-"]') || "Residential";
        const isShortForm = this.form?.closest('.short-form-component');
        const commentsEle = this.form.querySelector("#short-form-req-comment") as HTMLInputElement;
        let getCheckedPreferedComunnication = this.getCheckedPreferedComunnication();

        if (isShortForm) {
            this.nameInput = this.form.querySelector('#short-form-req-name');
            this.lastNameInput = this.form.querySelector('#short-form-req-lname');
            this.emailInput = this.form.querySelector('#short-form-req-email');
            this.phoneInput = this.form.querySelector('#short-form-req-phone');
            this.addressInput = this.form.querySelector('#short-form-req-address');
            const isShortServiceModifier = (isShortForm.querySelectorAll('input[name^="short-request-call"]') as NodeListOf<HTMLInputElement>);
            const filteredShortServiceModifier = Array.from(isShortServiceModifier)?.filter(x => x.checked);
            serviceModilfier = getServiceModifier(filteredShortServiceModifier,'input[name^="short-lead-service-text-"]') || "Residential";
        }
        else {
            this.nameInput = this.form.querySelector('#req-name');
            this.lastNameInput = this.form.querySelector('#req-lname');
            this.emailInput = this.form.querySelector('#req-email');
            this.phoneInput = this.form.querySelector('#req-phone');
            this.addressInput = this.form.querySelector('#req-address');
        }

        let leadServiceId = '';
        if (filteredServiceModifier.length > 0) {
            const selectedRadio = filteredServiceModifier[0];
            const leadServiceIdField = selectedRadio?.parentElement?.querySelector(`.lead-service-id`);
            if (leadServiceIdField && (leadServiceIdField as HTMLInputElement)?.value) {
                leadServiceId = (leadServiceIdField as HTMLInputElement)?.value;
            }
        }

        const fields :any = {
            nameInput: this.nameInput,
            lastNameInput: this.lastNameInput,
            emailInput: this.emailInput,
            phoneInput: this.phoneInput,
            addressInput: this.addressInput,
            isResidential: serviceModilfier,
            comments: commentsEle?.value || '',
            preferCommunication: getCheckedPreferedComunnication || '',
            serviceText	: serviceModifierText
        };
        if (leadServiceId) {
            fields.leadServiceId = leadServiceId; // Include service-id if present
        }
        if (!isNaN(this.serviceTypeID)) {
            fields.serviceTypeID = this.serviceTypeID;
        }

        return fields;
    }


    private validateInputs(): boolean {

        const formFields = this.getFormFields();
        window.getFormFields = formFields;
        this.form?.querySelectorAll('.invalid-field').forEach((e: any) => e.classList.remove('invalid-field'));
        const errors: any = {};

        if (formFields.nameInput) {
            if (formFields.nameInput?.value === '') {
                errors[formFields.nameInput?.id] = '';
            } else if (!REGEX.sendName.test(formFields.nameInput?.value)) {
                errors[formFields.nameInput?.id] = 'Invalid  name format.';
            }
        }

        if (formFields.lastNameInput) {
            if (formFields.lastNameInput?.value === '') {
                errors[formFields.lastNameInput?.id] = '';
            } else if (!REGEX.sendName.test(formFields.lastNameInput?.value)) {
                errors[formFields.lastNameInput?.id] = 'Invalid last name format.';
            }
        }

        if (formFields.phoneInput) {
            if (formFields.phoneInput?.value === '') {
                errors[formFields.phoneInput.id] = '';
            }
            else if (formFields.phoneInput?.value?.length < 14) {
                errors[formFields.phoneInput.id] = 'Invalid phone format.';
            }
        }

        if (formFields.addressInput) {
            if (formFields.addressInput?.value === '') {
                errors[formFields.addressInput?.id] = '';
            }
            else if (!REGEX.sendZip.test(formFields.addressInput?.value?.trim()) || this.addressInput?.value == '00000') {
                errors[formFields.addressInput?.id] = 'Invalid zip code format.';
            }
        }

        if (formFields.emailInput) {
            if (formFields.emailInput?.value === '') {
                errors[formFields.emailInput?.id] = '';
            } else if (!REGEX.sendEmail.test(formFields.emailInput?.value)) {
                errors[formFields.emailInput?.id] = 'Invalid email address, please try again.';
            }
        }

        const commentsEle = this.form.querySelector("#short-form-req-comment") as HTMLInputElement;
        if (commentsEle?.required && formFields.comments === '') {
            errors[commentsEle?.id] = '';
        }
        const labelElement = this.form.querySelector("#short-form-req-pref-email,#short-form-req-pref-phone,short-form-req-pref-text")?.closest('.input-label-wrap')?.querySelector('.label-basic');
        let isPreferCommunicationRequired = false;
        if (labelElement) {
            isPreferCommunicationRequired = labelElement.hasAttribute('required');
        }
        if (isPreferCommunicationRequired && formFields.preferCommunication === '') {
            errors['shortform_preferred-contact'] = '';
        }
        const errorIdChekboxSpan = this.handleCheckboxChange();
        if(errorIdChekboxSpan){
            errors[errorIdChekboxSpan] = '';
        }

        let self = this;
        Object.keys(errors).forEach((fieldId, index) => {
            const field: any = self.form.querySelector(`#${fieldId}`);
            const fieldError: any = self.form.querySelector(`#${fieldId}-error-msg`);
            if (fieldId === 'shortform_preferred-contact') {
                if (fieldError?.classList.contains('hidden'))
                    fieldError.classList.remove('hidden')
            }
            if (field && field.parentNode) {
                const errorMessage = errors[fieldId];
                const errorElement = document.createElement('span');
                errorElement.className = 'error-msg';
                errorElement.id = `${fieldId}-error-msg`;
                errorElement.textContent = errorMessage;
                field.classList.add("invalid-field");
                if (fieldError && fieldError.classList.contains('hidden'))
                    fieldError.classList.remove('hidden')
            }
            if (index == 0 && field) {
                field.focus()
            }
        });
        return Object.keys(errors).length === 0;
    }

    private async handleSubmit(event: any, data: { franchiseId: any; franchiseWebLocationId: any; }, zipcodeInput: any): Promise<any> {
        event.preventDefault();
        const formFields = window.getFormFields ? window.getFormFields : this.getFormFields();
        this.form = window.isShortForm ? window.isShortForm : this.form;
        const campaignName = getCampaignName();
        const addressZip: any = zipcodeInput;
        var host = window.location.hostname.toLowerCase();
        let IsTest;
        if (host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest')) {
            IsTest = true;
        } else {
            IsTest = false;
        }
        const id: any = data.franchiseWebLocationId ?? undefined;
        const franchiseId: any = data.franchiseId ?? undefined;
        const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value;
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        const defaultCustomerType = (document.getElementById('defaultCustomerType') as HTMLInputElement)?.value;
        const url = window.location.href;
        let vendorId: any;
        let vendorName: any;
        const brandData = await getBrandDetailsFromBrandJson(conceptCode) as any;
        if (brandData) {
            vendorId = brandData.vendorId;
            vendorName = brandData.vendorName;
        }

        const lastName = formFields.lastNameInput?.value ?? '';
        const emailOptOut = brandData.enable_emailOptOut;
        const defaultLeadSourceId = brandData.default_leadSourceID || 0;

        const requestBody: BookingPayloadModel = {
            isLocalized: true,
            firstName: formFields.nameInput?.value.split(' ')[0] ? formFields.nameInput?.value.split(' ')[0] : '',
            lastName: formFields.nameInput?.value.split(' ')[1] ? formFields.nameInput?.value.split(' ')[1] : ' ',
            zipCode: addressZip?.address?.postalCode.trim(),
            postalCode: addressZip?.address?.postalCode.trim(),
            email: formFields.emailInput?.value,
            state: addressZip?.address?.state,
            city: addressZip?.address?.city,
            phone: formFields.phoneInput?.value,
            address: "No address collected",
            address2: '',
            comments: formFields?.comments || '',
            note: formFields.isResidential,
            signUpForUpdates: false,
            conceptId: Number(conceptId),
            conceptCode: conceptCode,
            leadSource: "WEB",
            leadOrigin: "WEB",
            phoneType: 'true',
            isLeadOnly: true,
            isTest: IsTest,
            scheduleID: "",
            campaignName: campaignName,
            callType: "WEB",
            conceptCalledId: Number(conceptId),
            isEstimate: false,
            emailOptOut: emailOptOut,
            vendorId: Number(vendorId),
            vendorName: vendorName,
            currentPage: url,
            customerType: formFields.isResidential,
            preferredCommunicationType: formFields?.preferCommunication || 'Email,Phone',
            leadSourceId: defaultLeadSourceId,
            webLocationId: 0
        };

        if (!isNaN(this.serviceTypeID)) {
            requestBody.serviceTypeID = this.serviceTypeID;
        }

        if (defaultCustomerType) {
            requestBody.customerType = defaultCustomerType.charAt(0).toUpperCase() + defaultCustomerType.slice(1);
            requestBody.note = defaultCustomerType.charAt(0).toUpperCase() + defaultCustomerType.slice(1);
        }
        if (id) { 
            requestBody.webLocationId = id;
            requestBody.franchiseId = franchiseId;

            const dbaName = (document.getElementById('dbaName') as HTMLInputElement)?.value ?? localStorage.getItem('dbaName');

            if (dbaName && brandData?.send_shortform_lead_bookingapi) {
                const zipCode: any = sessionStorage.getItem('sAddressParam');
                const webId = (document.getElementById('weblocationId') as HTMLInputElement)?.value ?? localStorage.getItem('weblocationId');
                if (zipCode) {
                    let franchiseIdDetails = await getFranchiseWebLocationId(zipCode) as any
                    requestBody.webLocationId = Number(webId ?? id);
                    requestBody.franchiseId = franchiseIdDetails ? franchiseIdDetails?.franchiseId : null;
                } else if (webId) {
                    requestBody.webLocationId = Number(webId);
                    const apiUrl = apiConfig.FranchisePOSLookup + webId;
                    try {
                        let franchiseIdPOSDetails = await getCachedAPIResponse(apiUrl, apiCaching.POSSystemLookup);
                        if (franchiseIdPOSDetails.length > 0) {
                            requestBody.franchiseId = franchiseIdPOSDetails[0]?.franchiseId ? franchiseIdPOSDetails[0]?.franchiseId : null;
                        }
                    }
                    catch {
                        console.error("Error fetching FranchisePOSLookup in Residential Forms")
                    }
                }
            }
        }

        if (lastName) {
            requestBody.lastName = lastName;
            requestBody.firstName = formFields.nameInput?.value ?? '';
        }

        try {
            if (conceptCode?.toLocaleLowerCase() === 'dvw' && franchiseId) {
                const dynamic_url = `${apiConfig.howToKnow}/${franchiseId}`;
                const leadSources: LeadSourceArray = await cachedAPIWrapper(dynamic_url);
                const othersLeadSource: LeadSource | undefined = leadSources.find(
                    (source: LeadSource) => source?.leadSourceName.toLocaleLowerCase() === "others"
                );

                if (othersLeadSource?.referralTypeId) {
                    requestBody.referralTypeId = othersLeadSource.referralTypeId;
                }
                if (othersLeadSource?.leadSourceName) {
                    requestBody.referralType = othersLeadSource.leadSourceName;
                }

            }
        } catch (error) {
            console.error('An error occurred in generic-lead-sources API in ResidentialForm.ts file:', error);
        }

        if (formFields.comments) {
            requestBody.note += "," + formFields.comments
        }
        if (formFields.preferCommunication) {
            requestBody.note += ",Preferred method of communication: " + formFields.preferCommunication
        }

        const out = await handleMissingData(requestBody.city, requestBody.state, requestBody.zipCode);
        requestBody.city = out?.city || requestBody.city;
        requestBody.state = out?.state || requestBody.state;
        const isShortForm = this.form?.closest('.short-form-component');
        const tabBodyDiv = this.form?.closest('.tab-body');

        try {
            let isShowModal = modalVisibilityCheck(requestBody?.zipCode?.trim());
            if (this.isCAReminderModal) {
                isShowModal = false;
            }
            const domain = DomainIdentifier();
      
            const localisation = LocalizationIdentifier();
            // Default value to pass if checkbox is not found
            let checkboxId = '';
            const countryZip = !REGEX.sendCaPostalCode.test(requestBody.zipCode?.trim() || '') ? 'us' : 'ca';
            if (((domain === "us" && !localisation) || (domain === "us" && localisation=="us")) && countryZip === 'us') {
                checkboxId = 'short-form-service-reminder-us';
            } else {
                checkboxId = 'short-form-service-reminder-ca';
            }
        
            this.noteWithRequestBody = disclaimerHandleCheck(isShowModal, tabBodyDiv, requestBody, requestBody.zipCode, checkboxId);
            const serviceReminderModal = document.querySelector('.service-reminder-ca-modal') as HTMLElement;

            if (isShowModal && !this.isCAReminderModal && serviceReminderModal && countryZip=='ca' && localisation !== 'ca') {
                stopLoader();
                this.isCAReminderModal = true;
                document.getElementById("service-reminder-modal")?.querySelector(".primary-btn")?.addEventListener("click", () => {
                    startLoader();
                    const checkbox = tabBodyDiv.querySelector(`#${checkboxId}`);
                    if (checkbox) {
                        checkbox.checked = true;
                    }
                    this.handleSubmit(event, data, zipcodeInput);
                    (document.querySelector('.service-reminder-ca-modal') as HTMLAnchorElement)?.click();
                });
                document.getElementById("service-reminder-modal")?.querySelectorAll(".primary-outline-btn,#close-reminder-local-modal")?.forEach(element => {
                    element.addEventListener("click", () => {
                    startLoader();
                    this.handleSubmit(event, data, zipcodeInput);
                    });
                });
                const modalDiv = document.querySelector("#service-reminder-modal") as HTMLDivElement;
                const modal = new Modal(modalDiv);
                const self = this;
                if(modalDiv){
                    modalDiv.onclick = function(event) {
                        startLoader();
                        if (event.target === modalDiv) {
                            modal?.hide();
                        }
                        (document.querySelector('.service-reminder-ca-modal') as HTMLAnchorElement)?.click();
                        self.handleSubmit(event, data, zipcodeInput);
                    }
                  };
                return false;
            } else {

                if (this.noteWithRequestBody?.note) {
                    requestBody.note = this.noteWithRequestBody.note;
                }
                if (this.noteWithRequestBody?.comments) {
                    requestBody.comments = this.noteWithRequestBody.comments;
                }
               
                requestBody.optIn = this.noteWithRequestBody.optIn;
                requestBody.emailOptOut = this.noteWithRequestBody.emailOptOut;
                
                this.isCAReminderModal = false;
            }

        } catch (error) {
            console.error('An error occurred in disclaimerHandleCheck in ResidentialForm.ts file:', error);
        }

            let formDiv = tabBodyDiv?.querySelector('.residential-form-container') as HTMLFormElement;
            let formDivHeader = tabBodyDiv?.querySelector('.form-heading-container') as HTMLFormElement;
            let thankYouHeader = tabBodyDiv?.querySelector('#thank-you-header') as HTMLFormElement;
            let thankYouDesc = tabBodyDiv?.querySelector('#thank-you-description') as HTMLFormElement;
            let errorMsg = tabBodyDiv?.querySelector('#error--header') as HTMLFormElement;
            let eroorDesc = tabBodyDiv?.querySelector('#error-description') as HTMLFormElement;

            if (isShortForm) {
                const currentSectionDom = this.form?.closest('.short-form-component')
                formDiv = currentSectionDom.querySelector('.residential-form-container') as HTMLFormElement;
                formDivHeader = currentSectionDom.querySelector('.form-heading-container') as HTMLFormElement;
                thankYouHeader = currentSectionDom.querySelector('#shortform-thank-you-header') as HTMLFormElement;
                thankYouDesc = currentSectionDom.querySelector('#shortform-thank-you-description') as HTMLFormElement;
                errorMsg = currentSectionDom.querySelector('#shortform-error--header') as HTMLFormElement;
                eroorDesc = currentSectionDom.querySelector('#shortform-error-description') as HTMLFormElement;
            }

            if(formFields?.serviceText){
                requestBody.serviceText = formFields?.serviceText;
            }
            await bookingUsPostCallWrapper(apiConfig.BOOKING_API_URL, requestBody).then((response: any) => {
                    localStorage.setItem('weblocationId',requestBody.webLocationId.toString());
                    requestEstimate('request_call_back','short-form','success',requestBody.customerType?requestBody.customerType:'');
                    stopLoader();
                    const submitObj = {
                        event: 'opus_form_submit_ev',
                        status: 'success'
                    }
                    gtmCustomEventLeadFlow(submitObj);
                    storeBookingApiPayload(requestBody, response);
                    formDiv.remove();
                    formDivHeader?.remove();
                    thankYouHeader?.classList.remove("hidden");
                    thankYouDesc?.classList.remove("hidden");
                    this.scrollToThankYouSection();
                    const closeModal = document.getElementById('close-local-modal');
                    if (closeModal) {
                        closeModal.click();
                    }
                    insertAfterUrl();
                    this.createHiddenLocalWeblocalIdInput(id);
                })
                .catch((err) => {
                    requestEstimate('request_call_back','short-form','fail',requestBody.customerType?requestBody.customerType:'');
                    stopLoader();
                    const submitObj = {
                        event: 'opus_form_submit_ev',
                        status: 'failure'
                    }
                    gtmCustomEventLeadFlow(submitObj);
                    errorMsg?.classList.remove("hidden");
                    eroorDesc?.classList.remove("hidden");
                    formDiv?.remove();
                    formDivHeader?.remove();
                });

    }

    private setAutoSuggested = (e: any) => {
        this.form = (e.target as HTMLElement).closest('form');
        window.isShortForm = this.form;

        e.preventDefault()
        if (this.validateInputs()) {
            const formFields = this.getFormFields();
            const addressId = formFields?.addressInput?.id;
            const request = {
                method: 'GET',
                url: `${apiConfig.DISCOVER_API}?q=${(this.form.querySelector(`#${addressId}`) as HTMLInputElement).value}&in=countryCode%3AUSA%2CCAN&at=0,0&apiKey=${process.env.JS_Heremap_API_KEY}`
            }
            apiRequest(request)
                .then((result: any) => {
                    if (result?.items.length > 0) {
                        this.privateSuggestedArray = result?.items
                        const zipcodeData = result?.items[0]
                        const updatedData = this.getAddress(zipcodeData);
                        let selectedRadio = formFields.leadServiceId;
    
                        if (selectedRadio) {
                            //pass the selectedRadio
                            this.getFranchiseWebLocationId(e, updatedData, selectedRadio);
                        }
                        else {
                            this.getFranchiseWebLocationId(e, updatedData);
                        }
                        
                    } else {
                        this.handleErrorcase(1);
                    }
                })
                .catch((err) => {
                    this.handleErrorcase(1);
                });
        }
    }

    private getAddress(data: any) {
        let newData = data;
        newData.title = "No address collected";
        return newData;
    }

    private handleErrorcase(inputArg = 0) {
        let isShortForm = this.form?.closest('.short-form-component');
        let errorMsg: any, eroorDesc: any, noService: any, currentElement: any, noServiceDescriptn: any;
        const parentContainer = this.form?.closest('.residential-form-container');
        if (parentContainer) {
            currentElement = parentContainer.parentElement;
            if (currentElement) {
                errorMsg = currentElement.querySelector('#error--header');
                eroorDesc = currentElement.querySelector('#error-description');
                noService = currentElement.querySelector('#no-service-header');
                noServiceDescriptn = currentElement.querySelector('#no-service-description');
            }
        }

        if (isShortForm) {
            const formHeading = isShortForm.querySelector(".form-heading-container") as HTMLFormElement;
            const formContainer = isShortForm.querySelector('div[class*="form-container"]') as HTMLFormElement;
            errorMsg = isShortForm.querySelector('#shortform-error--header') as HTMLFormElement;
            eroorDesc = isShortForm.querySelector('#shortform-error-description') as HTMLFormElement;
            noService = isShortForm.querySelector('#shortform-no-service-header') as HTMLFormElement;
            const noServiceDesc = isShortForm.querySelector('#shortform-no-service-description') as HTMLFormElement;
            formHeading.classList.add("hidden");
            formContainer.classList.add("hidden");
            if (inputArg === 1) {
                errorMsg.classList.remove("hidden");
                eroorDesc.classList.remove("hidden");
                noService.classList.add("hidden");
                noServiceDesc.classList.add("hidden");
            }
            else {
                errorMsg.classList.add("hidden");
                eroorDesc.classList.add("hidden");
                noService.classList.remove("hidden");
                noServiceDesc.classList.remove("hidden");
            }
        }
        else {
            errorMsg?.classList.add("hidden");
            eroorDesc?.classList.add("hidden");
            noService?.classList?.remove("hidden");
            noServiceDescriptn?.classList.remove("hidden");
            const formHeading = currentElement?.querySelector(".form-heading-container") as HTMLFormElement;
            const formContainer = currentElement?.querySelector('div[class*="form-container"]') as HTMLFormElement;
            formHeading?.classList.add("hidden");
            formContainer?.classList.add("hidden");
            this.scrollToThankYouSection();
        }
        stopLoader();
    }

    private asideCardToMobile() {
        const asideCard = document.querySelector('.aside-card.tabs') as HTMLElement;
        const asideCardMobile = document.querySelector('.aside-card.tabs-mobile') as HTMLElement;

        try {

            if (asideCard && asideCardMobile) {
                if (window.innerWidth <= 768) {
                    while (asideCard.firstChild) {
                        asideCardMobile.appendChild(asideCard.firstChild);
                    }
                } else {
                    while (asideCardMobile.firstChild) {
                        asideCard.appendChild(asideCardMobile.firstChild);
                    }
                }
            }

        } catch (error) {
            console.log("Error in Residential form: ", error);
        }

    }

    private getFranchiseWebLocationId(event: Event, zipcodeInput: any, selectedRadio?: any) {
        event.preventDefault();
        const formFields = this.getFormFields();
        const zipCode: any = zipcodeInput;
        const tabBodyDiv = this.form?.closest('.tab-body');
        let errorMsg = tabBodyDiv?.querySelector('#error--header') as HTMLFormElement;
        let eroorDesc = tabBodyDiv?.querySelector('#error-description') as HTMLFormElement;
        let noService = tabBodyDiv?.querySelector('#no-service-header');
        let noServiceDesc = tabBodyDiv?.querySelector('#no-service-description');
        let isShortForm = this.form?.closest('.short-form-component');

        if (isShortForm) {
            const currentSectionDom = this.form?.closest('.short-form-component')
            errorMsg = currentSectionDom.querySelector('#shortform-error--header') as HTMLFormElement;
            eroorDesc = currentSectionDom.querySelector('#shortform-error-description') as HTMLFormElement;
            noService = isShortForm.querySelector('#shortform-no-service-header') as HTMLFormElement;
            noServiceDesc = isShortForm.querySelector('#shortform-no-service-description') as HTMLFormElement;
        }

        if (this.validateInputs()) {
            startLoader();
            const serviceId = selectedRadio;
            let apiUrl = apiConfig.LocateLocationApiWithRoundRobinFalse.replace("sAddressParamValue", encodeURIComponent(zipCode?.address?.postalCode));
             // Only append serviceId if it is not null & not undefined
            if (serviceId !== null && serviceId !== undefined) {
                apiUrl += `&Serviceid=${serviceId}`;
            }
            axios.get(apiUrl, {
                headers: {
                    'Cache-Control': 'max-age=0',
                },
            }).then(async (response: any) => {
                const result = response.data;
                const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
                const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);
                if (result.length > 0) {
                    const obj = {
                        'franchiseId': result[0].franchiseId,
                        'franchiseWebLocationId': result[0].franchiseWebLocationId
                    }
                    const doingBusinessAs = result[0].doingBusinessAs;
                    const locationUrl =  result[0].locationWebsiteUrl?.replace(/\/$/, '') ?? '';
                    const splitUrlArr = locationUrl.toLowerCase().split("/");
                    const pathname = splitUrlArr[splitUrlArr.length - 1];
                    if(pathname){
                        localStorage.setItem('dbaName', pathname);
                    }
                    localStorage.setItem("franchiseWebLocationId", result[0]?.franchiseWebLocationId);
                    localStorage.setItem('doingBusinessAs', doingBusinessAs);
                    this.handleSubmit(event, obj, zipcodeInput);
                }
                else {
                    const dbaName = (document.getElementById('dbaName') as HTMLInputElement)?.value ?? localStorage.getItem('dbaName');
                    if (!brandData?.send_shortform_lead_bookingapi || !dbaName) {

                        if (!brandData?.lead_contactapi) {
                            const parentContainer = this.form?.closest('.residential-form-container');
                            let currentElement: any;
                            let formHeading: any;
                            let formContainer: any;
                            if (parentContainer) {
                                currentElement = parentContainer.parentElement;
                                formHeading = currentElement.querySelector(".form-heading-container") as HTMLFormElement;
                                formContainer = currentElement.querySelector('div[class*="form-container"]') as HTMLFormElement;
                            }
                            if (isShortForm) {
                                formHeading = isShortForm.querySelector(".form-heading-container") as HTMLFormElement;
                                formContainer = isShortForm.querySelector('div[class*="form-container"]') as HTMLFormElement;
                            }

                            formHeading?.classList.add("hidden");
                            formContainer?.classList.add("hidden");
                            errorMsg?.classList.add("hidden");
                            eroorDesc?.classList.add("hidden");
                            noService?.classList.remove("hidden");
                            noServiceDesc?.classList.remove("hidden");
                            this.scrollToThankYouSection();

                        } else {
                            this.contactUsApiCall(zipcodeInput);
                        }
                    } else {
                        const obj = {
                            'franchiseId': null,
                            'franchiseWebLocationId': localStorage.getItem('weblocationId')
                        }
                        this.handleSubmit(event, obj, zipcodeInput);
                    }
                    stopLoader()
                }
            })
                .catch(async (error) => {
                    stopLoader();
                    const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
                    const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);

                    const duplicate = error?.response?.data;
                    const step1 = document.querySelector('.step-1') as HTMLElement;
                    if (step1) {
                        step1.classList.add('hidden');
                    }
                    if (duplicate?.Message === 'Full address is needed to narrow down results') {
                        const stateHandler = new StateHandler();
                        stateHandler.getStateFun();
                        const findLocalBtn = document.querySelector(".find-local") as HTMLElement;
                        findLocalBtn?.click();
                        const step2 = document.querySelector('.step-2') as HTMLElement;
                        if (step2) {
                            step2.classList.remove('hidden');
                            step2.classList.add('block');

                            const closeLocalModal = document.querySelector('.close-modal-btn');
                            closeLocalModal?.addEventListener('click', function handleClick(event) {
                                step2.classList.add("hidden");
                                step1.classList.remove("hidden");
                            });
                        }
                        const modalZipCode = document.querySelector('.step-2 #modal-zipcode') as HTMLInputElement;

                        modalZipCode.value = formFields.addressInput?.value;
                        sessionStorage.setItem('shortFormAddressBumper', 'true');

                        const locateAddressBumper = new LocateAddressBumper();
                    } else {
                        if (!brandData?.lead_contactapi) {
                            const html = `<div class="tab-body">
                                <h2 class="form-heading">${messageData?.lead_form_error_title}</h2>
                                <p class="thank-text">${messageData?.lead_form_error_description}</p>
                                </div>`;
                            let messageDiv = document.querySelector('.tabs-wrapper') as HTMLFormElement;
                            let formDiv = document.querySelector('.tab-body') as HTMLFormElement;
                            if (isShortForm) {
                                const currentSectionDom = this.form?.closest('.short-form-component');
                                messageDiv = currentSectionDom.querySelector('.tabs-wrapper') as HTMLFormElement;
                                formDiv = currentSectionDom.querySelector('.tab-body') as HTMLFormElement;
                            }
                            formDiv.remove()
                            messageDiv?.insertAdjacentHTML('beforeend', html);
                        } else {
                            this.contactUsApiCall(zipcodeInput);
                        }
                    }

                });
        }
    }

    async contactUsApiCall(zipcodeInput: any) {

        const formFields = this.getFormFields();
        const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
        const conceptId = document.getElementById('conceptId') as HTMLInputElement;
        const lastName = formFields.lastNameInput?.value ?? '';
        const addressZip: any = zipcodeInput;

        const tabBodyDiv = this.form?.closest('.tab-body')
        let formDiv = tabBodyDiv?.querySelector('.residential-form-container') as HTMLFormElement;
        let formDivHeader = tabBodyDiv?.querySelector('.form-heading-container') as HTMLFormElement;
        let thankYouHeader = tabBodyDiv?.querySelector('#thank-you-header') as HTMLFormElement;
        let thankYouDesc = tabBodyDiv?.querySelector('#thank-you-description') as HTMLFormElement;
        let errorMsg = tabBodyDiv?.querySelector('#error--header') as HTMLFormElement;
        let eroorDesc = tabBodyDiv?.querySelector('#error-description') as HTMLFormElement;
        const isShortForm = this.form?.closest('.short-form-component');
        let shortFormComment = '';
        if (isShortForm) {
            const currentSectionDom = this.form?.closest('.short-form-component')
            formDiv = currentSectionDom.querySelector('.residential-form-container') as HTMLFormElement;
            formDivHeader = currentSectionDom.querySelector('.form-heading-container') as HTMLFormElement;
            thankYouHeader = currentSectionDom.querySelector('#shortform-thank-you-header') as HTMLFormElement;
            thankYouDesc = currentSectionDom.querySelector('#shortform-thank-you-description') as HTMLFormElement;
            errorMsg = currentSectionDom.querySelector('#shortform-error--header') as HTMLFormElement;
            eroorDesc = currentSectionDom.querySelector('#shortform-error-description') as HTMLFormElement;
            shortFormComment  = currentSectionDom.querySelector('#short-form-req-comment')?.value; 
        }

        let vendorId;

        try {

            const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);

            if (brandData.lead_contactapi) {

                if (brandData) {
                    vendorId = brandData.vendorId;
                }
                let comments: string = '';

                if (brandData.osflow_contactUs_comments.length > 0) {
                    comments = brandData.osflow_contactUs_comments;
                }

                const requestBody: ContactUsPayloadModel = {
                    "firstName": formFields.nameInput?.value.split(' ')[0] ? formFields.nameInput?.value.split(' ')[0] : '',
                    "lastName": formFields.nameInput?.value.split(' ')[1] ? formFields.nameInput?.value.split(' ')[1] : ' ',
                    "zipCode": addressZip?.address?.postalCode.trim(),
                    "email": formFields.emailInput?.value,
                    "state": addressZip?.address?.state,
                    "city": addressZip?.address?.city,
                    "phone": formFields.phoneInput?.value,
                    "address": addressZip?.address?.label,
                    "address2": '',
                    "country": addressZip?.address?.country ?? '',
                    "comments": comments,
                    "signUpForUpdates": false,
                    "isLocalized": conceptId?.value ==="25" ? true : false,
                    "isNewCustomer": "",
                    "isTest": true,
                    "conceptId": Number(conceptId?.value),
                    "vendorId": Number(vendorId)
                };
                if(shortFormComment){
                    if(requestBody.comments){
                        requestBody.comments += " ";
                    }
                    requestBody.comments += shortFormComment;
                }
                const host = window.location.hostname.toLowerCase();
                if (host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest') || host.includes('localhost')) {
                    requestBody.isTest = true;
                } else {
                    requestBody.isTest = false;
                }
                if (lastName) {
                    requestBody.lastName = lastName;
                    requestBody.firstName = formFields.nameInput?.value ?? '';
                }
                
                try {
                    let isShowModal = modalVisibilityCheck(requestBody?.zipCode?.trim());
                    if (this.isCAReminderModal) {
                        isShowModal = false;
                    }
                    
                    const domain = DomainIdentifier();
                    const localisation = LocalizationIdentifier();
                    // Default value to pass if checkbox is not found
                    let checkboxId = '';
                    
                    const countryZip = !REGEX.sendCaPostalCode.test(requestBody.zipCode?.trim()) ? 'us' : 'ca';
                    if (((domain === "us" && !localisation) || (domain === "us" && localisation=="us")) && countryZip === 'us') {
                        checkboxId = 'short-form-service-reminder-us';
                    } else {
                        checkboxId = 'short-form-service-reminder-ca';
                    }

                    this.noteWithRequestBody = disclaimerHandleCheck(isShowModal, tabBodyDiv, requestBody, requestBody.zipCode,checkboxId);
                    const serviceReminderModal = document.querySelector('.service-reminder-ca-modal') as HTMLElement;

                    if (isShowModal && !this.isCAReminderModal && serviceReminderModal && countryZip=='ca'  && localisation !== 'ca') {
                        stopLoader();
                        this.isCAReminderModal = true;
                        document.getElementById("service-reminder-modal")?.querySelector(".primary-btn")?.addEventListener("click", () => {
                            startLoader();
                            this.contactUsApiCall(zipcodeInput);
                            const checkbox = tabBodyDiv.querySelector(`#${checkboxId}`);
                            if (checkbox) {
                                checkbox.checked = true;
                            }
                            (document.querySelector('.service-reminder-ca-modal') as HTMLAnchorElement)?.click();
                        });

                        document.getElementById("service-reminder-modal")?.querySelectorAll(".primary-outline-btn,#close-reminder-local-modal")?.forEach(element => {
                            element.addEventListener("click", () => {
                            startLoader();
                            this.contactUsApiCall(zipcodeInput);
                            });
                        });

                        const modalDiv = document.querySelector("#service-reminder-modal") as HTMLDivElement;
                        const modal = new Modal(modalDiv);
                        const self = this;
                        if(modalDiv){
                            modalDiv.onclick = function(event) {
                                startLoader();
                                if (event.target === modalDiv) {
                                    modal?.hide();
                                }
                                (document.querySelector('.service-reminder-ca-modal') as HTMLAnchorElement)?.click();
                                self.contactUsApiCall(zipcodeInput);
                            }
                        };

                        return false;
                    } else {
        
                        if (this.noteWithRequestBody?.comments) {
                            requestBody.comments = this.noteWithRequestBody.comments;
                        }
                        requestBody.optIn = this.noteWithRequestBody.optIn;
                        requestBody.emailOptOut = this.noteWithRequestBody.emailOptOut;
                        this.isCAReminderModal = false;
                    }
        
                } catch (error) {
                    console.error('An error occurred in disclaimerHandleCheck in ResidentialForm.ts file:', error);
                }
                await contactUsPostCallWrapper(apiConfig.CONTACT_US_SEND, requestBody);
                formDiv?.remove();
                formDivHeader?.remove();
                if (thankYouHeader) {
                    thankYouHeader.classList.remove("hidden");
                    thankYouDesc.classList.remove("hidden");
                }
                this.scrollToThankYouSection();
                stopLoader();
            }
        }
        catch (error) {
            console.error(`Error in Residential.ts file in the contactUsApiCall function. Error message: ${error}`);
            errorMsg?.classList.remove("hidden");
            eroorDesc?.classList.remove("hidden");
            formDiv.remove();
            formDivHeader.remove();
            stopLoader();
        }
    }

    public createHiddenLocalWeblocalIdInput(id: any): void {

        const localWebLocationIdInput: HTMLInputElement = document.createElement('input');
        localWebLocationIdInput.type = 'hidden';
        localWebLocationIdInput.value = id;
        localWebLocationIdInput.id = 'local_weblocationId';
        document.body.appendChild(localWebLocationIdInput);

    }

    calculateShortFormCount() {
        const sections = document.querySelectorAll('.aside-card.tabs .tabs-wrapper');
        // Loop through each section
        sections?.forEach((section, sectionIndex) => {
            const labels = section.querySelectorAll('label');
            const uls = section.querySelectorAll('ul.req-call-tabs');

            if(section.querySelectorAll('.lead-service-type,.short-lead-service-type').length > 0){
                section.querySelector('ul.req-call-tabs')?.remove();
                const uniqueName = `short-request-call-${sectionIndex + 1}`;
                const inputs = section.querySelectorAll('.lead-service-type,.short-lead-service-type');
                inputs?.forEach((input) => {
                    (input as HTMLInputElement).name = `${uniqueName}`;
                    input.id = `${input.id}-${sectionIndex + 1}`;
                });
            }

            uls?.forEach((ul) => {
                const uniqueName = `short-request-call-${sectionIndex + 1}`;
                const inputs = ul.querySelectorAll('input');
                inputs?.forEach((input) => {
                    // Assign the same name to inputs within the same ul
                    input.name = `${uniqueName}`;
                    input.id = `${input.id}-${sectionIndex + 1}`;
                });
            });

            labels?.forEach((label) => {
                if (label.htmlFor) {
                    const label1 = `short-form-${label.htmlFor}-${sectionIndex + 1}`;
                    label.htmlFor = label1;
                }
            });
        });

        const commonSelector = '.req-call-tablist .req-call-radio';
        const radioButtons = document.querySelectorAll(commonSelector);
        radioButtons?.forEach(element => {
            const radioButton = element as HTMLInputElement;
            // To ensure the radio button is checked
            if (radioButton.hasAttribute('checked') || radioButton.checked) {
                radioButton.checked = true;
            }
        });
    }
}

const residentialForm = new ResidentialForm();