import { tns } from "tiny-slider"

export class DateSlider {
  private nextDate: HTMLElement;
  private prevDate: HTMLElement;
  private dateBlock: HTMLElement;
  private dateBlockWidth: number;

  constructor() {
    this.nextDate = document.getElementById('show-next-dates')!;
    this.prevDate = document.getElementById('show-prev-dates')!;
    this.dateBlock = document.querySelector('.date-details-list') as HTMLElement;
    this.dateBlockWidth = this.dateBlock?.scrollWidth;
    const cancelVerification = document.querySelectorAll(".cancel-verification");
    const nextBtns = document.querySelectorAll(".btn-next");
    this.nextDate?.addEventListener('click', this.slideRight.bind(this));
    this.prevDate?.addEventListener('click', this.slideLeft.bind(this));

    if (this.dateBlockWidth < 1232) {
      this.prevDate?.classList.add("disabled");
      this.dateBlock.onscroll = this.handleScroll.bind(this);
    }

    // Date Select Slider

  this.setTinySlider();
  this.focusTabElement();  

  this.scrollTop(cancelVerification);
  this.scrollTop(nextBtns);
  }

  public scrollTop(element: any) {
    element.forEach((btn: any) => {
      btn.addEventListener("click", () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      });
    });
  }

  setTinySlider() {

    try {
      var dtlslider = tns({
        container: '.date-slider',
        loop: false,
        items: 7,
        slideBy: 'page',
        nav: false,
        autoplay: false,
        speed: 400,
        swipeAngle: false,
        autoplayButtonOutput: false,
        mouseDrag: true,
        lazyload: true,
        controlsContainer: "#customize-controls",
        responsive: {
          350: {
            items: 5,
          },
          640: {
            items: 5,
          },
          768: {
            items: 5,
          },
          1024: {
            items: 7,
          }
        }

      });

    } catch (error) {

    }
  }

  private slideLeft() {
    this.dateBlock.scrollBy(-100, 0);
  }

  private slideRight() {
    this.dateBlock.scrollBy(100, 0);
  }

  private handleScroll() {
    const blockWidth = this.dateBlock.scrollLeft;
    const scrollWidth = this.dateBlock.scrollWidth;
    const blockOffset = this.dateBlock.offsetWidth;

    if (blockWidth === 0) {
      this.prevDate.classList.add("disabled");
      this.nextDate.classList.remove("disabled");
    }

    if (blockWidth > 10) {
      this.nextDate.classList.remove("disabled");
      this.prevDate.classList.remove("disabled");
    }

    if (scrollWidth - blockWidth === blockOffset) {
      this.nextDate.classList.add("disabled");
      this.prevDate.classList.remove("disabled");
    }
  }

  private focusTabElement(): void {
    const focusableElements: string = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal: HTMLInputElement | null = document.querySelector('.custom-select');
  
    if (!modal) {
      return; 
    }
  
    const firstFocusableElement: HTMLElement = modal.querySelectorAll<HTMLElement>(focusableElements)[0];
    const focusableContent: NodeListOf<HTMLElement> = modal.querySelectorAll<HTMLElement>(focusableElements);
    const lastFocusableElement: HTMLElement = focusableContent[focusableContent.length - 1];
  
    document.addEventListener('keydown', function (e: KeyboardEvent): void {
      let isTabPressed: boolean = e.key === 'Tab';
  
      if (!isTabPressed) {
        return;
      }
  
      if (e.shiftKey && document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus(); 
          e.preventDefault();
        
      } else if (document.activeElement === lastFocusableElement) {
         
          firstFocusableElement.focus(); 
          e.preventDefault();
      }
    });
  
    firstFocusableElement.focus();
  }
  
}



if((document.getElementById('conceptId') as HTMLInputElement)?.value !== '25'){
document.addEventListener("DOMContentLoaded", () => {
  window.scrollTo(0, 0);
});

const dateSlider = new DateSlider();
}