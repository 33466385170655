import { getBrandJSON } from "../opus2/util/locationBasedBrandJson";
import apiRequest from "../opus2/api/apiRequest";

export class StorageManager {
    
    expiryThreshold: number;
    expiryTimeKey: string;
    expiryType: string;
    expiryTimeMap: { [key: string]: number };
    localStorageItems: string[];
    clearAllLocalStorge: boolean;
    clearAllSessionStorge: boolean;
  
    constructor(

      //if there is no value set in the env file, it would default to days
      expiryThreshold: number = (process.env.JS_STORAGE_CLEARING_INTERVAL && !isNaN(parseInt(process.env.JS_STORAGE_CLEARING_INTERVAL))) ? parseInt(process.env.JS_STORAGE_CLEARING_INTERVAL) : 7,
      expiryType: string = (process.env.JS_STORAGE_CLEARING_DURATION_TYPE?.toLowerCase()) ?? 'days',

      expiryTimeKey: string = 'expiryTime',

      //Add more values that need to be cleared in the local storage in this array
      localStorageItems: string[] = ['brandDetails', 'fullAttrOnLoad'],
      
      //Set this to true if we need all the data in the local Storage to be cleared;
      //Default if no value is present in the env file is false
      clearAllLocalStorge = (process.env.JS_CLEAR_LOCAL_STORAGE && typeof JSON.parse(process.env.JS_CLEAR_LOCAL_STORAGE.toLowerCase()) === 'boolean') ? JSON.parse(process.env.JS_CLEAR_LOCAL_STORAGE.toLowerCase()) : false,
      
      //Set this to true if we need to extend this functionality to clear Session Storage as well;
      //Default if no value is present in the env file is false
      clearAllSessionStorge: boolean = (process.env.JS_CLEAR_SESSION_STORAGE && typeof JSON.parse(process.env.JS_CLEAR_SESSION_STORAGE.toLowerCase()) === 'boolean') ? JSON.parse(process.env.JS_CLEAR_SESSION_STORAGE.toLowerCase()) : false
,
    
    ) {
        this.expiryThreshold = expiryThreshold;
        this.expiryTimeKey = expiryTimeKey;
        this.expiryType = expiryType;
        this.localStorageItems = localStorageItems;
        this.clearAllLocalStorge = clearAllLocalStorge;
        this.clearAllSessionStorge = clearAllSessionStorge;
        this.expiryTimeMap = {
            days: 24 * 60 * 60 * 1000,
            hours: 60 * 60 * 1000,
            minutes: 60 * 1000,
            seconds: 1000,
        };

    //   Setting a 2 second timeout so that all values are set in the page before clearing the local storage
      setTimeout(() => {
          this.init();
      }, 2000);
    }
  
    //Method to convert the secondary selection type (days, hours..) in milliseconds
    getExpiryTimeInMilliseconds(): number {
      return this.expiryThreshold * (this.expiryTimeMap[this.expiryType] || 24 * 60 * 60 * 1000);
    }
  
    //Method to check if the expiry condition has been met, it would return true if there is no expiry object
    isExpired(): boolean {
      const expiryTime = localStorage.getItem(this.expiryTimeKey);
      if (!expiryTime) {
        return true;
      }
      const currentTime = Date.now();
      const storedExpiryTime = JSON.parse(expiryTime);
      return currentTime > storedExpiryTime;
    }
  
    //This method sets the expiry time as a local object for fresh expiry time
    setExpiryTime(): void {
      const expiryTime = Date.now() + this.getExpiryTimeInMilliseconds();
      localStorage.setItem(this.expiryTimeKey, JSON.stringify(expiryTime));
    }

    //This is an extended functionality if we require all the APIs to be triggered again
    async callAllAPIs(){

        //1. Fetching Brand JSON
        const conceptCodeElement = document.getElementById('conceptCode') as HTMLInputElement;
        if (conceptCodeElement) {
            const conceptCode = conceptCodeElement.value;
            try {
                const brandJson = getBrandJSON();
                if(brandJson === 'Error validating Location'){
                    console.error("Error Determining BrandJSON path for given location");
                    return;
                }
                else{
                    const origin = window.location.origin;
                    const request = {
                        method: 'GET',
                        url: `${origin}/${brandJson}`
                    };
        
                    const result :any = await apiRequest(request);
                    const brandDetails = result.find((item: any) => item.code === conceptCode);
        
                    localStorage.setItem("brandDetails", JSON.stringify(brandDetails));
                    return brandDetails;
                }
                
        
            } catch (error) {
                console.error('Error in fetching getBrandDetailsFromBrandJson API data:', error);
                return [];
            }
        }
        
    }

    //Local storage clearing method
    clearLocalStorage(): void {
        if(!this.clearAllLocalStorge){
            this.localStorageItems.forEach(item => {
                localStorage.removeItem(item);
            });
        }
        else{
            localStorage.clear();
        }
    }
    
    //Session storage clearing method
    clearSessionStorage(): void {
        if(this.clearAllSessionStorge){
            sessionStorage.clear();
        }
    }

    //Method to trigger clearing if the user in on the same page when the event occurs
    triggerClearing(): void {
        const expiryTimeStr = localStorage.getItem(this.expiryTimeKey);
        if (!expiryTimeStr) {
          return;
        }
        try {
          const expiryTime = JSON.parse(expiryTimeStr);
          const currentTime = Date.now();
          const timeLeftForExpiry = expiryTime - currentTime;
      
          if (timeLeftForExpiry <= 0) {
            this.clearLocalStorage();
            this.clearSessionStorage();
            this.setExpiryTime();
          } else {
            setTimeout(() => {
              this.clearLocalStorage();
              this.clearSessionStorage();
              this.setExpiryTime();
            }, timeLeftForExpiry);
          }
        } catch (error) {
          console.error('Error while clearing storage in triggerClearing:', error);
        }
    }
  
    //Initilaizing function that would run after 2 seconds of pageload
    init(): void {
      if (this.isExpired()) {
        this.clearLocalStorage();
        this.clearSessionStorage();

        //This is to set the new expiry time
        this.setExpiryTime();
          
        //Uncomment this if we need to call all APIs on page load.
        //this.callAllAPIs()
      }
      else{
        this.triggerClearing();
      }
    }
  }
new StorageManager();
  