export class InspirationGallery {
    public insGalleryDomPresent = (document.querySelector('.gallery-page-section') as HTMLElement)?.classList?.contains('inspiration-gallery');
    public loadMoreButton:any =document.querySelector('.gallery-page-main-inspiration .cta-wrapper .btn') as HTMLElement;
    public ImageCagetegory:any =  (document.getElementById('all') as HTMLInputElement)?.value?.toLocaleLowerCase();
    constructor(){
       if(this.insGalleryDomPresent){
        const insCategory:any = document.querySelectorAll('.category-radio')
        insCategory?.forEach((item:any)=>{
            item?.addEventListener('change', ()=>{
                this.getImageTabType(item.getAttribute('id')?.toLocaleLowerCase())
            })
        })
        this.loadMore();
      }
    }
    public getImageTabType(imageCat:any){
        this.ImageCagetegory = '';
        this.ImageCagetegory = imageCat;
        if (this.loadMoreButton) { 
            this.loadMoreButton.style.display = 'flex';
        }
        const imageList:any = document.querySelectorAll('.gallery-page-main-inspiration .photo-card-list .photo-card-item');
        const allTab = (document.getElementById('all') as HTMLInputElement)?.value;
        if(imageList.length>0){
            if(imageCat !== allTab.toLocaleLowerCase()){
            imageList.forEach((insImagesLi:any)=>{
                if(insImagesLi?.getAttribute('category-id')?.toLocaleLowerCase() === imageCat){
                    insImagesLi?.classList?.remove('hidden');
                }else {
                    insImagesLi?.classList?.add('hidden');
                }
            })
        }else {
            const firstFiveItems = Array.from(imageList).slice(0, 5);
            const afterFiveItems = Array.from(imageList).slice(firstFiveItems.length, imageList.length)
            firstFiveItems?.forEach((insImagesLi:any)=>{
                insImagesLi?.classList?.remove('hidden');
            })
            afterFiveItems?.forEach((insImagesLi:any)=>{
                insImagesLi?.classList?.add('hidden');
            })
        }
        }
    }

    public loadMore(){
        const allTab = (document.getElementById('all') as HTMLInputElement)?.value ?? '';
        this.loadMoreButton?.addEventListener('click', (e:any)=> {
            e.preventDefault();
            const hiddenItems = (this.ImageCagetegory === allTab.toLocaleLowerCase()) ? document.querySelectorAll('.photo-card-item.hidden') : document.querySelectorAll(`li.photo-card-item.hidden[category-id="${this.ImageCagetegory}"]`);
            const initialHiddenItemCount = hiddenItems.length;
            const nextHiddenItems = Array.from(hiddenItems).slice(0, 6);
            nextHiddenItems?.forEach(item => {
           item.classList.remove('hidden');
           });
          const remainingHiddenItemCount = initialHiddenItemCount - nextHiddenItems.length;
       if (remainingHiddenItemCount === 0) {
           this.loadMoreButton.style.display = 'none';
        }
      });
    }
}

new InspirationGallery()