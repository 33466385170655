export class GiftConfirmation {
    private giftPurchaseDom: HTMLElement | null;
    private giftPurchaseSuccess: HTMLElement | null;
    private giftPurchaseFail: HTMLElement | null;
  
    constructor() {
      this.giftPurchaseDom = document.querySelector('.gift-purchase-msg') as HTMLElement;
      this.giftPurchaseSuccess = document.querySelector('.gift-success') as HTMLElement;
      this.giftPurchaseFail = document.querySelector('.gift-fail') as HTMLElement;
    }
  
    public displayMessage(success: boolean) {
      if (this.giftPurchaseDom) {
        this.giftPurchaseDom.style.display = 'flex';
  
        if (success) {
          this.showSuccessMessage();
        } else {
          this.showFailureMessage();
        }
      }
    }
  
    private showSuccessMessage() {
      if (this.giftPurchaseSuccess && this.giftPurchaseFail) {
        this.giftPurchaseSuccess.style.display = 'flex';
        this.giftPurchaseFail.style.display = 'none';
      }
    }
  
    private showFailureMessage() {
      if (this.giftPurchaseSuccess && this.giftPurchaseFail) {
        this.giftPurchaseSuccess.style.display = 'none';
        this.giftPurchaseFail.style.display = 'flex';
      }
    }

    public pushHTMLToHistoryAndCleanUrl = () => {
        const htmlContent = document.documentElement.outerHTML;
        const pageTitle = document.title;
        const cleanUrl = window.location.origin + window.location.pathname;
        
        window.history.pushState({ html: htmlContent, pageTitle: pageTitle }, "", cleanUrl);
    };
  }
  