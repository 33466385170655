import { getBrandDetailsFromBrandJson } from "../util/share";
import { formatPhone } from "./formatPhone";
import { FranchiseDetail } from "./models/FranchiseDetail";

export const getBubbleContent = async (franchiseDetail: FranchiseDetail): Promise<string> => {
    let franchise_addressLine_1_2 = `${franchiseDetail.address1 ?? ''} ${franchiseDetail.address2 ?? ''}, ${franchiseDetail.state ?? ''},${franchiseDetail.zipCode ?? ''}`;
    let addressLine_1_2 = `<p class="modal-text">${franchise_addressLine_1_2} </p>`;
    const addrLine1 = (franchiseDetail.showAddress && franchiseDetail.address1 && franchiseDetail.address1.length > 0) ? `<span>${franchiseDetail.address1}</span><br>` : '';
    const addrLine2 = (franchiseDetail.showAddress && franchiseDetail.address2 && franchiseDetail.address2.length > 0) ? `<span>${franchiseDetail.address2}</span><br>` : '';
    
    const addrLine3 = `${(franchiseDetail.city && franchiseDetail.city.length > 0) ? franchiseDetail.city + ', ' : ''}
        ${(franchiseDetail.state && franchiseDetail.state.length > 0) ? franchiseDetail.state + ', ' : ''}
        ${(franchiseDetail.country && franchiseDetail.country.length > 0) ? franchiseDetail.country + ', ' : ''}
        ${(franchiseDetail.zipCode && franchiseDetail.zipCode.length > 0) ? franchiseDetail.zipCode : ''}
    `;
   
    const websiteTrackingNumber: any = franchiseDetail.websiteTrackingPhone[0] ? franchiseDetail.websiteTrackingPhone[0] : franchiseDetail.phoneNumber;
    let reqHref = `https://${window.location.hostname}/schedule-appointment`;
    const requestEstimateText = (document.getElementById('request-estimate-label') as HTMLInputElement)?.value;
    const showWebsiteText = (document.getElementById('view-website-label') as HTMLInputElement)?.value;

    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    const brandData = await getBrandDetailsFromBrandJson(conceptCode);
    
    return `<div class="modal-body">
    <div class="modal-heading">${franchiseDetail.doingBusinessAs}</div>
    <p class="modal-text">
        ${addrLine1}
        ${addrLine2}
        ${addrLine3}
    </p>
    </div>
    <div class="modal-cta">
    <a href="tel:${websiteTrackingNumber}" class="modal-link">${websiteTrackingNumber.includes('(') ? websiteTrackingNumber : formatPhone(websiteTrackingNumber)}</a>
    <a href=${brandData?.request_button_link_type ? brandData?.request_button_link_type : reqHref} class="modal-link">${requestEstimateText}</a>
    <a href="${franchiseDetail.websiteUrl}" class="modal-link">${showWebsiteText}</a>
    </div>`;

}

