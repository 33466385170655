export function brandLocalHeader(){
    const storedWebId = localStorage.getItem("franchiseWebLocationId");
    const parsedWebId = storedWebId !== null ? JSON.parse(storedWebId) : null;
    const localHeader = document.querySelector(".brand-header-local") as HTMLDivElement
    

    if (localHeader) { // Check if localHeader is not null
        if (parsedWebId) {
          // localHeader.hidden = false;
        } else {
          // localHeader.hidden = true;
          // localHeader.style.display = "none";
          // localHeader.classList.remove("brand-header-local");
        }
      }
}

brandLocalHeader();
