import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { bookingUsPostCallWrapper } from "../apiCaching/POST-Requests/apibookingCallWrapper";
import { isTestEnvironment } from "../apiCaching/POST-Requests/helperModules/helperFunctions";
import { BookingPayloadModel } from "../apiCaching/POST-Requests/interface/booking/bookingInterface";
import { errorMessage } from "../common/errorMessages";
import { apiConfig } from "../config/apiConfig";
import { apiCaching } from "../config/cachingConfig";
import { REGEX } from "../constants/regex";
import { getCountryCode } from "../location-search-map/getCountryCode";
import { disclaimerHandleCheck, DisclaimerServiceIdentifier } from "../util/disclaimerService";
import { allAddressList, discoverSuggestions, getAddressDetails, handleMissingData } from "../util/discovery";
import { DomainIdentifier } from "../util/domainService";
import { handleMobileInput } from "../util/errorHandlers";
import { gtmCustomEventLeadFlow } from "../util/gtm-custom-event-lead-flow";
import { hideTermsPolicy, toggleTermsPolicyBasedOnCountry } from "../util/hideTermsPolicy";
import { startLoader, stopLoader } from "../util/loader";
import { LocalizationIdentifier } from "../util/localizationService";
import { debounce, getBrandDetailsFromBrandJson, getCampaignName, storeBookingApiPayload } from "../util/share";
import { insuranceErrorMessage } from "./inuranceErrorMessage";
import {  BrowserMultiFormatReader, NotFoundException } from '@zxing/library';

export class InsuranceForm {
    private firstName!: HTMLInputElement;
    private lastName!: HTMLInputElement;
    private zipCode!: HTMLInputElement;
    private serviceAddress!: HTMLInputElement;
    private serviceAddress2!: HTMLInputElement;
    private phoneNumber!: HTMLInputElement;
    private email!: HTMLInputElement;

    private insuranceCompany!: HTMLInputElement;
    private insuranceCompanyPhoneNumber!: HTMLInputElement;
    private insurancePolicyNumber!: HTMLInputElement;
    private insuranceCauseLoss!: HTMLInputElement;
    private insuranceClaim!: HTMLInputElement;
    private insuranceDeductible!: HTMLInputElement;
    private insuranceDateOfLoss!: HTMLInputElement;
    private vehicleTab!: HTMLElement;
    private carYear!: HTMLInputElement;
    private carMake!: HTMLInputElement;
    private carModel!: HTMLInputElement;
    private carStyle!: HTMLInputElement;
    private checkboxList!: NodeListOf<HTMLInputElement>;
    private insuranceAgentName!: HTMLInputElement;
    private insuranceAgencyName!: HTMLInputElement;
    private insuranceAgencyZip!: HTMLInputElement;
    private insuranceAgencyAddress!: HTMLInputElement;
    private insuranceAgencyEmail!: HTMLInputElement;
    private insuranceAgencyPhone!: HTMLInputElement;
    private insuranceComments!: HTMLTextAreaElement;
    private submitButton!: HTMLButtonElement;
    private vinSearchInput!: HTMLInputElement;
    private vinYearInput!: HTMLInputElement;
    private vinMakehInput!: HTMLInputElement;
    private vinModelInput!: HTMLInputElement;
    private vinStyleInput!: HTMLInputElement;
    private vinErrorIdMsg!: HTMLElement;
    private vinErrorMsgText!: string;
    private videoElement: any;
    private scanTimeoutId: number | null = null;
    private readonly insuranceFormService!: NodeListOf<HTMLElement>;

    private static instance: InsuranceForm;

    static getInstance(): InsuranceForm {
        if (!InsuranceForm.instance) {
            InsuranceForm.instance = new InsuranceForm();
        }
        return InsuranceForm.instance;
    }

    constructor() {
        this.initFormFields();
        this.addEventListeners();
        this.addInputEventListeners();
        this.clearNextInputsOnTyping();
        this.insuranceFormService = document.querySelectorAll('[id*="new-lead-service-reminder"]');
        const localisation = DomainIdentifier();
        const countryCode = getCountryCode();
        const localisedcountryCode = sessionStorage.getItem('countryCode');
        if (localisation == 'ca' || countryCode === 'ca' || localisedcountryCode === 'ca') {
            hideTermsPolicy('.insurance-form-main', 'terms-policy-text');
        }
    }

    clearNextInputsOnTyping() {
        const allInputsId = ["car-year","car-make","car-model","car-style"];
        const allVinInputsId = ["vin-year","vin-make","vin-model","vin-style"];

        // Combine all inputs with their carModel values
        const carStyle = document.getElementById('car-style') as HTMLInputElement;
        const carYear = document.getElementById('car-year') as HTMLInputElement;
        const carMake = document.getElementById('car-make') as HTMLInputElement;
        const carModel = document.getElementById('car-model') as HTMLInputElement;

        const vinStyle = document.getElementById('vin-style') as HTMLInputElement;
        const vinYear = document.getElementById('vin-year') as HTMLInputElement;
        const vinMake = document.getElementById('vin-make') as HTMLInputElement;
        const vinModel = document.getElementById('vin-model') as HTMLInputElement;

        const updateCarField = (input: HTMLInputElement, carField: HTMLInputElement) => {
            if (carField) carField.value = input.value;
        };

        document.querySelectorAll("#car-modal input")?.forEach((input:any, index, inputs:any) => {
            input.addEventListener('input', () => {
              allInputsId?.forEach((id) => {
                const input = document.getElementById(id) as HTMLInputElement;
                if (input) {
                    switch (input.id) {
                        case 'car-style':
                            updateCarField(input, vinStyle);
                            break;
                        case 'car-year':
                            updateCarField(input, vinYear);
                            break;
                        case 'car-make':
                            updateCarField(input, vinMake);
                            break;
                        case 'car-model':
                            updateCarField(input, vinModel);
                            break;
                        
                    }
                }
            });
            });
          });
        
        document.querySelectorAll("#vin .form-column input")?.forEach((input: any, index, inputs: any) => {
            input.addEventListener('input', () => {
                allVinInputsId.forEach((id) => {
                    const input = document.getElementById(id) as HTMLInputElement;
                    if (input) {
                        switch (input.id) {
                            case 'vin-style':
                                updateCarField(input, carStyle);
                                break;
                            case 'vin-year':
                                updateCarField(input, carYear);
                                break;
                            case 'vin-make':
                                updateCarField(input, carMake);
                                break;
                            case 'vin-model':
                                updateCarField(input, carModel);
                                break;
                            
                        }
                    }
                });
            });
        });
    }

    private async scanBarcodeService() {
        this.appendScannerFullscreen(); // Adding Video Tag Scanner
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: { facingMode: { ideal: "environment" } },
            });
            if (this.videoElement) {
                this.videoElement.srcObject = stream;
                this.videoElement.style.display = 'block'; // Show the video element
                this.videoElement.play();
            }
            this.decodeFromVideoDevice();
        } catch (err) {
            console.error("Error accessing webcam: ", err);
            // Fallback to default camera if facingMode constraint fails
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: true,
                });
                if (this.videoElement) {
                    this.videoElement.srcObject = stream;
                    this.videoElement.style.display = 'block'; // Show the video element
                    this.videoElement.play();
                }
                this.decodeFromVideoDevice();
            } catch (fallbackErr) {
                console.error("Error accessing default webcam: ", fallbackErr);
            }
        }
    }

    private stopScanning() {
        if (this.videoElement) {
            const stream = this.videoElement.srcObject as MediaStream;
            console.log("Get Stream tracks",stream?.getTracks());
            const tracks = stream?.getTracks();
            tracks?.forEach((track) => track.stop());
            this.videoElement.srcObject = null;
            console.log('Scanning stopped');
        }
        this.videoElement.style.display = 'none'; // Hide the video element
           // Clear the timeout if it exists
           if (this.scanTimeoutId !== null) {
            clearTimeout(this.scanTimeoutId);
            this.scanTimeoutId = null;
        }

        const fullVideoScreen = document.querySelector('.scanner-fullscreen') as HTMLElement;
        if (fullVideoScreen) {
            fullVideoScreen.remove();
        }
    }

    private readonly decodeFromVideoDevice = async () => {
        const reader = new BrowserMultiFormatReader();
        const vinInput = this.vinSearchInput;
        reader.decodeFromVideoDevice(
          null,
          this.videoElement,
          async (result,err) => {
            if (result) {
                await this.getVehicleDetails(result.getText());
                console.log("Barcode result: ", result.getText());
                vinInput.value = result.getText();
                this.stopScanning();
                reader.stopContinuousDecode();
            }

            if (err && !(err instanceof NotFoundException)) {
                console.error('Error decoding barcode:', err);
            }
            
          }
        );
        
      };

    private initFormFields(): void {
        const insuranceFormSection = document.querySelector('.insurance-form-section') as HTMLElement;
        this.firstName = insuranceFormSection?.querySelector('#insurance-fname') as HTMLInputElement;
        this.lastName = insuranceFormSection?.querySelector('#insurance-lname') as HTMLInputElement;
        this.zipCode = insuranceFormSection?.querySelector('#insurance-zip-code') as HTMLInputElement;
        this.serviceAddress = insuranceFormSection?.querySelector('#insurance-address') as HTMLInputElement;
        this.serviceAddress2 = insuranceFormSection?.querySelector('#insurance-address-2') as HTMLInputElement;
        this.phoneNumber = insuranceFormSection?.querySelector('#insurance-pnumber') as HTMLInputElement;
        this.email = insuranceFormSection?.querySelector('#insurance-email') as HTMLInputElement;

        this.insuranceCompany = insuranceFormSection?.querySelector('#insurance-company') as HTMLInputElement;
        this.insuranceCompanyPhoneNumber = insuranceFormSection?.querySelector('#insurance-company-phone') as HTMLInputElement;
        this.insurancePolicyNumber = insuranceFormSection?.querySelector('#insurance-policy-number') as HTMLInputElement;
        this.insuranceCauseLoss = insuranceFormSection?.querySelector('#insurance-cause-loss') as HTMLInputElement;
        this.insuranceClaim = insuranceFormSection?.querySelector('#insurance-claim') as HTMLInputElement;
        this.insuranceDeductible = insuranceFormSection?.querySelector('#insurance-deductible') as HTMLInputElement;
        this.insuranceDateOfLoss = insuranceFormSection?.querySelector('#insurance-date-loss') as HTMLInputElement;

        this.vehicleTab = insuranceFormSection?.querySelector('#vehicle-tab') as HTMLElement;
        this.carYear = insuranceFormSection?.querySelector('#car-year') as HTMLInputElement;
        this.carMake = insuranceFormSection?.querySelector('#car-make') as HTMLInputElement;
        this.carModel = insuranceFormSection?.querySelector('#car-model') as HTMLInputElement;
        this.carStyle = insuranceFormSection?.querySelector('#car-style') as HTMLInputElement;
        this.checkboxList = insuranceFormSection?.querySelectorAll('.checkbox-list input[type="checkbox"]');
        this.vinSearchInput = insuranceFormSection?.querySelector('#vin-search') as HTMLInputElement;
        this.vinYearInput = insuranceFormSection?.querySelector('#vin-year') as HTMLInputElement;
        this.vinMakehInput = insuranceFormSection?.querySelector('#vin-make') as HTMLInputElement;
        this.vinModelInput = insuranceFormSection?.querySelector('#vin-model') as HTMLInputElement;
        this.vinStyleInput = insuranceFormSection?.querySelector('#vin-style') as HTMLInputElement;
        this.vinErrorIdMsg = document.getElementById('vin-search-error-msg') as HTMLElement;
        this.vinErrorMsgText = this.vinErrorIdMsg?.innerText;

        this.insuranceAgentName = insuranceFormSection?.querySelector('#insurance-agent-name') as HTMLInputElement;
        this.insuranceAgencyName = insuranceFormSection?.querySelector('#insurance-agency-name') as HTMLInputElement;
        this.insuranceAgencyZip = insuranceFormSection?.querySelector('#insurance-agency-zip') as HTMLInputElement;
        this.insuranceAgencyAddress = insuranceFormSection?.querySelector('#insurance-agency-address') as HTMLInputElement;
        this.insuranceAgencyEmail = insuranceFormSection?.querySelector('#insurance-agency-email') as HTMLInputElement;
        this.insuranceAgencyPhone = insuranceFormSection?.querySelector('#insurance-agency-phone') as HTMLInputElement;

        this.insuranceComments = insuranceFormSection?.querySelector('#insurance-comments') as HTMLTextAreaElement;

        this.submitButton = insuranceFormSection?.querySelector('.submit-btn') as HTMLButtonElement;
        const vinFindVehicleBtn = document.querySelector(".vin-search-wrap .secondary-btn");
        vinFindVehicleBtn?.addEventListener('click', this.handleVinSearch.bind(this));

        const scanVinButton = document.querySelector(".vehicle-vin-tab .primary-outline-btn") as HTMLButtonElement;
        scanVinButton?.addEventListener('click', this.scanBarcodeService.bind(this));
        
    }

    private handleVinSearch(e: any) {
        e.preventDefault();
        const vin = this.vinSearchInput?.value?.replace(/\s/g, '');
        if (vin) {
            this.vinErrorIdMsg?.classList?.add('hidden');
            this.getVehicleDetails(vin);
        }else if (this.vinErrorIdMsg) {
                this.vinErrorIdMsg.textContent = this.vinErrorMsgText;
                if(this.vinErrorIdMsg){
                    this.vinErrorIdMsg.classList.remove('hidden');
                }
                this.resetVinFields();
        }
    }

    private async getVehicleDetails(vin: string) {
        startLoader();
        const request = {
            url: apiConfig.VIN_API_URL+vin+"?apikey="+process.env.JS_API_KEY
        };
        try {
            const result = await getCachedAPIResponse(request.url);
            this.handleVehicleApiResponse(result);
            stopLoader();
        } catch (error: any) {
            stopLoader();
            this.handleVehicleApiError(error);
        }
    }

    private handleVehicleApiResponse(result: any) {
        console.log('Vehicle details:', result);
        const data = result?.data;
        if (data) {
            // focus on the vehicle details
            const barCodeTxt = document.querySelector('.vin-barcode-text');
            if(barCodeTxt){
                  // Ensure the element is focusable
                (barCodeTxt as HTMLElement).setAttribute('tabindex', '0');
                (barCodeTxt as HTMLElement)?.focus();
            }
            this.updateVehicleField(data.modelYear, this.vinYearInput, this.carYear);
            this.updateVehicleField(data.make, this.vinMakehInput, this.carMake);
            this.updateVehicleField(data.model, this.vinModelInput, this.carModel);
            this.updateVehicleField(data.bodyClass, this.vinStyleInput, this.carStyle);
            console.log("Vehicle details added.");
        } else {
            console.error('Vehicle data is undefined');
        }
    }

    private updateVehicleField(value: string, vinField: HTMLInputElement, carField: HTMLInputElement) {
        if (value) {
            if (vinField) vinField.value = value;
            if (carField) carField.value = value;
            // Hide the input all VinInput elements
            if(vinField) vinField.setAttribute('disabled','true');
            this.hideVehicleInputsErrorMsg(vinField?.id);
            this.hideVehicleInputsErrorMsg(carField?.id);
        }
    }

    hideVehicleInputsErrorMsg(id: string) {
        const vinErrorIdMsg = document.getElementById(`${id}-error-msg`) as HTMLElement;
        const inputElement = document.getElementById(id) as HTMLInputElement;
        inputElement?.classList.remove("invalid-field");
        if (vinErrorIdMsg) {
            vinErrorIdMsg.classList.add('hidden');
        }
    }

    private handleVehicleApiError(error: any) {
        console.log('Error while fetching vehicle details:', error);
        const errorMessage = "No vehicle was found by this VIN number.";
        if (this.vinErrorIdMsg) {
            this.vinErrorIdMsg.textContent = errorMessage;
            if(this.vinErrorIdMsg){
                this.vinErrorIdMsg.classList.remove('hidden');
            }
            this.resetVinFields();
        }

    }

    // Reset Vin input fields
    private resetVinFields() {    
        if(this.vinYearInput)  this.vinYearInput.value = '';
        if (this.vinMakehInput) this.vinMakehInput.value = '';
        if (this.vinModelInput) this.vinModelInput.value = '';
        if (this.vinStyleInput) this.vinStyleInput.value = '';
    }

    private readonly debouncedSearch = debounce(async (zipCode: string, address: string) => {
        await discoverSuggestions(zipCode, address);
    }, 500);

    private readonly setAutoSuggested = async (e: any) => {

        e.preventDefault();

        sessionStorage.setItem("suggestion", "false");
        this.serviceAddress.removeAttribute("suggestion");
        sessionStorage.setItem("manualCity", "");
        sessionStorage.setItem("manualState", "");
        this.debouncedSearch(this.zipCode, this.serviceAddress);
    }
    private addEventListeners(): void {
        this.submitButton?.addEventListener('click', this.handleSubmit.bind(this));

        const serviceRequestedCheckList = document.querySelectorAll<HTMLInputElement>('.insurance-form-section .service-requested-insurance input[type="checkbox"]');

        // Add eventlistner 
        if (serviceRequestedCheckList?.length > 0) {
            serviceRequestedCheckList?.forEach((checkbox) => {
                checkbox.addEventListener('change', () => {
                    this.handleServiceRequestCheckboxChange();
                });
            });
        }
    }

    private handleServiceRequestCheckboxChange() {
        const checkBoxItems = document.querySelector<HTMLInputElement>('.insurance-form-section .service-requested-insurance');
        const checkBoxList = document.querySelectorAll<HTMLInputElement>('.insurance-form-section .service-requested-insurance input[type="checkbox"]')
        const serviceRequestedArr = this.serviceRequested();
        if (checkBoxItems?.getAttribute('required') !== null && checkBoxList?.length > 0) {
            const errorMessageHtml = document.getElementById('glass-type-error-msg') as HTMLElement;
            if (serviceRequestedArr.length > 0) {
                errorMessageHtml?.classList.add('hidden');
            } else {
                errorMessageHtml?.classList.remove('hidden');
            }
        }
    }

    public getAddressDetails(formData: any) {
        let item = allAddressList?.filter((item: any) => item.title.trim() == formData?.address?.trim())[0];
        let manualCity = sessionStorage.getItem("manualCity");
        let manualState = sessionStorage.getItem("manualState");
        let city = manualCity ?? (item?.address.city || "Not Specified");
        let state = manualState ?? (item?.address.state || "Not Specified");
        let country = item?.address.countryCode || "Not Specified";
        let finalAddress: any = formData?.address;
        let getsuggestionFlag = sessionStorage.getItem("suggestion");
        const suggestAddr = sessionStorage.getItem('suggestedAddress') as string;

        if (getsuggestionFlag === "true" && suggestAddr) {
            city = JSON.parse(suggestAddr).city;
            state = JSON.parse(suggestAddr).stateCode;
            finalAddress = JSON.parse(suggestAddr).addr1;
            if(JSON.parse(suggestAddr)?.country){
                country = JSON.parse(suggestAddr).country;
            }
        }

        return { city, state, finalAddress ,country };
    }
    private async handleSubmit(event: Event): Promise<void> {

        event.preventDefault();
        if (this.validateFields()) {
            console.log('submit button clicked');
            try {
                const data = {
                    insuranceFname: this.firstName.value,
                    insuranceLname: this.lastName.value,
                    insuranceZipCode: this.zipCode.value,
                    insuranceAddress: this.serviceAddress.value,
                    insuranceAddress2: this.serviceAddress2.value,
                    insuranceEmail: this.email.value,
                    insurancePhone: this.phoneNumber.value,
                };
                console.log('insurance form data:', data);
                const addrFlag = sessionStorage.getItem("suggestion");
                const enteredAddr = this.serviceAddress?.value.concat(" ", this.serviceAddress2.value, " ", this.zipCode?.value);
                const suggestAddr = this.serviceAddress?.getAttribute("data-suggestion") as string;
                if (addrFlag === "false" && suggestAddr != null && suggestAddr !== 'undefined' && enteredAddr) {
                    this.openPropertyValidationModal(enteredAddr, suggestAddr);
                } else {
                    let zipCode = data.insuranceZipCode;

                    if (zipCode) {
                        this.franchiseLookupAddressApi(zipCode);
                    } else {
                        // Display the error msg if zipcode is empty
                        stopLoader();
                        this.throwErrorModal();
                    }

                }
            } catch (e) {
                console.log('Error while submitting Insurance form', e);
            }
        }

    }
    public async franchiseLookupAddressApi(zipCode: any) {
        if (!zipCode) return;

        const request = {
            url: apiConfig.LocateLocationApiWithRoundRobinTrue.replace('sAddressParamValue', encodeURIComponent(zipCode))
        };

        try {
            const result = await getCachedAPIResponse(request.url, apiCaching.LocateLocationApiWithRoundRobinTrue);
            await this.handleApiResponse(result);
        } catch (error: any) {
            await this.handleError(error);
        }
    }
    async getBrandData(): Promise<any> {
        const conceptCodeElement = document.getElementById('conceptCode') as HTMLInputElement;
        if (!conceptCodeElement) {
            throw new Error('Concept code element not found');
        }
        const conceptCode = conceptCodeElement.value;
        if (!conceptCode) {
            throw new Error('Concept code is empty');
        }
        return await getBrandDetailsFromBrandJson(conceptCode);
    }
    private async handleError(error: any) {
        stopLoader();
        const brandData = await this.getBrandData();
        let errorMessage = JSON.parse(error?.message);
        if (errorMessage?.message) {
            if (errorMessage.message === 'Full address is needed to narrow down results') {
                const zipCode: any = this.zipCode?.value;
                const address: any = this.serviceAddress?.value;
                const completeAddress = `${address}, ${zipCode}`;
                this.franchiseLookupAddressApi(completeAddress);
            }
        } else {
            this.handleGenericError(brandData);
        }
    }

    public handleGenericError(brandData: any) {
        stopLoader();
        const btnClick = document.getElementById('insurance-form-error-modal-id');
        btnClick?.click();
        const zipMessage = document.getElementById('modal-zipcode-error');
        if (zipMessage) {
            zipMessage.innerHTML = `We're sorry. Something went wrong. Please try again later.`;
        }

    }
    public async handleApiResponse(result: any) {
        if (result?.length == 0) {
            await this.handleNoServicedArea();
        } else if (result?.length > 0) {
            this.bookingApiCall(result[0]);
        }
    }
    public async bookingApiCall(result: any) {
        startLoader();
        const { franchiseWebLocationId, franchiseId } = result;
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        const campaignName = getCampaignName();
        const brandData = await getBrandDetailsFromBrandJson(conceptCode);
        const zipCode = this.zipCode.value;
        let formData: any = {
            name: this.firstName.value + ' ' + this.lastName.value,
            address: this.serviceAddress.value,
            address2: this.serviceAddress2.value,
            city: '',
            state: '',
            zipCode: zipCode,
            email: this.email.value,
            phone: this.phoneNumber.value,
        };

        let { city, state, finalAddress, country } = this.getAddressDetails(formData);
        const payLoadObj = { formData, city, state,country, finalAddress, zipCode, conceptCode, campaignName, brandData, franchiseWebLocationId, franchiseId };
        let requestBody = this.createRequestBody(payLoadObj);

        // Handle missing city & state for Agency form 
        const agencyZipCode = this.insuranceAgencyZip?.value || '';
        const angencyMissCityState = await handleMissingData('', '', agencyZipCode);
        if(angencyMissCityState){
            requestBody.agencyCity = angencyMissCityState?.city || '';
            requestBody.agencyState = angencyMissCityState?.state || '';
        }
        // End handle missing city & state for Agency form

        const out = await handleMissingData(requestBody.city, requestBody.state, requestBody.zipCode);
        requestBody.city = out?.city || requestBody.city;
        requestBody.state = out?.state || requestBody.state;

        //Optin OputOut Email & Text In 
        requestBody = this.optinEmailCheck(requestBody);

        await bookingUsPostCallWrapper(apiConfig.BOOKING_API_URL, requestBody)
            .then((response: any) => {
                stopLoader();
                sessionStorage.setItem('insuranceConfirmation', JSON.stringify(requestBody));
                storeBookingApiPayload(requestBody, response); //store booking api payload
                // Removed campaign name session storage
                sessionStorage.removeItem('campaignName');

                const submitObj = {
                    event: 'opus_insurance_form_submit_ev',
                    status: 'success'
                };
                gtmCustomEventLeadFlow(submitObj);
                this.redirectConfirmationPage();

            })
            .catch((err) => {
                stopLoader();
                this.throwErrorModal();
                const submitObj = {
                    event: 'opus_insurance_form_submit_ev',
                    status: 'failure'
                };
                gtmCustomEventLeadFlow(submitObj);
            });
    }
    public redirectConfirmationPage() {
        const insuranceRedirectConfirmationUrl = (document.getElementById('insurance-redirect-confirmation') as HTMLInputElement)?.value;
        if (insuranceRedirectConfirmationUrl) {
            window.location.href = insuranceRedirectConfirmationUrl;
        }
    }
    public createRequestBody(payLoadObj: any) {
        const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value;
        const url = window.location.href;
        let vendorId;
        let vendorName;
        const { formData, city, state, finalAddress, zipCode, conceptCode, campaignName, brandData, franchiseWebLocationId, franchiseId ,country} = payLoadObj;
        if (payLoadObj.brandData) {
            vendorId = brandData.vendorId;
            vendorName = brandData.vendorName;
        }

        const emailOptOut = brandData.enable_emailOptOut;
        const defaultLeadSourceId = brandData.default_leadSourceID || 0;
        let noteComment = this.insuranceComments?.value ?? '';
        const serviceRequestedArr = this.serviceRequested().length > 0 ? this.serviceRequested() : [];
        const serviceText = (document.getElementById('insurance_type') as HTMLInputElement)?.value;
      
        const vin = this?.vinSearchInput?.value || '';
        const { carMake,carModel,carStyle,carYear} = this.getCarYearMakeModelStyle();
 
        const requestBody: BookingPayloadModel = {

            insuranceCompany: this.insuranceCompany?.value || '',
            insuranceCompanyPhoneNumber: this.insuranceCompanyPhoneNumber?.value || '',
            policyNumber: this.insurancePolicyNumber?.value || '',
            insuranceCauseOfLoss: this.insuranceCauseLoss?.value || '',
            claimNetworkReferralNumber: this.insuranceClaim?.value || '',
            deductibleAmount: parseFloat(this.insuranceDeductible.value) || 0,
            insuranceDateOfLoss: this.insuranceDateOfLoss?.value || '',
            agencyAgentName: this.insuranceAgentName?.value || '',
            agencyName: this.insuranceAgencyName?.value || '',
            agencyZipCode: this.insuranceAgencyZip?.value || '',
            agencyAddress: this.insuranceAgencyAddress?.value || '',
            agencyEmailAddress: this.insuranceAgencyEmail?.value || '',
            agencyDirectPhoneNumber: this.insuranceAgencyPhone?.value || '',
            agencyCity: '', // Take agency zip code and get city and state
            agencyState: '', // Take agency zip code and get city and state
            serviceRequested: serviceRequestedArr,
            year: carYear,
            make: carMake,
            model: carModel,
            bodyStyle: carStyle,
            serviceText: serviceText,
            vin: vin,
            // licensePlateNumber: '',
            // windshieldRepair: '',
            isLocalized: true,
            firstName: formData.name.split(' ')[0] || '',
            lastName: formData.name?.split(' ')[1] || ' ',
            zipCode: zipCode.trim(),
            postalCode: zipCode.trim(),
            email: formData.email,
            state: state,
            city: city,
            country: country,
            phone: formData.phone,
            address: finalAddress,
            address2: formData?.address2 ?? '',
            comments: '',
            signUpForUpdates: false,
            conceptId: Number(conceptId),
            currentPage: url,
            leadSource: "WEB",
            leadOrigin: "WEB",
            phoneType: '',
            isLeadOnly: true,
            campaignName: campaignName || 'N/A',
            callType: "WEB",
            conceptCalledId: Number(conceptId),
            emailOptOut: emailOptOut,
            vendorId: Number(vendorId),
            isTest: isTestEnvironment(),
            conceptCode: conceptCode,
            vendorName: vendorName,
            customerType: '',
            leadSourceId: defaultLeadSourceId,
            note: noteComment,
            webLocationId: 0
        };

        if (franchiseWebLocationId) {
            requestBody.webLocationId = franchiseWebLocationId;
        }
        if (franchiseId) {
            requestBody.franchiseId = franchiseId;
        }
    
        return requestBody;
    }
    public async handleNoServicedArea() {
        stopLoader();
        const btnClick = document.getElementById('insurance-form-error-modal-id');
        btnClick?.click();
        const noServiceEle = document.getElementById('insurance-form-no-service');
        if (noServiceEle) {
            noServiceEle.classList.remove('hidden');
            const insuranceSystemError = document.getElementById('insurance-form-system-error');
            if (insuranceSystemError) {
                insuranceSystemError.classList.add('hidden');
            }
        }

    }

    public closeAddressBumperForm() {
        const findLocalBtn = document.querySelector(".find-local") as HTMLElement;
        findLocalBtn?.click();
    }
    openPropertyValidationModal(addressParam: string, suggestStr: any) {
        stopLoader();
        const callPopupModal = document.getElementById('address-modal')
        callPopupModal?.click()
        const enteredAddr = document.querySelector('label[for="entered-address-radio"]');
        const suggestAddr = document.querySelector('label[for="suggested-address-radio"]');
        const suggestJSON = JSON.parse(suggestStr);
        if (enteredAddr && suggestAddr) {
            enteredAddr.textContent = addressParam;
            suggestAddr.textContent = suggestJSON.address.label;
        }
        document.querySelector('.cta-wrapper .primary-btn')?.addEventListener("click", () => {
            let output: any;
            const suggestAddrInp = document.getElementById('suggested-address-radio') as HTMLInputElement;
            if (suggestAddrInp?.checked) {
                output = suggestAddr?.textContent;
                this.serviceAddress.value = output;
                sessionStorage.setItem("suggestion", "true");
                const suggestedAddress = getAddressDetails(suggestJSON.address);
                sessionStorage.setItem('suggestedAddress', JSON.stringify(suggestedAddress));
            }
            else {
                output = enteredAddr?.textContent;
            }
            sessionStorage.setItem('sAddressParam', output);
        });

    }
    throwErrorModal() {
        stopLoader();
        const errorMessage = `We're sorry. We don't currently provide service to ${this.zipCode.value}.`;
        document.getElementById('insurance-form-error-modal-id')?.click();
        document.getElementById('error-modal')?.classList.remove('hidden');
        const errorElement = document.getElementById('insurance-form-no-service') as HTMLInputElement | null;
        if (errorElement && errorElement.textContent === '') {
            errorElement.textContent = errorMessage;
        }
        document.getElementById('insurance-form-no-service')?.classList.remove('hidden');
    }
    handleFocusOut() {
        const zipCode = this.zipCode?.value.trim();
        DisclaimerServiceIdentifier(zipCode);
        toggleTermsPolicyBasedOnCountry('.insurance-form-main', zipCode);
    }
    handleCheckboxChange() {
        const forms: any = document.querySelector('.insurance-form-section');
        const serviceReminderDivs = forms?.querySelectorAll('.service-reminder-main');
        let errorID = null;
        // Check if any divs with the class 'service-reminder-main' are found
        if (!serviceReminderDivs || serviceReminderDivs.length === 0) {
            console.log('Servcie Disclaimer not authored in CP');
            return;
        }
        serviceReminderDivs.forEach((div: Element) => {
            // Check if the div is visible (does not have the 'hidden' class)
            if (div && !div.classList.contains('hidden')) {
                // Find the checkbox input inside this div
                const checkbox = div.querySelector('input[type="checkbox"]');
                // Find the corresponding error message element
                const errorMsg = div.querySelector('.error-msg');
                // Check if the checkbox and errorMsg exist and if the checkbox is required
                if (checkbox && errorMsg && checkbox instanceof HTMLInputElement && checkbox.required) {
                    // Add event listener to handle checkbox state change
                    checkbox.addEventListener('change', () => {
                        // If the checkbox is checked, hide the error message
                        if (checkbox.checked) {
                            errorMsg.classList.add('hidden');
                        } else {
                            // If the checkbox is unchecked, show the error message
                            errorMsg.classList.remove('hidden');
                            errorID = errorMsg?.id;
                        }
                    });
                    // Initial check: if the checkbox is required and unchecked when the page loads
                    if (checkbox.required && !checkbox.checked) {
                        errorMsg.classList.remove('hidden');
                        errorID = errorMsg?.id;
                    }
                }
            }
        });
        return errorID;
    }

    public optinEmailCheck(requestBody: any): any {
        try {
            const domain = DomainIdentifier();
            const localisation = LocalizationIdentifier();

            let checkboxId = '';

            const countryZip = !REGEX.sendCaPostalCode.test(this.zipCode?.value.trim()) ? 'us' : 'ca';
            if (((domain === "us" && !localisation) || (domain === "us" && localisation == "us")) && countryZip === 'us') {
                checkboxId = 'new-lead-service-reminder-us';
            } else {
                checkboxId = 'new-lead-service-reminder-ca';
            }
            const rewardForm: any = document.querySelector('.insurance-form-section');
            const noteWithRequestBody = disclaimerHandleCheck(false, rewardForm, requestBody, requestBody.zipCode, checkboxId);
            return noteWithRequestBody;

        } catch (error) {
            console.error('An error occurred in disclaimerHandleCheck in insurance form:', error);
            return requestBody;
        }
    }

    public addInputEventListeners() {
        this.addInputEventListener(this.firstName, REGEX.sendName, insuranceErrorMessage.firstNameErrorMsg);
        this.addInputEventListener(this.lastName, REGEX.sendName, insuranceErrorMessage.lastNameErrorMsg);
        this.addInputEventListener(this.zipCode, REGEX.sendZip, insuranceErrorMessage.zipCodeErrorMsg, this.handleFocusOut.bind(this));
        this.addInputEventListener(this.serviceAddress, REGEX.sendAddress1, insuranceErrorMessage.serviceAddress, this.setAutoSuggested.bind(this));
        this.addInputEventListener(this.serviceAddress2, REGEX.sendAddress2, insuranceErrorMessage.serviceAddress);
        this.addInputEventListener(this.insuranceCompany, REGEX.matchAny, insuranceErrorMessage.insuranceCompanyNameErrorMsg);
        this.addInputEventListener(this.insurancePolicyNumber, REGEX.alphanumeric, insuranceErrorMessage.insurancePolicyNumberErrorMsg, undefined, "alphanumeric");
        this.addInputEventListener(this.phoneNumber, REGEX.mobileNumberRegex, errorMessage.phone, handleMobileInput.bind(this));
        this.addInputEventListener(this.email, REGEX.sendEmail, insuranceErrorMessage.emailErrorMsg);
        this.addInputEventListener(this.insuranceCompanyPhoneNumber, REGEX.mobileNumberRegex, errorMessage.phone, handleMobileInput.bind(this));
        this.addInputEventListener(this.insuranceCauseLoss, REGEX.matchAny, insuranceErrorMessage.insuranceCauseLossErrorMsg);
        this.addInputEventListener(this.insuranceClaim, REGEX.sendNumber, insuranceErrorMessage.insuranceClaimNumberErrorMsg, undefined, 'number');
        this.addInputEventListener(this.insuranceDeductible, REGEX.floatDigit, insuranceErrorMessage.insuranceDeductibleErrorMsg, undefined, 'float');
        this.addInputEventListener(this.insuranceDateOfLoss, REGEX.dateMMDDYYYY, insuranceErrorMessage.insuranceDateOfLossErrorMsg, this.handleDateInput.bind(this));
        this.addInputEventListener(this.carMake, REGEX.matchAny, insuranceErrorMessage.insuranceCarMakenErrorMsg);
        this.addInputEventListener(this.carModel, REGEX.matchAny, insuranceErrorMessage.insuranceCarModelErrorMsg);
   
        this.addInputEventListener(this.vinMakehInput, REGEX.matchAny, insuranceErrorMessage.insuranceCarMakenErrorMsg);
        this.addInputEventListener(this.vinModelInput, REGEX.matchAny, insuranceErrorMessage.insuranceCarModelErrorMsg);
        this.addInputEventListener(this.vinStyleInput, REGEX.matchAny, insuranceErrorMessage.insuranceCarSyleErrorMsg);
        this.carYear?.addEventListener('input', (e) => this.handleYearCheck(e, 'car-year-error-msg' ,insuranceErrorMessage.insuranceCarYearErrorMsg));
        this.vinYearInput?.addEventListener('input', (e) => this.handleYearCheck(e, 'vin-year-error-msg' ,insuranceErrorMessage.insuranceCarYearErrorMsg));
        this.addInputEventListener(this.carStyle, REGEX.matchAny, insuranceErrorMessage.insuranceCarSyleErrorMsg);
        this.addInputEventListener(this.insuranceAgentName, REGEX.sendName, insuranceErrorMessage.insuranceAgentNameErrorMsg);
        this.addInputEventListener(this.insuranceAgencyName, REGEX.sendName, insuranceErrorMessage.insuranceAgencyNameErrorMsg);
        this.addInputEventListener(this.insuranceAgencyZip, REGEX.sendZip, insuranceErrorMessage.zipCodeErrorMsg);
        this.addInputEventListener(this.insuranceAgencyAddress, REGEX.sendAddress1, insuranceErrorMessage.serviceAddress);
        this.addInputEventListener(this.insuranceAgencyEmail, REGEX.sendEmail, insuranceErrorMessage.emailErrorMsg);
        this.addInputEventListener(this.insuranceAgencyPhone, REGEX.mobileNumberRegex, errorMessage.phone, handleMobileInput.bind(this));
        this.addInputEventListener(this.insuranceComments, REGEX.matchAny, insuranceErrorMessage.insuranceCommentsErrorMsg);

        this.insuranceFormService?.forEach((checkbox) => {
            checkbox.addEventListener('change', this.handleCheckboxChange.bind(this));
        });
    }

    private appendScannerFullscreen(): void {
        const vehicleTabContent = document.getElementById('vehicle-tab-content');
        if (vehicleTabContent) {
            const scannerFullscreenDiv = document.createElement('div');
            scannerFullscreenDiv.className = 'scanner-fullscreen';
            scannerFullscreenDiv.style.position = 'fixed';
            scannerFullscreenDiv.style.top = '0';
            scannerFullscreenDiv.style.left = '0';
            scannerFullscreenDiv.style.width = '100%';
            scannerFullscreenDiv.style.height = '100%';
            scannerFullscreenDiv.style.background = 'rgba(0, 0, 0, 0.8)';
            scannerFullscreenDiv.style.display = 'flex';
            scannerFullscreenDiv.style.justifyContent = 'center';
            scannerFullscreenDiv.style.alignItems = 'center';
            scannerFullscreenDiv.style.zIndex = '9999';
            scannerFullscreenDiv.style.overflow = 'hidden';

            scannerFullscreenDiv.innerHTML = `
            <svg class="vin-video-close-btn" focusable="false" aria-hidden="true" viewBox="0 0 24 24" 
                style="position: absolute; top: 20px; left: 20px; z-index: 10; font-size: 2rem; 
                color: white; cursor: pointer; user-select: none; display: inline-block; 
                fill: currentcolor; flex-shrink: 0; width: 1.5rem; height: 1.5rem; 
                transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1)">
                <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
            </svg>
            `;

            scannerFullscreenDiv.querySelector('.vin-video-close-btn')?.addEventListener('click', this.stopScanning.bind(this));

            const innerDiv = document.createElement('div');
            const videoElement:any = document.createElement('video');
            videoElement.style.width = '100%';
            videoElement.style.height = '100%';
            videoElement.autoplay = true;
            videoElement.style.border = '1px solid black';

            innerDiv.appendChild(videoElement);
            scannerFullscreenDiv.appendChild(innerDiv);
            vehicleTabContent.appendChild(scannerFullscreenDiv);

            this.videoElement = videoElement as HTMLVideoElement;
        }
    }

    private addInputEventListener(element: HTMLInputElement | HTMLTextAreaElement | undefined, regex: RegExp, errorMessage: string, additionalHandler?: (e: Event) => void, validationType?: string) {
        if (element) {
            element.addEventListener('input', (e) => this.handleCommonInputErrorForm1(e, element, `${element.id}-error-msg`, regex, errorMessage, validationType));
            if (additionalHandler) {
                element.addEventListener('input', additionalHandler);
            }
        }
    }

    public handleCommonInputError(e: any, inputElement: any, errorId: any, validationRegex: any, errorMessage: any, validationType?: string): void {
        const insuranceInputElement = e.target;
        e.preventDefault();
        const getErrMsg = (document.getElementById(errorId) as HTMLElement)?.getAttribute('aria-describedby');
        const fieldError = document.getElementById(errorId) as HTMLElement;

        if (insuranceInputElement.value !== '' && !validationRegex.test(insuranceInputElement?.value?.trim())) {
            if (fieldError) {
                fieldError.classList.remove('hidden');
                fieldError.innerHTML = errorMessage ?? getErrMsg ?? '';
            }
        } else if (insuranceInputElement.value) {
            inputElement.classList.remove("invalid-field");
            if (fieldError) {
                fieldError.classList.add('hidden');
            }
        }
    }
    public handleCommonInputErrorForm1(e: any, inputElement: any, errorId: any, validationRegex: any, errorMessage: any, validationType?: string): void {

        const getErrMsg = (document.getElementById(errorId) as HTMLElement)?.getAttribute('aria-describedby');
        const fieldError = document.getElementById(errorId) as HTMLElement;

        if (inputElement.value !== '' && !validationRegex.test(inputElement?.value?.trim())) {
            if (fieldError) {
                fieldError.classList.remove('hidden');
                fieldError.innerHTML = errorMessage ?? (getErrMsg as string);
            }
        } else {
            inputElement.classList.remove("invalid-field");
            if (fieldError) {
                fieldError.classList.add('hidden');
            }
        }
    }


    private handleDateInput(e: Event): void {
        const inputElement = e.target as HTMLInputElement;
        let input = inputElement.value;

        if (/\D\/$/.test(input)) input = input?.slice(0, -3);
        let values = input.split('/')?.map(function (v: any) {
            return v.replace(/\D/g, '')
        });
        if (values[0]) values[0] = this.checkValue(values[0], 12);
        if (values[1]) values[1] = this.checkValue(values[1], 31);
        let output = values.map(function (v: any, i: any) {
            return v.length == 2 && i < 2 ? v + ' / ' : v;
        });
        inputElement.value = output?.join('')?.slice(0, 14);

        // Validate the date format
        const errorElement = document.getElementById('insurance-date-loss-error-msg') as HTMLElement;
        if (REGEX.dateMMDDYYYY.test(inputElement.value) && !this.isFutureDate(inputElement.value)) {
            inputElement?.classList.remove('invalid-field');
            if (errorElement) {
                errorElement.classList.add('hidden');
            }
        } else {
            inputElement?.classList.add('invalid-field');
            if (errorElement) {
                errorElement.classList.remove('hidden');
                errorElement.textContent = "Please enter a valid date in MM/DD/YYYY format.";
            }
        }
    }

    private isFutureDate(dateString: string): boolean {
        const [month, day, year] = dateString.split('/').map(Number);
        const inputDate = new Date(year, month - 1, day);
        const currentDate = new Date();
        return inputDate > currentDate;
    }

    private checkValue(value: string, max: number): string {
        if (!value.startsWith('0') || value === '00') {
            let num = parseInt(value);
            if (isNaN(num) || num <= 0 || num > max) num = 1;
            value = num > parseInt(max.toString().charAt(0)) && num.toString().length === 1 ? '0' + num : num.toString();
        }
        return value;
    }

    public validateFields(): boolean {
        try {
            document.querySelectorAll('.invalid-field')?.forEach((e: any) => e.classList.remove('invalid-field'));
            const errors: any = {};

            this.validateField(this.firstName, REGEX.sendName, insuranceErrorMessage.firstNameErrorMsg, errors);
            this.validateField(this.lastName, REGEX.sendName, insuranceErrorMessage.lastNameErrorMsg, errors);
            this.validateField(this.zipCode, REGEX.sendZip, insuranceErrorMessage.zipCodeErrorMsg, errors);
            this.validateField(this.email, REGEX.sendEmail, insuranceErrorMessage.emailErrorMsg, errors);
            this.validateField(this.serviceAddress, REGEX.sendAddress1, insuranceErrorMessage.serviceAddress, errors);
            this.validateField(this.insuranceCompany, REGEX.matchAny, insuranceErrorMessage.insuranceCompanyNameErrorMsg, errors);
            this.validateField(this.phoneNumber, REGEX.mobileNumberRegex, errorMessage.phone, errors);
            this.validateField(this.insuranceCompanyPhoneNumber, REGEX.mobileNumberRegex, errorMessage.phone, errors);
            this.validateField(this.insurancePolicyNumber, REGEX.alphanumeric, insuranceErrorMessage.insurancePolicyNumberErrorMsg, errors);
            this.validateField(this.insuranceCauseLoss, REGEX.matchAny, insuranceErrorMessage.insuranceCauseLossErrorMsg, errors);
            this.validateField(this.insuranceClaim, REGEX.sendNumber, insuranceErrorMessage.insuranceClaimNumberErrorMsg, errors);
            this.validateField(this.insuranceDeductible, REGEX.floatDigit, insuranceErrorMessage.insuranceDeductibleErrorMsg, errors);
            this.validateField(this.insuranceDateOfLoss, REGEX.dateMMDDYYYY, insuranceErrorMessage.insuranceDateOfLossErrorMsg, errors);

            // Validate vehicle details
            const vehicleTab = this.vehicleTab?.querySelector('[aria-selected="true"]');

            if(vehicleTab?.id === 'vin-tab'){
                this.validateField(this.vinYearInput, REGEX.sendYear, errorMessage.year, errors);
                this.validateField(this.vinMakehInput, REGEX.matchAny, errorMessage.make, errors);
                this.validateField(this.vinModelInput, REGEX.matchAny, errorMessage.carModel, errors);
                this.validateField(this.vinStyleInput, REGEX.matchAny, errorMessage.carModel, errors);
            }else{
                this.validateField(this.carYear, REGEX.sendYear, errorMessage.year, errors);
                this.validateField(this.carMake, REGEX.matchAny, errorMessage.make, errors);
                this.validateField(this.carModel, REGEX.matchAny, errorMessage.carModel, errors);
                this.validateField(this.carStyle, REGEX.matchAny, errorMessage.carModel, errors);
            }

            this.validateField(this.insuranceAgentName, REGEX.sendName, insuranceErrorMessage.insuranceCompanyNameErrorMsg, errors);
            this.validateField(this.insuranceAgencyName, REGEX.sendName, insuranceErrorMessage.insuranceCompanyNameErrorMsg, errors);
            this.validateField(this.insuranceAgencyZip, REGEX.sendZip, insuranceErrorMessage.zipCodeErrorMsg, errors);
            this.validateField(this.insuranceAgencyAddress, REGEX.sendAddress1, insuranceErrorMessage.serviceAddress, errors);
            this.validateField(this.insuranceAgencyEmail, REGEX.sendEmail, insuranceErrorMessage.emailErrorMsg, errors);
            this.validateField(this.insuranceAgencyPhone, REGEX.mobileNumberRegex, errorMessage.phone, errors);
            this.validateField(this.insuranceComments, REGEX.matchAny, insuranceErrorMessage.insuranceCompanyNameErrorMsg, errors);
            
            const checkBoxItems = document.querySelector<HTMLInputElement>('.insurance-form-section .service-requested-insurance');
            const checkBoxList = document.querySelectorAll<HTMLInputElement>('.insurance-form-section .service-requested-insurance input[type="checkbox"]')

            if(checkBoxItems?.getAttribute('required') !==null && checkBoxList?.length > 0) {
             const serviceRequestedArr = this.serviceRequested().length > 0 ? this.serviceRequested() : [];
             const errorMessageHtml = document.getElementById('glass-type-error-msg') as HTMLElement;
                if(serviceRequestedArr.length === 0){
                        errorMessageHtml?.classList.remove('hidden');
                        errors['glass-type'] = '';
                }else{
                    errorMessageHtml?.classList.add('hidden');
                }
            }

            const errorIdChekboxSpan = this.handleCheckboxChange();
            if (errorIdChekboxSpan) {
                errors[errorIdChekboxSpan] = '';
            }

            this.displayErrors(errors);

            return Object.keys(errors).length === 0;
        } catch (error) {
            console.error('Error validating fields:', error);
            return false;
        }
    }

    private validateField(field: any, regex: RegExp, errorMessage: string, errors: any): void {
        if (field?.value === '' && field?.required) {
            errors[field?.id] = '';
        } else if (field?.value !== '' && !regex.test(field?.value)) {
            errors[field?.id] = errorMessage;
        } else if (field?.value && (field?.id === 'insurance-company-phone') && field?.value.length < 14) {
            errors[field?.id] = errorMessage;
        } else if(field?.id ==='insurance-date-loss' &&  REGEX.dateMMDDYYYY.test(field?.value) && this.isFutureDate(field?.value)){
            errors[field?.id] = errorMessage;
        }   else if (field?.id === 'vin-year' || field?.id === 'carYear') {
            // Year regex to match 4-digit numbers from 1980 to 2099
            const yearRegex = REGEX.sendYear;
            const year = parseInt(field?.value, 10);
            const currentYear = new Date().getFullYear();
            // Check if the input year is valid (regex check)
            if (!yearRegex.test(field?.value) || year > currentYear) {
                errors[field?.id] = errorMessage;
            }
        }
    }

    private displayErrors(errors: any): void {
        Object.keys(errors).forEach((fieldId, index) => {
            const field: any = document.getElementById(`${fieldId}`);
            const fieldError: any = document.getElementById(`${fieldId}-error-msg`);
            if (field?.parentNode) {
                const errorMessage = errors[fieldId];
                const errorElement = document.createElement('span');
                errorElement.className = 'error-msg';
                errorElement.id = `${fieldId}-error-msg`;
                errorElement.textContent = errorMessage;
                field.classList.add("invalid-field");
                if (fieldError?.classList.contains('hidden')) {
                    fieldError.classList.remove('hidden');
                }
            }
            if (index === 0 && field) {
                field.focus();
            }
        });
    }

    private serviceRequested() {
        const serviceRequestArr:any = [];
        const checkBoxItems = document.querySelectorAll<HTMLInputElement>('.insurance-form-section .service-requested-insurance input[type="checkbox"]');

        checkBoxItems.forEach((item) => {
            if (item?.checked) {
                const checkBoxLabel:string = item.nextElementSibling?.textContent ?? '';
                if(checkBoxLabel){
                    serviceRequestArr.push(checkBoxLabel);
                }
            }
        });

        return serviceRequestArr;
    }

    private getCarYearMakeModelStyle(){

        let carYear = this.carYear?.value ?  Number(this.carYear?.value) : 0;
        let carMake = this.carMake?.value ?? '';
        let carModel = this.carModel?.value ?? '';
        let carStyle = this.carStyle?.value ?? '';

        const vehicleTab = this.vehicleTab?.querySelector('[aria-selected="true"]');

        if(vehicleTab?.id === 'vin-tab'){

            if(this.vinMakehInput?.value) {
                carYear = this.vinYearInput?.value ? Number(this.vinYearInput?.value) : 0;
            }

            if(this.vinMakehInput?.value) {
                carMake = this.vinMakehInput?.value;
            }

            if(this.vinModelInput?.value) {
                carModel = this.vinModelInput?.value;
            }

            if(this.vinStyleInput?.value) {
                carStyle = this.vinStyleInput?.value;
            }
        }

        return {carYear, carMake, carModel, carStyle};
    }

    private handleYearCheck(e: any, errorId: string, errorMessage?: string) {
        const eleInput = e.target as HTMLInputElement;
        const inputVal = eleInput?.value;
        
        // Get the current year
        const currentYear = new Date().getFullYear();
        // Regex to check for 4-digit years between 1980 and 2099
        const regex = REGEX.sendYear;
    
        // Get the error message from the aria-describedby attribute
        const getErrMsg = (document.getElementById(errorId) as HTMLElement)?.getAttribute('aria-describedby') ?? '';
        const fieldError = document.getElementById(errorId) as HTMLElement;
    
        // Check if the input matches the regex (valid 4-digit year from 1980 to 2099)
        if (inputVal !== '' && !regex.test(inputVal)) {
            // If field is invalid, show error
            if (fieldError) {
                fieldError.classList.remove('hidden');
                fieldError.innerHTML = errorMessage ?? getErrMsg ?? '';
            }
            eleInput?.classList.add("invalid-field");
            return false; // Input does not match the valid year pattern
        }
    
        // Convert the input to a number and compare with the current year
        const year = parseInt(inputVal, 10);
        if (year > currentYear) {
            // If year is in the future, show error
            if (fieldError) {
                fieldError.classList.remove('hidden');
                fieldError.innerHTML = errorMessage ?? getErrMsg;
            }
            eleInput?.classList.add("invalid-field");
            return false; // Year is in the future
        }
    
        // If valid, remove error display and add valid styling
        eleInput?.classList.remove("invalid-field");
        if (fieldError) {
            fieldError.classList.add('hidden');
        }
        return true; // Year is valid
    }
    

}

document.addEventListener('DOMContentLoaded', () => {
if(document.querySelector('.insurance-form-section')){
    // Initialize the InsuranceForm class
    InsuranceForm.getInstance();
}
});