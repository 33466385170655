export class DripCalculator {
    constructor() {
        const DripCalculatorDom = document.querySelector(".drip-form-wrap") as HTMLElement;
        if (DripCalculatorDom)
            this.init();
    }

    public init(): void {
        this.loadFunction();
    }

    calculateValue(elementId: string, calculation: (value: number) => number): void {
        const element = document.getElementById(elementId) as HTMLInputElement;
        let value: string | number = element.value;

        // Input validation: accept only whole integers
        if (!Number.isInteger(Number(value)) || String(value).trim() === "" || Number(value) === 0) {
            element.value = " ";
            value = 0;
        } else {
            value = calculation(Number(value)).toFixed(2);
        }
        let inputElement = document.getElementById(elementId) as HTMLElement;
        let timeCountDiv = inputElement.closest(".time-count");
        if (timeCountDiv) {
            let dropCountSpan = timeCountDiv.querySelector(".drop-count") as HTMLElement;
            dropCountSpan.innerText = value.toString(); // Update span text with value
        }
    }

    loadFunction(): void {
        const dripsElement = document.getElementById('drip-per-minute') as HTMLInputElement;
        const timeElement = document.getElementById('time-in-sec') as HTMLInputElement;

        // Prevent non-digit input
        const preventNonDigits = (event: KeyboardEvent): void => {
            if (!/\d/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete') {
                event.preventDefault();
            }
        };

        const updateOnDeleteOrBackspaceForDrip = (event: KeyboardEvent): void => {
            if (event.key === 'Backspace' || event.key === 'Delete') {
                const target = event.target as HTMLInputElement;
                if (Number.isInteger(Number(target.value))) {
                    this.calculateValue(target.id, value => value * 1440 / 1e4);
                }
            }
        };
        const updateOnDeleteOrBackspaceForTime = (event: KeyboardEvent): void => {
            if (event.key === 'Backspace' || event.key === 'Delete') {
                const target = event.target as HTMLInputElement;
                if (Number.isInteger(Number(target.value))) {
                    this.calculateValue(target.id, value => 5400 / value);
                }
            }
        };

        dripsElement.onkeydown = preventNonDigits;
        timeElement.onkeydown = preventNonDigits;

        dripsElement.onkeyup = updateOnDeleteOrBackspaceForDrip;
        timeElement.onkeyup = updateOnDeleteOrBackspaceForTime;

        dripsElement.oninput = (event): void => {
            const target = event.target as HTMLInputElement;
            if (Number.isInteger(Number(target.value))) {
                this.calculateValue('drip-per-minute', value => value * 1440 / 1e4);
            }
        }

        timeElement.oninput = (event): void => {
            const target = event.target as HTMLInputElement;
            if (Number.isInteger(Number(target.value))) {
                this.calculateValue('time-in-sec', value => 5400 / value);
            }
        }
    }
}
new DripCalculator();
