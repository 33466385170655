export function setServices(footerList: any) {



    const serviceSection = document.querySelector('#fblinkblock-services > .footer-submenu')

    while (serviceSection?.firstChild) {
        serviceSection.removeChild(serviceSection.firstChild);
    }

    if (footerList.length == 0 && document.getElementById('fblinkblock-services')) {
        document.getElementById('fblinkblock-services')!.style.display = 'none'

    }else {
        const listItem = footerList.map((item: any) => {
            return `<li class="submenu-li">
            <a href=${item.link} class="submenu-a">${item.name}</a>
             </li>`;
        });
    
        serviceSection?.insertAdjacentHTML('beforeend', listItem.join(''));
    }
}

