export function useMemo<T>(functionRef: (...args: any[]) => T, args: any[] = []): T {
    // Create a unique key based on the function and arguments
    const keyName = functionRef.name + JSON.stringify(args);

    // Check if result is already memoized
    const memoItem = localStorage.getItem(keyName);
    if (memoItem) {
        try {
            return JSON.parse(memoItem);
        } catch (e) {
            // Handle parsing errors and recalculate result
            console.warn('Failed to parse memoized result, recalculating...');
        }
    }

    // Execute the function and store the result
    const result = functionRef(...args);
    localStorage.setItem(keyName, JSON.stringify(result));
    return result;
}
