export function blogPageLocationURL(dbaName: string):string {
    const brandDetails = localStorage.getItem("brandDetails");
    if(brandDetails!=="undefined" && brandDetails !==null){
        const data = JSON.parse(brandDetails);
        const blogUseFlag = data?.weblocationUrlPath_blog
        if(blogUseFlag)
            return `${data.weblocation_path_group}/${dbaName}`;
        else
            return `${dbaName}`;
    }
    console.error("Error while setting a localized blog URL")
    return "error";
}