import { LocationSearchMapCpkConfig } from "./models/LocationSearchMapCpkConfig";

export const getLocationSearchMapCpkConfig = (): LocationSearchMapCpkConfig => { 
    const showPhone = (document.getElementById('show-phone') as HTMLInputElement)?.value;
    const phoneLabel = (document.getElementById('phone-label') as HTMLInputElement)?.value;
    const phoneViewType = (document.getElementById('phone-view-type') as HTMLInputElement)?.value;
    const showViewWeb = (document.getElementById('show-view-website') as HTMLInputElement)?.value;
    const viewWebsiteLabel = (document.getElementById('view-website-label') as HTMLInputElement)?.value;
    const viewWebViewType = (document.getElementById('view-website-view-type') as HTMLInputElement)?.value;
    const showReqEstimate = (document.getElementById('show-request-estimate') as HTMLInputElement)?.value;
    const requestEstimateLabel = (document.getElementById('request-estimate-label') as HTMLInputElement)?.value;
    const reqEstimateViewType = (document.getElementById('request-estimate-view-type') as HTMLInputElement)?.value;
    const noLosAvailableMsg = (document.getElementById('no-locs-available-msg') as HTMLInputElement);  
    const noLosAvailableMsgString = noLosAvailableMsg?.textContent?.trim() || '';
    return {

        showPhone: showPhone.toString().trim() === 'yes' ? true : false,
        phoneText: phoneLabel.toString().trim(),
        phoneViewType: phoneViewType.toString().trim(),

        showViewWebsite: showViewWeb.toString().trim() === 'yes' ? true : false,
        viewWebsiteText: viewWebsiteLabel.toString().trim(),
        viewWebsiteViewType: viewWebViewType.toString().trim(),

        showRequestEstimate: showReqEstimate.toString().trim() === 'yes' ? true : false,
        requestEstimateText: requestEstimateLabel.toString().trim(),
        requestEstimateViewType: reqEstimateViewType.toString().trim(),

        
        noLocationsAvailable: noLosAvailableMsgString.toString()
    }
}