export class GiftCertificateImage {
    constructor() {
        this.init();
    }

    private init() {
        this.initImagePrint();
        const printBtn = document.getElementById('gift-certificate-print-button');
        if (printBtn) {
            printBtn.addEventListener('click', this.printImage);
        }
    }

    private initImagePrint() {
        const id = this.extractQueryStringValue('id');
        const s = this.extractQueryStringValue('s');
        const printStatus = this.extractQueryStringValue('print');
        const imgSrc = process.env.JS_CORE_API_BASE_URL + `/GiftCertificates/Render/${id}-${s}`;

        const imgElement = document.querySelector('.gc-img') as HTMLImageElement;
        if (imgElement) {
            imgElement.src = imgSrc;
        }

        if (printStatus === 'true') {
            this.printImage();
        }
    }

    public printImage(): void {
        console.log('Called gift Certificate Image print');
        let imgSrc = (document.querySelector('.gc-img') as HTMLImageElement).src;
        let printFrame = document.getElementById('gift-certificate-print-frame') as HTMLIFrameElement;

        // Set onload event before writing to the frame's document
        if (printFrame) {
            printFrame.onload = function () {
                if (printFrame?.contentWindow) {
                    printFrame.contentWindow.print();
                } else {
                    console.error('printFrame or printFrame.contentWindow is null');
                }
            };

            if (printFrame?.contentWindow) {
                let printDocument = printFrame?.contentWindow.document;
                let htmlContent = `
                        <!DOCTYPE html>
                        <html>
                        <head>
                            <title>Print Image</title>
                            <style>
                                @media print {
                                    @page {
                                        margin: 0;
                                    }
                                    body {
                                        margin: 0;
                                    }
                                }
                            </style>
                        </head>
                        <body>
                            <img src="${imgSrc}" style="width:100%;">
                        </body>
                        </html>`;

                printDocument.open();
                printDocument.write(htmlContent);
                printDocument.close();
            }
        }
    }

    private extractQueryStringValue(parameterName: any) {
        const urlString = window.location.href;
        const url = new URL(urlString);
        const params = new URLSearchParams(url.search);
        return params.get(parameterName);
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const conceptId: any = (document.getElementById('conceptId') as HTMLInputElement)?.value;
    const currentUrl = window.location.href;
    const isGiftCertificationImg = currentUrl.includes("gift-certificate-image")
    if (conceptId == 1 && isGiftCertificationImg) {
        new GiftCertificateImage();
    }
})