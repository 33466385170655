import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiCaching } from "../config/cachingConfig";
import { getPageInfo } from "../util/add-Page-Type-DataLayer";

/*Special offers modal to be shown when user is idle for 10 seconds*/
document.addEventListener('DOMContentLoaded', async function () {
    const timeoutDuration = 10;
    let idleTime = 0;
    let idleInterval: any;
    const pageType = getPageInfo();
    const request = {
        method: 'GET',
        url: `${window.location.origin}/special-offers.json`
    };

    async function checkUrlStatus(url: string) {
        try {
            const response = await fetch(url);
            // Check if the response status is 404
            if (response.status === 404) {
                return false; // Indicates 404 status
            }
            // If not 404, return true
            return true;
        } catch (error) {
            console.error("Error checking URL:", error);
            return false;
        }
    }

    const urlExists = await checkUrlStatus(request.url);
    if (urlExists) {
        try {

            const offerDetails = await getCachedAPIResponse(request.url, apiCaching.OffersPopup); // Fetch data only if URL is valid
            const result: any = offerDetails;
            if (result && (Array.isArray(result) ? result.length > 0 : Object.keys(result).length > 0)) {
                // Check if the pageType is 'Homepage' and has active offers-json is not empty
                if (pageType === 'Homepage') {
                    if (!sessionStorage.getItem('splOfferModalShown')) {
                        initIdleDetection();
                    }
                }
            }
        }
        catch (err: any) {
            if (err.response?.status !== 404) {
                console.error('Error fetching data:', err);
            }
        }
    }

    function initIdleDetection() {
        idleTime = 0;
        idleInterval = setInterval(timerIncrement, 1000);

        // Event listeners for user activity
        document.addEventListener('mousemove', resetIdleTime);
        document.addEventListener('keypress', resetIdleTime);
        document.addEventListener('click', resetIdleTime);
        document.addEventListener('scroll', resetIdleTime);
    }

    function timerIncrement() {
        idleTime++;
        if (idleTime > timeoutDuration) {
            showModal();
            clearInterval(idleInterval);
        }
    }

    function resetIdleTime() {
        idleTime = 0;
    }

    function showModal() {
        const modal = document.getElementById('sp-offer-modal');
        if (modal) {
            modal.classList.remove('hidden');
            modal.classList.add('flex');
            modal.style.backgroundColor = "#00000066";
            sessionStorage.setItem('splOfferModalShown', 'true');
        }
        const closeModalBtn = document.querySelector('.sp-offer-modal .close-modal-btn');
        if (closeModalBtn) {
            closeModalBtn.addEventListener('click', () => {
                if (modal) {
                    modal.classList.add('hidden');
                    modal.classList.remove('flex');
                    modal.style.backgroundColor = "";
                }
            });
        }
    }
});

/*End*/