import { locationSearchMapConfig } from './config';
import { ScreenType } from './models/ScreenType';
export const reportScreenSize = (): ScreenType => {
    /*
    *   screen width:
    *   desktop view: >= 992px
    *   mobile view: < 992
    */   
    const screenWidth = window.innerWidth;
    //console.log(`windowResized [width:${screenWidth}]`);
    if(screenWidth >= locationSearchMapConfig.mobileViewWidth) 
    {
        //console.log('desktop view');
        return ScreenType.Desktop;
    }    
    else 
    {
        //console.log('mobile view');
        return ScreenType.Mobile;
    }
}