export function handleInputError(inputElement:any, errorId:any, validationRegex:any, errorMessage:any) {
    const getErrMsg = (document.getElementById(errorId) as HTMLElement)?.getAttribute('aria-describedby');
    const fieldError = document.getElementById(errorId) as HTMLElement;
  
    if (inputElement.value !== '' && !validationRegex.test(inputElement.value)) {
      if (fieldError) {
        fieldError.classList.remove('hidden');
        fieldError.innerHTML = getErrMsg ?? errorMessage;
      } else if (inputElement.parentNode) {
          const errorElement = document.createElement('span');
          errorElement.className = 'error-msg';
          errorElement.id = `${inputElement.id}-error-msg`;
          errorElement.textContent = getErrMsg ?? errorMessage;
          inputElement.classList.add("invalid-field");
          inputElement.parentNode.appendChild(errorElement);
      }
    } else {
      inputElement.classList.remove("invalid-field");
      if (fieldError) {
        fieldError.classList.add('hidden');
      }
    }
  }

  export function handleCommonInputError(inputElement:any, errorId:any, validationRegex:any, errorMessage:any) {
    const getErrMsg = (document.getElementById(errorId) as HTMLElement)?.getAttribute('aria-describedby');
    const fieldError = document.getElementById(errorId) as HTMLElement;
  
    if (inputElement.value !== '' && !validationRegex.test(inputElement.value)) {
      if (fieldError) {
        fieldError.classList.remove('hidden');
        fieldError.innerHTML = getErrMsg ?? errorMessage;
      } else if (inputElement.parentNode) {
          const errorElement = document.createElement('span');
          errorElement.className = 'error-msg';
          errorElement.id = `${inputElement.id}-modal-error-msg`;
          errorElement.textContent = getErrMsg ?? errorMessage;
          inputElement.classList.add("invalid-field");
          inputElement.parentNode.appendChild(errorElement);
      }
    } else {
      inputElement.classList.remove("invalid-field");
      if (fieldError) {
        fieldError.classList.add('hidden');
      }
    }
  }