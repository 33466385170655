import { formatPhone } from "./formatPhone";
import { FranchiseDetail } from "./models/FranchiseDetail";
import { LocationSearchMapCpkConfig } from "./models/LocationSearchMapCpkConfig";

export const printLocationBars = (franchiseDetails: FranchiseDetail[], cpkConfig: LocationSearchMapCpkConfig, pinnedWeblocationId: number = -1,brandData:any): string => {
    let locationBars: string[] = [];
    if(franchiseDetails?.length>0){
        for (let i = 0; i < franchiseDetails.length; i++) {
            const webLocation = franchiseDetails[i];
            let locationBar = '';
            if(pinnedWeblocationId === webLocation.franchiseWebLocationId) 
            {
                locationBar = printPinnedLocationBar(webLocation, cpkConfig,brandData);
                locationBars.unshift(locationBar); // add it to beginning 
            }
            else 
            {
                locationBar = printLocationBar(webLocation, cpkConfig,brandData);
                locationBars.push(locationBar);
            }
        }
    }
    return locationBars.join('\n');
}
export const printLocationBar = (franchiseDetails: FranchiseDetail, cpkConfig: LocationSearchMapCpkConfig,brandData:any): string => {
    const addrLine1 = (franchiseDetails.showAddress && franchiseDetails.address1 && franchiseDetails.address1.length > 0) ? `<span>${franchiseDetails.address1}</span><br>` : '';
    const addrLine2 = (franchiseDetails.showAddress && franchiseDetails.address2 && franchiseDetails.address2.length > 0) ? `<span>${franchiseDetails.address2}</span><br>` : '';
    
    const addrLine3 = `<p class="location-main">${(franchiseDetails.city && franchiseDetails.city.length > 0) ? franchiseDetails.city + ', ' : ''}
        ${(franchiseDetails.state && franchiseDetails.state.length > 0) ? franchiseDetails.state + ', ' : ''}
        ${(franchiseDetails.country && franchiseDetails.country.length > 0) ? franchiseDetails.country + ', ' : ''}
        ${(franchiseDetails.zipCode && franchiseDetails.zipCode.length > 0) ? franchiseDetails.zipCode : ''}
    </p>`;
    var websiteTrackingNumber:any = franchiseDetails.websiteTrackingPhone[0] ? franchiseDetails.websiteTrackingPhone[0] : franchiseDetails.phoneNumber;
    return `
    <li class="location-item">
        <div class="location-data">
        <div class="location-heading"><a href="${franchiseDetails.websiteUrl ? franchiseDetails.websiteUrl : "#"}" target="_blank" class="primary-link">${franchiseDetails.doingBusinessAs}</a></div>           
            ${addrLine1}
            ${addrLine2}
            ${addrLine3}
            <div class="location-links">
            ${cpkConfig.showPhone? printPhoneButtonLink(websiteTrackingNumber, cpkConfig.phoneViewType, cpkConfig.phoneText):""}
            </div>            
        </div>
        <div class="location-cta">
        ${cpkConfig.showRequestEstimate? printReqButtonLink("#", cpkConfig.requestEstimateViewType, cpkConfig.requestEstimateText,brandData): ""}
        </div>
        <input type="hidden" class="weblocation-id" value="${franchiseDetails.franchiseWebLocationId}">
    </li>`;
}

const printReqButtonLink = (url: string, viewType: string, label: string,brandData:any):string => {
    // return `<a href="${url ? url : "#"}" target="_blank" class="primary-btn">${label}</a>`;
    let reqHref = `https://${window.location.hostname}${brandData.request_button_link_type ? brandData.request_button_link_type : '/schedule-appointment'}`;

    return `<a href=${reqHref} class="primary-btn" id="requestEstimate">${label}</a>`;

 
}


const printPhoneButtonLink = (phone: string, viewType: string, label: string): string => {
    return `<a href="tel:${phone}" class="primary-link">${label}: ${phone?.includes('(') ? phone:formatPhone(phone)}</a>`;
}

export const printPinnedLocationBar = (franchiseDetails: FranchiseDetail, cpkConfig: LocationSearchMapCpkConfig,brandData:any): string => {    
    const addrLine1 = (franchiseDetails.showAddress && franchiseDetails.address1 && franchiseDetails.address1.length > 0) ? `<span>${franchiseDetails.address1}</span><br>` : '';
    const addrLine2 = (franchiseDetails.showAddress && franchiseDetails.address2 && franchiseDetails.address2.length > 0) ? `<span>${franchiseDetails.address2}</span><br>` : '';
    const addrLine3 = `<span>${(franchiseDetails.city && franchiseDetails.city.length > 0) ? franchiseDetails.city + ', ' : ''}
        ${(franchiseDetails.state && franchiseDetails.state.length > 0) ? franchiseDetails.state + ', ' : ''}
        ${(franchiseDetails.country && franchiseDetails.country.length > 0) ? franchiseDetails.country + ', ' : ''}
        ${(franchiseDetails.zipCode && franchiseDetails.zipCode.length > 0) ? franchiseDetails.zipCode : ''}
    </span>`;
    
    return `
    <li class="location-item">
        <div class="location-data">
       <div class="location-heading"><a href="${franchiseDetails.websiteUrl ? franchiseDetails.websiteUrl : "#"}" target="_blank" class="primary-link">${franchiseDetails.doingBusinessAs}</a></div>      
            ${addrLine1}
            ${addrLine2}
            ${addrLine3}    
            <div class="location-links">
            ${cpkConfig.showPhone? printPhoneButtonLink(franchiseDetails.phoneNumber, cpkConfig.phoneViewType, cpkConfig.phoneText):""}
            </div>                
        </div>
        <div class="location-cta">
        ${cpkConfig.showRequestEstimate? printReqButtonLink("#", cpkConfig.requestEstimateViewType, cpkConfig.requestEstimateText,brandData): ""}
        </div>
    </li>`;
}