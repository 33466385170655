import { tns } from "tiny-slider";
import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import apiRequest from "../api/apiRequest";
import { isLeadDataHms } from "./hms-step1";
import { startLoader, stopLoader } from "../util/loader";
import { Step3 } from "./step3";
import { getBrandDetailsFromBrandJson, hmsProgressBarUpdate, removeAllEventListeners } from "../util/share";
import { handleCommonInputError } from "../util/commonValidation";

let allAppData: any = []
let moment = require('moment');
let date = '';
let time = '';
let schaduleId = '';
let changeZip: any;
let fullData: any = null;


export function currentActiveStep(currentStep: any) {
    const previousSections = document.querySelectorAll(".section-main>.contact-us-section");
    if (currentStep && previousSections) {
        previousSections.forEach((elm) => {
            elm.classList.remove("form-step-active");
        })
        currentStep.classList.add("form-step-active");
    }
}

export class Step2 {

    private buttonName: any = [];
    private form: any;
    public bookInspector: any;
    public schaduleDom: any;

    constructor() {

        this.init();
        this.initInput();
        this.setAppointmainCalander();
        this.initCancelBtn();
        hmsProgressBarUpdate('0%', 0);
    }

    init() {

        window.scrollTo(0,0);
        this.form = document.querySelectorAll('.appointment-details-section.contact-us-section.form-step .btn-next');
        const handleSubmitBtn = removeAllEventListeners('.appointment-details-section.contact-us-section.form-step .btn-next')
        handleSubmitBtn?.addEventListener('click', this.handleSubmit.bind(this));
    }

    
    private initCancelBtn(): void {
        const cancelBtn = removeAllEventListeners('.appointment-details-section.contact-us-section.form-step .cancel-verification');
        if (cancelBtn) {
            cancelBtn?.addEventListener('click', async () => {
                /*Code to be uncommented once all POS moves to onverity */

                const hmsRedirectLocal = localStorage.getItem('hms_redirect_local');
                if(hmsRedirectLocal){
                    const conceptId = (document.getElementById("conceptId") as HTMLInputElement)?.value;
                    const brandData = await getBrandDetailsFromBrandJson(conceptId);
                    let redirectUrl = localStorage.getItem("dbaName") ? localStorage.getItem("dbaName") : '';

                    if(brandData?.weblocation_path_group){
                        redirectUrl = `/${brandData.weblocation_path_group}/${redirectUrl}`
                    }else {
                        redirectUrl = `/${redirectUrl}`
                    }

                    if(redirectUrl){
                        localStorage.removeItem('hms_redirect_local');
                        window.location.href = window.location.origin + redirectUrl;
                        return 
                    }

                }
                const zipModalDiv = document.querySelector('.zip-modal') as HTMLInputElement;
                if (zipModalDiv) {
                    zipModalDiv.style.display = "flex";
                }
                const formSectionDiv = document.getElementById('form-section') as HTMLElement;
                if (formSectionDiv) {
                    formSectionDiv.style.display = "none";
                }
                window.scrollTo(0,0);

                
            });
        }
    }

    initInput() {
        this.bookInspector = document.getElementById('book-inspector');
        if(this.bookInspector){
            this.bookInspector.addEventListener('input', () => handleCommonInputError(this.bookInspector, `${ this.bookInspector.id}-modal-error-msg`, REGEX.sendName, 'Please Enter Book Inspector'));
        }
    }

    nextStep3() {
        const inspectionStepElement = document.getElementById('inspection-step') as HTMLElement;
        currentActiveStep(inspectionStepElement);
        hmsProgressBarUpdate('25%', 1);
    
    }

    handleSubmit() {

        if (this.validateForm()) {

            const bookingData: any = JSON.parse(sessionStorage.getItem('hms_booking') as string) ?? {};

            const calendarPaylod: any = {};
            calendarPaylod.bookInspector = this.bookInspector?.value ?? '';
            calendarPaylod.userSelectDate = date;
            calendarPaylod.userSelectTime = time;
            calendarPaylod.isCalanderDIsable = isLeadDataHms;
            calendarPaylod.ScheduleID = schaduleId;
            bookingData.calendarPaylod = calendarPaylod;
            bookingData.ScheduleID = schaduleId

            sessionStorage.setItem('hms_booking', JSON.stringify(bookingData));

            this.nextStep3();
            this.setupDateTime();
        }
    }

    setupDateTime() {

        const dateTimeHtml = document.querySelectorAll('.step-time');
        const timecontainer = document.querySelector('.select-time');

        dateTimeHtml?.forEach((dtTime) => {
            if (dtTime) {
                dtTime.innerHTML = `Selected Start Time: <span>${moment(date).format('ddd DD MMM, YYYY')} at ${timecontainer?.innerHTML || ''}</span>`
            }
        })
    }


    public validateForm(): boolean {

        document.querySelectorAll('.error-msg')?.forEach(e => e.classList.add('hidden'));
        document.querySelectorAll('.invalid-field')?.forEach(e => e.classList.remove('invalid-field'));

        const errors: Record<string, string> = {};


        const inputs = [
            { input: this.bookInspector, id: this.bookInspector?.id, regex: REGEX.sendName, errorMessage: 'Please Enter valid Book Inspector.' },
        ];

        if(!date || !time){
            errors['appointment-selection'] = document.getElementById('appointment-selection-modal-error-msg')?.innerText as any ?? 'Please Select a Date and Time.';
        }

        inputs.forEach(({ input, id, regex, errorMessage }) => {
            let errorMessage2 = errorMessage;
            errorMessage = document.getElementById(`${id}-modal-error-msg`)?.innerText ?? errorMessage;

            if (input.required && input.value === '') {
                errors[id] = errorMessage;
            } else if (input.value !== '' && regex && !regex.test(input.value)) {
                errors[id] = errorMessage2;
            }
        });

        const firstErrorFieldId = Object.keys(errors)[0];
        if (firstErrorFieldId) {
            const firstErrorField = document.getElementById(firstErrorFieldId);
            if (firstErrorField) {
                firstErrorField.focus();
            }
        }

        Object.keys(errors).forEach(fieldId => {
            const field = document.getElementById(fieldId);
            const fieldError = document.getElementById(`${fieldId}-modal-error-msg`);
            if (field && field.parentNode) {
                field.classList.add("invalid-field");
                if (fieldError) {
                    fieldError.innerHTML = errors[fieldId];
                    fieldError.classList.remove('hidden');
                }
            }
        });

        return Object.keys(errors).length === 0;

    }


    setTinySlider(currentIndex: any) {

        try {
            tns({
                container: '.date-slider',
                loop: false,
                gutter: 8,
                items: 7,
                slideBy: 'page',
                nav: false,
                autoplay: false,
                speed: 400,
                swipeAngle: false,
                autoplayButtonOutput: false,
                mouseDrag: true,
                lazyload: true,
                controlsContainer: "#customize-controls",
                responsive: {
                    350: {
                        items: 5,
                    },
                    640: {
                        items: 5,
                    },
                    768: {
                        items: 5,
                    },
                    1024: {
                        items: 7,
                    }
                }
            });

        } catch (error) {

        }
    }

    setAppointmainCalander() {
        const hmsBookingData = JSON.parse(sessionStorage.getItem('hms_booking') as any);
        let zipValue = ((document.getElementById('location-search-input') as HTMLInputElement)?.value || hmsBookingData?.zipcode) ?? sessionStorage.getItem('sAddressParam');
        if (REGEX.sendCaPostalCode.test(zipValue)) {
            //if control comes here means entered zip is for CA locations
            zipValue = zipValue?.indexOf(" ") == -1 ? zipValue?.replace(/^(.{3})(.*)$/, "$1 $2") : zipValue;
        }
        try {
            const weblocationId = sessionStorage.getItem('franchiseWebLocationId');
            const dynamic_url = `${apiConfig.AppointMentAPi}?PostalCode=${zipValue}&WebLocationId=${weblocationId}&NumberOfDays=56&DateStart=${moment().format('MM/DD/YYYY')}&IsEstimate=true&apikey=${process.env.JS_API_KEY}`
            const request = {
                method: 'GET',
                url: dynamic_url,
            };
            if (date == "") {
                this.calendarApiRequest(request);
            } else {
                if (changeZip) {
                    //   setchangeZip(false);
                    changeZip = false;
                    this.calendarApiRequest(request);
                }
            }

        }
        catch (err) {
            // 
        }
    }

    calendarApiRequest(request: any) {
        startLoader()
        apiRequest(request)
            .then((response: any) => {
                const calendarContainer: any = document.getElementsByClassName("calendar-container");
                const hasSameDay = fullData?.options['hasSameDayScheduling'] ? fullData?.options['hasSameDayScheduling'] : true
                allAppData = response?.resultCollection
                let currentIndex = allAppData.findIndex((dt: any) => dt.isAvailable);
                if (currentIndex === -1) {
                    const linkCheckbox: any = document.getElementById('link-checkbox');
                    calendarContainer[0].style.display = "none";
                    const calendarHeading = document.getElementsByClassName("appointment-heading");
                    const systemErrorMsg = document.getElementById("modal-appoinment-slot")?.innerHTML;
                    if(calendarHeading.length > 0){
                        calendarHeading[0].innerHTML = systemErrorMsg ? systemErrorMsg : "";
                    }
                    this.enableButton();
                    if(linkCheckbox){
                        linkCheckbox.checked = true;
                    }
                    return;
                }
                
                currentIndex = Math.floor(currentIndex / 3);
                const filterData = this.removeDuplicateDates(allAppData, hasSameDay);
                date = allAppData?.find((x: any) => x.isAvailable).startDate;
                const cards = this.setCard(filterData);
                this.buttonName = document.querySelectorAll('.date-details-item .dateapp');
                // time = allAppData?.find((x: any) => x.isAvailable).displayText;
                const scId: any = allAppData?.find((x: any) => x.isAvailable);

                this.setTimeUl(scId);

                schaduleId = scId?.scheduleID;

                document.querySelector('.appointment-section-text')!.innerHTML = `${moment(date).format('ddd MMM DD, YYYY')}, <span class="select-time"> Please Select a Time </span>`

                let containner: any = (document.querySelector('.date-slider') || document.querySelector('.date-details-list'));

                if (containner) {
                    containner.innerHTML = '';
                    containner.innerHTML = cards.join('')
                }
                this.addListner()
                this.setTinySlider.call(this, currentIndex);
                calendarContainer[0].style.display = "flex";
                setTimeout(() => {
                    stopLoader();
                }, 0)

                const unavailableErr = document.querySelector('.unavailable') as HTMLElement;
                if(unavailableErr){
                    unavailableErr.style.display = 'none';
                }

            }).catch((err) => {
                stopLoader();
                const unavailableErr = document.querySelector('.unavailable') as HTMLElement;
                if(unavailableErr){
                    unavailableErr.style.display = 'flex';
                }
                const linkCheckbox: any = document.getElementById('link-checkbox');
                const calendarContainer: any = document.getElementsByClassName("calendar-container");
                if(calendarContainer && calendarContainer?.length > 0) {
                calendarContainer[0].style.display = "none";
                }
                const calendarHeading = document.getElementsByClassName("appointment-heading");
                const systemErrorMsg = document.getElementById("modal-appoinment-slot")?.innerHTML;
                if(calendarHeading && calendarHeading?.length > 0){
                    calendarHeading[0].innerHTML = systemErrorMsg ? systemErrorMsg : "";
                }
                this.enableButton();
                if(linkCheckbox){
                    linkCheckbox.checked = true;
                }
                return;
            });
    }

    enableButton() {
        const container = document.querySelector('.appointment-details-section .btn-container .primary-btn') as HTMLButtonElement;
        if(container){
            container.disabled = false;
            container.style.opacity = '1';
        }
    }

    removeDuplicateDates(resultCollection: any, hasSameDay: any) {
        const uniqueDates = new Set();
        const filteredResultCollection = [];
        for (const item of resultCollection) {
            const startDate = item.startDate.split('T')[0]; // Extract the date part
            if (!uniqueDates.has(startDate)) {
                uniqueDates.add(startDate);
                filteredResultCollection.push(item);
            }
        }

        const hasSameDaate = moment().format('DD MMM, YYYY')
        if (hasSameDay == false) {

            const data = filteredResultCollection.filter((x: any) => hasSameDaate != moment(x.startDate).format('DD MMM, YYYY'));

            return data
        }
        else {
            return filteredResultCollection;
        }
    }

    setCard = (data: any) => {
        // this.disableButton();
        return data.map((item: any) => {
            const filterData: any = allAppData?.some((x: any) => moment(x.startDate).format('DD MMM, YYYY') == moment(item.startDate).format('DD MMM, YYYY') && x.isAvailable)
            return (`
                    <li class="date-details-item">
                    <button type="button" class="${!filterData ? 'date-details-disable' : moment(item.startDate).format('ddd DD MMM, YYYY') === moment(date).format('ddd DD MMM, YYYY') ? 'date-details-inactive date-details-active dateapp' : 'date-details-inactive dateapp'}"
                    value=${item.startDate}>
                        <span value=${item.startDate} class="date-text-small">${moment(item.startDate).format('MMM')}</span>
                        <strong value=${item.startDate} class="date-text-normal">${moment(item.startDate).format('DD')}</strong>
                        <input class="getDateApp" hidden value=${item.startDate}>
                        <input class="scheduleID" hidden value=${item.scheduleID}>                                
                        <span value=${item.startDate} class="date-text-small">${moment(item.startDate).format('ddd')} </span>
                    </button>
                </li>
          `)
        })

    }

    setTimeUl(item: any) {

        // this.disableButton();
        const calendarRow = document.querySelector('.calendar-row');
        const sampleData: any = allAppData?.filter((x: any) => moment(x.startDate).format('DD MMM, YYYY') == moment(item.startDate).format('DD MMM, YYYY'))
        const ulTimeData = sampleData?.map((x: any, i: any) => {
            return (`<li class="calendar-row-item"><button type="button" value="${x.scheduleID}" class="calendar-col btn-time  ${x.isAvailable == false ? 'disable-btn' : ''} "  id="${x.displayText}">${x.displayText}</button></li>`)
        })

        if (calendarRow)
            calendarRow.innerHTML = ulTimeData.join('')
        this.setListnerOnUlBtn();
        this.setAppointmentTimeText(sampleData);
    }

    disableButton() {
        const container = document.querySelector('.appointment-details-section .btn-container .primary-btn') as HTMLButtonElement
        container.disabled = true
        container.style.opacity = '0.3'

    }
    setAppointmentTimeText(data: any) {
        const timeButtons = document.querySelectorAll('.calendar-col.btn-time');
        timeButtons.forEach((item) => {
            data.forEach((element: any) => {
                if (item.innerHTML == element.displayText) {
                    item.setAttribute("value", element.scheduleID);
                    if (!element.isAvailable)
                        item.setAttribute("disabled", "");
                }
            });

        })
    }
    setListnerOnUlBtn() {
        const timesBtn = document.querySelectorAll('.calendar-col');
        timesBtn.forEach(e => e.classList.remove('active'));
        if (timesBtn) {
            timesBtn.forEach((item: any) => {
                item.addEventListener('click', (e: any) => {
                    timesBtn.forEach(e => e.classList.remove('active'));
                    item.classList.toggle('active');
                    const timecontainer = document.querySelector('.select-time')
                    if (timecontainer)
                        timecontainer.innerHTML = item.innerHTML
                    schaduleId = item.value;
                    time = item.id;
                    this.enableButton();
                    this.hideDateTimeError();
                })

            })
        }
    }

    hideDateTimeError() {
        const dataTimeErrorModal = document.getElementById('appointment-selection-modal-error-msg') as HTMLElement;

        if(dataTimeErrorModal){
            dataTimeErrorModal.classList.add('hidden');
        }
    }

    addListner() {
        document.querySelectorAll('.dateapp')?.forEach((item: any) => {
            item.addEventListener('click', (e: any) => {
                const rm = document.querySelectorAll('.dateapp')
                rm.forEach(e => e.classList.remove('date-details-active'));
                date = moment(item.querySelector('.getDateApp').value).format('ddd DD MMM, YYYY');
                time = '';
                schaduleId = item.querySelector('.scheduleID').value
                item.classList.toggle('date-details-active')
                const itemFInd = allAppData?.find((x: any) => x.scheduleID == schaduleId)
                document.querySelector('.appointment-section-text')!.innerHTML = `${moment(item.querySelector('.getDateApp').value).format('ddd MMM DD, YYYY')}, <span class="select-time"> Please Select a Time </span>`;
                this.setTimeUl(itemFInd)
            })
        })
    }
}