import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import { ResidentialForm } from "../hero_component";
import { getCountryCode } from "../location-search-map/getCountryCode";
import { startLoader, stopLoader } from "../util/loader"
export class LocateAddressBumper {
  private address1: HTMLElement
  private sendButton: HTMLButtonElement | null;
  private formElement: HTMLFormElement;
  constructor() {
    const modalContent = document.querySelector('.find-local-modal .modal-loc-div');
    this.formElement = document.querySelector('.step-2 .modal-search') as HTMLFormElement;
    if (modalContent) {
      modalContent.classList.add('banner-wizard');
    }
    this.sendButton = document.querySelector('.step-2 .form-cta button');
    if (this.sendButton) {
      this.sendButton.addEventListener("click", this.handleNextBtnClick.bind(this));
    }
    this.address1 = document.getElementById('modal-address-1') as HTMLInputElement

    const closeButton = document.getElementById('close-local-modal');
    if (closeButton) {
      closeButton.addEventListener('click', () => {
        this.handleModalClose();
      });
    }
    this.setupInlineValidation();
  }

  private enableButtonFindLocal(): void {
    const nextBtn = document.querySelector('.step-2 .disable-btn');
    if (nextBtn) {
      nextBtn.addEventListener("click", this.handleNextBtnClick.bind(this));
    }

  }

  private setupInlineValidation(): void {
    const fields = {
      address1: document.getElementById('modal-address-1') as HTMLInputElement,
      address2: document.getElementById('modal-address-2') as HTMLInputElement,
      city: document.getElementById('modal-city') as HTMLInputElement,
      state: document.getElementById('modal-states') as HTMLSelectElement,
      zipCode: document.getElementById('modal-zipcode') as HTMLInputElement
    };

    fields.address1?.addEventListener('input', (e) => this.validateField(e.target as HTMLInputElement, () => this.addressValidation(fields.address1.value, true)));
    fields.address2?.addEventListener('input', (e) => this.validateField(e.target as HTMLInputElement, () => this.addressValidation(fields.address2.value, false)));
    fields.city?.addEventListener('input', (e) => this.validateField(e.target as HTMLInputElement, () => this.cityValidation(fields.city.value)));
    fields.zipCode?.addEventListener('input', (e) => this.validateField(e.target as HTMLInputElement, () => this.zipValidation(fields.zipCode.value)));
    fields.state?.addEventListener('change', (e) => this.validateField(e.target as HTMLSelectElement, () => this.stateValidation(fields.state.value)));
  }

  private validateField(element: HTMLInputElement | HTMLSelectElement, validationFunction: () => string): void {
    const error = validationFunction();
    const errorElement = element.nextElementSibling as HTMLSpanElement;
    if (error) {
      element.classList.add('invalid-field');
      if (errorElement?.classList.contains('error-msg')) {
        errorElement.textContent = error;
      } else {
        const newErrorElement = document.createElement('span');
        newErrorElement.className = 'error-msg';
        newErrorElement.textContent = error;
        element.parentNode?.insertBefore(newErrorElement, element.nextSibling);
      }
    } else {
      element.classList.remove('invalid-field');
      if (errorElement?.classList.contains('error-msg')) {
        errorElement.remove();
      }
    }
  }

  private getFormFields(): any {
    const fields = {

      zipCode: (document.getElementById('modal-zipcode') as HTMLInputElement).value,
      address1: (document.getElementById('modal-address-1') as HTMLInputElement).value,
      address2: (document.getElementById('modal-address-2') as HTMLInputElement).value,
      city: (document.getElementById('modal-city') as HTMLInputElement).value,
      state: (document.getElementById('modal-states') as HTMLSelectElement).value,
    };
    return fields;
  }

  private addressValidation(inputVal: string, isRequired: boolean): string {
    const addrRegex = REGEX.noBracketAddress;
    if (isRequired) {
      if (!inputVal.trim()) {
        return 'Address Line 1 is required.';
      }
      if (!addrRegex.test(inputVal)) {
        return 'Enter valid address.';
      }
    } else if (inputVal.trim() && !addrRegex.test(inputVal)) {
        return 'Enter valid address.';
    }
    return '';
  }

  private zipValidation(inputVal: string): string {
    const zipRegex = REGEX.sendZip;
    if (!inputVal.trim()) {
      return 'Zip code is required.';
    }
    if (!zipRegex.test(inputVal.trim()) || inputVal.trim() === '00000') {
      return 'Invalid zip code format.';
    }
    return '';
  }

  private cityValidation(inputVal: string): string {
    const cityRegex = REGEX.sendName;
    if (!inputVal.trim()) {
      return 'City is required.';
    }
    if (!cityRegex.test(inputVal)) {
      return 'Enter valid city.';
    }
    return '';
  }

  private stateValidation(inputVal: string): string {
    if (!inputVal || inputVal === '') {
      return 'State is required.';
    }
    return '';
  }


  public validateForm(): boolean {
    const formFields = this.getFormFields();
    document.querySelectorAll('.step-2 .error-msg').forEach(e => e.remove());
    // document.querySelectorAll('.error-msg').forEach(e => e.classList.add('hidden'));
    document.querySelectorAll('.invalid-field').forEach(e => e.classList.remove('invalid-field'));

    let errors: { [key: string]: string } = {};
    errors['modal-address-1'] = this.addressValidation(formFields.address1, true);
    errors['modal-address-2'] = this.addressValidation(formFields.address2, false);
    errors['modal-zipcode'] = this.zipValidation(formFields.zipCode);
    errors['modal-city'] = this.cityValidation(formFields.city);
    errors['modal-states'] = this.stateValidation(formFields.state);

    Object.keys(errors).forEach((fieldId) => {
      const field: any = document.getElementById(fieldId);
      if (field && field.parentNode) {
        const errorMessage = errors[fieldId];
        if (errorMessage) {
          const errorElement = document.createElement('span');
          errorElement.className = 'error-msg';
          errorElement.textContent = errorMessage;
          field.classList.add("invalid-field");
          field.parentNode.appendChild(errorElement);
        }
      }
    });

    return Object.values(errors).every(error => !error);
  }

  public handleNextBtnClick(event: Event): void {
    if (this.validateForm()) {
      startLoader()
      this.handleNextBtnSubmit(event)
    }
  }

  public errorHandling() {
    const text1 = document.getElementById("step1-text1") as HTMLElement;
    const text2 = document.getElementById("step1-text2") as HTMLElement;
    text1.style.display = "none";
    text2.style.display = "block";

    const step1 = document.querySelector('.step-1') as HTMLElement;
    const step2 = document.querySelector('.step-2') as HTMLElement;

    step2.classList.add('hidden');
    step1.classList.remove('hidden');
  }
  public handleNextBtnSubmit(event: Event): void {
    const inputAddressBumper = document.querySelector('.step-2 #modal-address-1') as HTMLInputElement;
    const inputAddressBumper2 = document.querySelector('.step-2 #modal-address-2') as HTMLInputElement;
    const inputCity = document.querySelector('.step-2 #modal-city') as HTMLInputElement;
    const inputState1 = document.querySelector('.step-2 #modal-states') as HTMLInputElement;
    const inputZipCode = document.querySelector('.step-2 #modal-zipcode') as HTMLInputElement;
    const sAddressBumper = inputAddressBumper?.value.trim() || '';
    const sAddressBumper1 = inputAddressBumper2?.value.trim() || '';
    const sCityBumper = inputCity?.value.trim() || '';
    const sStateBumper = inputState1?.value.trim() || '';
    const sZipCodeBumper = inputZipCode?.value.trim() || '';
    const sAddressParam = `${sAddressBumper} ${sAddressBumper1} ${sCityBumper} ${sStateBumper} ${sZipCodeBumper}`;
    const apiUrl = apiConfig.LocateLocationApiWithRoundRobinTrue.replace('sAddressParamValue', encodeURIComponent(sAddressParam));
    fetch(apiUrl)
      .then((response) => response.json())
      .then((result) => {

        if (result.length > 0) {
          const jsonItems = result.filter((e: any) => e.franchiseId === e.franchiseId);
          if (jsonItems.length === 1) {
            const shortFormAddressBumper = sessionStorage.getItem('shortFormAddressBumper');
            if (shortFormAddressBumper === "true") {
              sessionStorage.removeItem('shortFormAddressBumper');
              const obj = {
                'franchiseId': result[0].franchiseId,
                'franchiseWebLocationId': result[0].franchiseWebLocationId

              }
              const addressParam = {
                "address": {
                  "state": sStateBumper,
                  "city": sCityBumper,
                  "postalCode": sZipCodeBumper
                }, "title": sAddressBumper
              }
              const shortFormAddressBumper: any = new ResidentialForm();
              shortFormAddressBumper.handleSubmit(event, obj, addressParam)
            } else {
              const item = jsonItems[0];
              const sRedirectURL = item.locationWebsiteUrl;
              sessionStorage.setItem('alternateConcept', item.alternateConcept);
              sessionStorage.setItem('franchiseWebLocationId', item.franchiseWebLocationId);
              sessionStorage.setItem('doingBusinessAs', item.doingBusinessAs);
              sessionStorage.setItem('franchiseeName', item.franchiseeName);
              sessionStorage.setItem('sAddressParam', sZipCodeBumper);
              let countryCode = getCountryCode();
              sessionStorage.setItem('countryCode', countryCode);

              if (sRedirectURL) {
                if (sRedirectURL.startsWith('http:')) {
                  window.location.href = sRedirectURL.replace('http:', 'https:');
                } else if (sRedirectURL.startsWith('https:')) {
                  window.location.href = sRedirectURL;
                }
              }
            }
          }
          else {
            this.errorHandling();

          }
        }
        else {
          this.errorHandling();


        }
        stopLoader();
      });

  }

  private clearValidationErrors(): void {
    document.querySelectorAll('.step-2 .error-msg').forEach(e => e.remove());
    document.querySelectorAll('.invalid-field').forEach(e => e.classList.remove('invalid-field'));
  }

  private handleModalClose(): void {
    this.formElement.reset();
    this.clearValidationErrors();
  }

}