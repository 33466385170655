import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { extractServices } from "./filterArray";
import { AppointmentForm } from './step3';
import { brandColorCode } from "../location-search-map/config";
import { apiCaching } from "../config/cachingConfig";
import { getCachedAPIResponse } from "../apiCaching/apiWrapper";


import { s3upload } from "../util/fileUploads3";
import { startLoader, stopLoader } from "../util/loader"
import { getCountryCodefromAssetpath, getWeblocationPathGroupWithDynamicConfig, removeElementEventListeners } from "../util/share";

let serviceData: any = []

export let addressFromInput:any = {}

export const FilesUrl: any = [null, null, null]
export let inspectionFeeObj: any = null
export let serviceSelect = ''
export let serviceSkuId = ''
export let serviceSelectCat = ''
export let serviceSelectFloors = ''
export let serviceStype: any = 'Residential'

interface MenuItem {
    id: number;
    name: string;
    serviceType: string;
}
export class FormHandlerStep2 {
    private formElement: HTMLFormElement;
    private form: any;
    private serviceTypeRadioElements: NodeListOf<HTMLInputElement>;
    private sendNoteTextarea: HTMLTextAreaElement;
    private selectServiceSelect: HTMLSelectElement;
    private imageInputs: HTMLInputElement[];
    private emailCheckbox: HTMLInputElement;
    private textCheckbox: HTMLInputElement;
    private howAboutUsSelect: HTMLSelectElement;
    private selectSubServiceSelect: HTMLSelectElement;
    private selectFloorAppliance: HTMLSelectElement;
    private showButton2: Boolean
    private onlineScheduling :boolean;
    private selectServiceProCategory : any;
    private selectServicePro : any;
    
    constructor(onlineScheduling:boolean) {
        this.onlineScheduling = onlineScheduling;
        this.formElement = document.querySelector('.contact-us-section') as HTMLFormElement;
        this.showButton2 = true
        this.form = document.querySelectorAll('.contact-us-section .btn-next')
        this.serviceTypeRadioElements = document.querySelectorAll('input[name="service-type"]');
        this.sendNoteTextarea = document.getElementById('send-note') as HTMLTextAreaElement;
        this.selectServiceSelect = document.getElementById('select-service-category') as HTMLSelectElement;
        this.imageInputs = Array.from(document.querySelectorAll('input[type="file"]'));
        this.emailCheckbox = document.getElementById('default-checkbox') as HTMLInputElement;
        this.textCheckbox = document.getElementById('checked-checkbox') as HTMLInputElement;
        this.howAboutUsSelect = document.getElementById('how-about-us') as HTMLSelectElement;
        this.selectSubServiceSelect = document.getElementById('select-service') as HTMLSelectElement;
        this.selectFloorAppliance = document.getElementById('select-floor') as HTMLSelectElement;
        this.selectServiceProCategory = document.getElementById('select-servicepro-category') as HTMLSelectElement;
        this.selectServicePro = document.getElementById('select-servicepro') as HTMLSelectElement;
        this.howAboutUsSelect?.addEventListener('change', this.hideError.bind(this, 'how-about-us'))
        this.selectServiceSelect?.addEventListener('change', this.hideError.bind(this, 'select-service-category'));
        this.sendNoteTextarea?.addEventListener('input', this.hideError.bind(this, 'send-note'))
        this.selectSubServiceSelect?.addEventListener('change', this.hideError.bind(this, 'select-service'))
        this.selectFloorAppliance?.addEventListener('change', this.hideError.bind(this, 'select-floor'));
        this.selectServiceProCategory?.addEventListener('change', this.hideError.bind(this, 'select-servicepro-category'));
        this.selectServicePro?.addEventListener('change', this.hideError.bind(this, 'select-servicepro'));



        this.initImages()

        if (this.form) {
           removeElementEventListeners(this.form[1])?.addEventListener('click', this.handleSubmit.bind(this))

            if (this.howAboutUsSelect?.required || this.selectServiceSelect?.required || this.sendNoteTextarea?.required) {
                // this.form[1].disabled = true
                // this.form[1].style.opacity = '0.3'
            }
        }

        if (this.serviceTypeRadioElements && this.serviceTypeRadioElements?.length > 0) {
            this.serviceTypeRadioElements[0].checked = true;
            this.serviceTypeRadioElements.forEach((item: any) => {
                item.addEventListener('click', (e: any, i: any) => {
                    try {
                        startLoader();
                        const customSelect2 = document.getElementById("custom-select2");
                        const customSelect2SchedulerPro = document.getElementById("custom-select2-scheduler-pro");
                        this.disibleSelectCateogry(customSelect2, 'select-service');
                        this.disibleSelectCateogry(customSelect2SchedulerPro, 'select-service');
                        // Function to remove all options
                        function removeAllOptions(selectElement: HTMLSelectElement) {
                            while (selectElement?.options?.length > 0) {
                                selectElement?.remove(0);
                            }
                        }
                        const selectService = document.getElementById('select-service-category') as HTMLSelectElement;
                        const selectServicePro = document.getElementById('select-servicepro-category') as HTMLSelectElement;
                        removeAllOptions(selectServicePro);
                        let index = this.getValue()
                        if (selectService !== null) {
                            selectService.innerHTML = '';
                        }
                        if (selectServicePro !== null) {
                            selectServicePro.innerHTML = '';
                        }
                        // Create and append new <option> elements


                        serviceStype = index == 1 ? 'Commercial' : 'Residential'

                        const option = document.createElement('option');
                        option.value = ''; // Set the value attribute as needed
                        option.textContent = 'Select a Service Category'; // Set the option text as needed
                        if (selectService) {
                            selectService?.appendChild(option);
                            selectServicePro?.appendChild(option.cloneNode(true));
                        } else {
                            selectServicePro?.appendChild(option);
                        }

                        serviceData[index]?.category.forEach((optionData: any) => {
                            const option = document.createElement('option');
                            option.value = optionData.name; // Set the value attribute as needed
                            option.textContent = optionData.name; // Set the option text as needed
                            selectService?.appendChild(option);
                            selectServicePro?.appendChild(option.cloneNode(true));
                        });

                        setTimeout(() => {
                            if (window?.calendarTypeId == 2) {
                                this.setupData("custom-select1-scheduler-pro", false);
                                this.setupData2("custom-select2-scheduler-pro", true);
                            } else {
                                this.setupData("custom-select1", false);
                                this.setupData2("custom-select2", true);
                            }
                            stopLoader();
                        }, 1000);
                    } catch (error) {
                        stopLoader();
                        console.log("Service type onclick issue: ",error);
                    }
                })
           
            })

            //this.setAutoSuggested()
        }
        this.solutionAppointmentError();
        this.getMenu()
        this.setHowOknow()
        this.getFee()
        this.floorAppliance();

        const cancleBtn = document.querySelectorAll('.contact-us-section .cancel-verification')
        if (cancleBtn) {
            removeElementEventListeners(cancleBtn[1])?.addEventListener('click', () => {
                const formNext = document.querySelectorAll('#form-section .contact-us-section');
                const activeFormIndex = Array.from(formNext).findIndex(form => form.classList.contains('form-step-active'));
                const progress = document.querySelectorAll('.progressbar .progress-step')
                const progressBar: any = document.getElementById('progress')
                const form1 = formNext[activeFormIndex]
                const form2 = formNext[activeFormIndex-1]
                const progress1 = progress[activeFormIndex]
                const progress2 = progress[activeFormIndex-1];
                const totalSteps = formNext.length;
                const newProgressWidth = ((activeFormIndex - 1) / (totalSteps-1)) * 100;
                progressBar.style.width = `${newProgressWidth}%`;
                form1.classList.remove('form-step-active')
                form2.classList.add('form-step-active')
                progress1.classList.remove('progress-step-active')
                progress2.classList.add('progress-step-active')
                if(activeFormIndex==1){
                    progress1.classList.remove('progress-step-confirmed')
                }
            })
        }

        this.hideClosetButton();
        this.hideAndShowServicePro(window?.calendarTypeId==2);

    }

    private hideAndShowServicePro(isFlag: boolean = false) {
        const serviceProElement = document.querySelectorAll('.scheduler-pro');
        const serviceElement = document.getElementById('select-service');
        const categoryElement = document.getElementById('select-service-category');
            if (isFlag) {
                serviceProElement?.forEach((ele) => {
                    ele?.classList.remove('hidden');
                })
                serviceElement?.closest('.input-label-wrap')?.classList.add('hidden');
                categoryElement?.closest('.input-label-wrap')?.classList.add('hidden');
            } else {
                serviceProElement?.forEach((ele) => {
                    ele?.classList.add('hidden');
                })
                serviceElement?.closest('.input-label-wrap')?.classList.remove('hidden');
                categoryElement?.closest('.input-label-wrap')?.classList.remove('hidden');
            }
    }

    private hideClosetButton() {

        let appoinmentWrapper = document.querySelector('.select-solution-wrap');
        let appointMentCloset = window?.offersClosetAppointments;

        if (!appointMentCloset || !this.onlineScheduling) {
            appoinmentWrapper?.classList?.add('hidden');
            sessionStorage.setItem('solutionAppointment', 'false');
        }else {
            appoinmentWrapper?.classList?.remove('hidden');
            sessionStorage.setItem('solutionAppointment', 'true');
        }
    }

    private setAutoSuggested = () => {
      
            const request = {
                method: 'GET',
                url: ` https://discover.search.hereapi.com/v1/discover?q=${(document.getElementById('zip-code') as HTMLInputElement).value}&in=countryCode%3AUSA%2CCAN&at=0,0&apiKey=${process.env.JS_Heremap_API_KEY}`
            }
            apiRequest(request)
                .then((result: any) => {
                  
                     addressFromInput = result?.items[0]
    
                    // const arr = result?.items.map((item: any) => item.title)
                    // this.setDatat(result?.items.map((item: any) => item.title))
                })
                .catch((err) => {
                });
       
    }

    setupData(customSelect1:string,isDefaultBtnSelected:boolean = true) {    
        document.addEventListener("click", this.closeAllSelect.bind(this));
            const customSelect:any = document.getElementById(customSelect1);

            if(customSelect !==null){

                const select = customSelect.querySelector("select") as HTMLSelectElement;
                const options = select.options;
                const selectedOption = options[select.selectedIndex];

                const selectselectedd = customSelect.querySelector('.select-selected')
                selectselectedd?.remove();
                
                const colorCode:any = document.getElementById('conceptCode') as HTMLInputElement;
                const colorCodeVal:any = brandColorCode[colorCode.value];

                const selectSelected = document.createElement("button");
                selectSelected.type="button";
                selectSelected.className = "select-selected";
                selectSelected.innerHTML = selectedOption?.innerHTML && isDefaultBtnSelected ? selectedOption?.innerHTML : 'Select a Service Category' ;
                customSelect.appendChild(selectSelected);
                customSelect?.parentNode.querySelectorAll('.select-items.select-hide.slim-scroll')?.forEach((ele: Element) => {
                    ele?.remove();
                });

                const selectItems = document.createElement("div");
                selectItems.className = "select-items select-hide slim-scroll";
        
                for (let i = 0; i < options.length; i++) {
                    if (i === select.selectedIndex) continue;
        
                    const option = document.createElement("button");
                    option.innerHTML = options[i].innerHTML;
                    option.type="button";
        
                    option.addEventListener("click", () => { // Use an arrow function here
                        select.selectedIndex = i;
                        selectSelected.innerHTML = options[i].innerHTML;

                        serviceSelectCat = options[i].innerHTML
                        selectSelected.style.backgroundColor = colorCodeVal;
                        selectSelected.style.color = "white";
                        this.setupServiceDropdown(selectSelected);
                        if(window?.calendarTypeId ==2){
                            this.setupData2("custom-select2-scheduler-pro");
                        }else {
                            this.setupData2("custom-select2")
                        }
                        this.closeAllSelect.call(this); // Remove unnecessary arguments
                    });
        
                    selectItems.appendChild(option);
                }
                
                this.setupServiceDropdown(selectSelected);

                customSelect.appendChild(selectItems);
        
                selectSelected.addEventListener("click", (e) => { // Use an arrow function here
                    e.stopPropagation();
                    this.closeAllSelect();
                    
                    selectItems.classList.toggle("select-hide");
                    selectSelected.classList.toggle("select-arrow-active");
                    this.escapeServiceCategory(selectItems,selectSelected);
                });
            // });
            }else {
                this.setupServiceDropdown(null);
            }
    }

   

    setupData2(customSelectId: string,disableService: boolean = false) {
        document.addEventListener("click", this.closeAllSelect.bind(this));
        const customSelect:any = document.getElementById(customSelectId);
        if(customSelect != null) {
        // customSelects.forEach((customSelect) => {
            const select = customSelect.querySelector("select") as HTMLSelectElement;
            const selectselected = customSelect.querySelector('.select-selected')
              selectselected?.remove()
            const options = select.options;
            const selectedOption = options[select.selectedIndex];

   
            const colorCode:any = document.getElementById('conceptCode') as HTMLInputElement;
            const colorCodeVal:any = brandColorCode[colorCode.value];

            const selectSelected = document.createElement("button");
            selectSelected.className = "select-selected";
            selectSelected.type="button";
            selectSelected.innerHTML = selectedOption?.innerHTML ?selectedOption?.innerHTML : '' ;
            customSelect.appendChild(selectSelected);
            customSelect?.parentNode.querySelectorAll('.select-items.select-hide.slim-scroll')?.forEach((ele: Element) => {
                ele?.remove();
            });
            const selectItems = document.createElement("div");
            selectItems.className = "select-items select-hide slim-scroll";

            if((options.length > 1 || this.selectServiceSelect === null) && !disableService ){
                const data = document.querySelectorAll('.select-service')
                data?.forEach((item) => {
                    item?.classList?.remove('select-service')
                })
            }
    
            for (let i = 0; i < options.length; i++) {
                if (i === select.selectedIndex) continue;
    
                const option = document.createElement("button");
                option.innerHTML = options[i].innerHTML;
                option.type="button";
                const dataServiceSkuId = options[i]?.getAttribute('data-service-sku-id');
                const dataServiceSku = options[i]?.getAttribute('data-service-sku');
                if(dataServiceSkuId){
                    option.setAttribute('data-service-sku-id', dataServiceSkuId);
                }    
                if(dataServiceSku){
                    option.setAttribute('data-service-sku', dataServiceSku);
                }
                option.addEventListener("click", () => { // Use an arrow function here
                    select.selectedIndex = i;
                    selectSelected.innerHTML = options[i].innerHTML;
                    serviceSelect = options[i].innerHTML;
                    selectSelected.style.backgroundColor = colorCodeVal;
                    selectSelected.style.color = "white";
                    const dataServiceSkuId = options[i]?.getAttribute('data-service-sku-id');
                    const dataServicSku= options[i]?.getAttribute('data-service-sku');
                    if(dataServiceSkuId && dataServicSku){
                        serviceSkuId = dataServiceSkuId;
                    }
                    this.closeAllSelect(); // Remove unnecessary arguments
                });
    
                selectItems.appendChild(option);
            }
    
            customSelect.appendChild(selectItems);
    
            selectSelected.addEventListener("click", (e) => { // Use an arrow function here
                e.stopPropagation();
                if(selectItems.innerHTML){
                    this.closeAllSelect();   
                    selectItems.classList.toggle("select-hide");
                    selectSelected.classList.toggle("select-arrow-active");
                    this.escapeServiceCategory(selectItems,selectSelected);
                }
            });
        // });
        }
    }
    
    floorAppliance() {
        document.addEventListener("click", this.closeAllSelect.bind(this));
        const customSelect = document.getElementById("custom-select3");
        const hideError = document.getElementById('select-floor-error-msg');
        if (customSelect) {
            const select = customSelect.querySelector("select") as HTMLSelectElement;
            const selectselected = customSelect.querySelector('.select-selected');
            if (select && selectselected) {
                selectselected.remove();
                const options = select.options;
                const selectedOption = options[select.selectedIndex];
    
                const colorCode = document.getElementById('conceptCode') as HTMLInputElement;
                const colorCodeVal = brandColorCode[colorCode.value];
    
                const selectSelected = document.createElement("button");
                selectSelected.className = "select-selected";
                selectSelected.type = "button";
                selectSelected.innerHTML = selectedOption ? selectedOption.innerHTML : '';
                customSelect.appendChild(selectSelected);
    
                const selectItems = document.createElement("div");
                selectItems.className = "select-items select-hide slim-scroll";
    
                if ((options.length > 1) || !this.selectServiceSelect) {
                    const data = document.querySelector('.select-floor');
                    if (data) {
                        data.classList.remove('select-floor');
                    }
                }
    
                for (let i = 0; i < options.length; i++) {
                    if (i === select.selectedIndex) continue;
    
                    const option = document.createElement("button");
                    option.innerHTML = options[i].innerHTML;
                    option.type = "button";
                    option.addEventListener("click", () => {
                        hideError?.classList.add('hidden');
                        select.selectedIndex = i;
                        selectSelected.innerHTML = options[i].innerHTML;
                        serviceSelectFloors = options[i].innerHTML;
                        selectSelected.style.backgroundColor = colorCodeVal;
                        selectSelected.style.color = "white";
                        this.closeAllSelect();
                    });
    
                    selectItems.appendChild(option);
                }
    
                customSelect.appendChild(selectItems);
    
                selectSelected.addEventListener("click", (e) => {
                    e.stopPropagation();
                    this.closeAllSelect();
                    selectItems.classList.toggle("select-hide");
                    selectSelected.classList.toggle("select-arrow-active");
                    this.escapeServiceCategory(selectItems, selectSelected);
                });
            }
        }
    }
     
    closeAllSelect() { // Remove unnecessary 'e' parameter
        const selectItems = document.querySelectorAll(".select-items.slim-scroll");
        const selectSelected = document.querySelectorAll(".select-selected");
    
        selectItems.forEach((item) => {
            item.classList.add("select-hide");
        });
    
        selectSelected.forEach((selected) => {
            selected.classList.remove("select-arrow-active");
        });
    }
    




    initImages() {
        const containerImages = document.querySelector('.scheduler-main')

        if (containerImages) {
            const fileInputs = document.querySelectorAll('input[type="file"]');
            fileInputs.forEach((fileInput, index) => {
                fileInput.addEventListener('change', () => {
                    this.showFilePreview(fileInput, index)
                }
                );
            });

            const closeIcons = document.querySelectorAll('.icon-close')

            closeIcons.forEach((close, index) => {
                close.addEventListener('click', this.clearPreview.bind(this, index));
            });
        }

    }

    getFee() {
        const dynamic_url = `${apiConfig.franchiseWebLocationPostalCodeFees}/${sessionStorage.getItem('franchiseWebLocationId')}/${(document.getElementById('zip-code') as HTMLInputElement).value}?apikey=${process.env.JS_API_KEY}`
        const request = {
            method: 'GET',
            url: dynamic_url,
        };

       

        apiRequest(request)
            .then((response: any) => {
                inspectionFeeObj = response.find((x: any) => x.feeName == "Inspection Fee")
                this.showOrHideInspectionFeeDiv(inspectionFeeObj);
            }).catch((err) => {

            });

    }

    showOrHideInspectionFeeDiv(inspectionFeeObj: any) {
        const showFeeFlag = sessionStorage.getItem('hideInspectionFee'); 
        const container = document.querySelector('.inspection-fee');
        const completeInspectionDiv = document.querySelector(".fee-wrapper.alert-info") as HTMLElement;
            if(!completeInspectionDiv)
                return
            if (!inspectionFeeObj || !container || showFeeFlag === "false") {
                sessionStorage.setItem("hideInspectionFee", "false");
                completeInspectionDiv.style.display = "none";
                return;
            }
    
            const fee = inspectionFeeObj.fee;
            if (!fee) {
                sessionStorage.setItem("hideInspectionFee", "false");
                completeInspectionDiv.style.display = "none";
            } else {
                completeInspectionDiv.style.display = "flex";
                sessionStorage.setItem("hideInspectionFee", "true");
                container.innerHTML = `<strong> $${fee}</strong>`;
            }
    }

    setHowOknow() {

        let dynamic_url = `${apiConfig.howToKnow}?apikey=${process.env.JS_API_KEY}`;
        const franchiseId = sessionStorage.getItem('franchiseId'); 
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value?.toLocaleLowerCase();

        if(conceptCode === 'dvw'){
            dynamic_url = dynamic_url+`&franchiseId=${franchiseId}`
        }
        const request = {
            method: 'GET',
            url: dynamic_url,
        };

        const selectHowOnow = document.getElementById('how-about-us') as HTMLSelectElement;

        if (!this.howAboutUsSelect?.value || this.howAboutUsSelect?.value == 'Select a referal method')
            apiRequest(request)
                .then((response: any) => {
                    const options = response;
                    // Clear existing options
                    if(selectHowOnow){  
                        selectHowOnow.innerHTML = '';
                        // Create and append new <option> elements
                        const option = document.createElement('option');
                        option.value = ''; // Set the value attribute as needed
                        option.textContent = 'Select a Referral Method'; // Set the option text as needed
                        selectHowOnow.appendChild(option);

                        options.forEach((optionData: any) => {
                            const option = document.createElement('option');
                            option.value = optionData.leadSourceName; // Set the value attribute as needed
                            option.textContent = optionData.leadSourceName; // Set the option text as needed
                            option.setAttribute('data-sync-generic-lead-source-id', optionData.syncGenericLeadSourceId); 
                            if(conceptCode==='dvw' && optionData.referralTypeId){
                                option.setAttribute('data-referral-type-id', optionData.referralTypeId); 
                            }
                            selectHowOnow.appendChild(option);
                        });
                    }

                }).catch((err) => {

                    console.log(err,"error in setHowOknow function");
                    
                    if(conceptCode === 'dvw'){
                        document.getElementById('modal-zipcode-error')?.classList.add('hidden');
                        document.getElementById('error-modal-id')?.click();
                        document.getElementById('modal-appoinment-slot')?.classList.remove('hidden');
                        document.getElementById('error-modal')?.classList.remove('hidden');
                        
                    }

                });
    }

    private getValue() {
        const checkedRadio = document.querySelector('input[name="service-type"]:checked') as HTMLInputElement;

        // Check if a radio input is checked
        if (checkedRadio) {
            // Get the label associated with the checked radio input
            const labelForCheckedRadio = document.querySelector(`label[for="${checkedRadio.id}"]`) as HTMLLabelElement;

            // Get the text content of the label
            const labelText = labelForCheckedRadio?.textContent;

            // Use labelText as needed
            return labelText == 'Commercial' ? 1 : 0
        } else {
            return 0
        }
    }

    private handleSubmit(event: Event): void {
        event.preventDefault();

        const formNext = document.querySelectorAll('#form-section .contact-us-section');
        const calendarIndex = Array.from(formNext).findIndex(form => form.classList.contains('appointment-details-section'));
        // Perform client-side validation here
        if (this.isFormValid()) {
            if(calendarIndex ==2){

                if (this.form[0].innerHTML == 'Next' || this.form[1].innerHTML == 'Next') {
                
                    window.scrollTo({top: 0});
        
                    let appointMentCloset = window?.offersClosetAppointments; 

                    if((this.onlineScheduling && appointMentCloset) || (window.calendarTypeId ==2) ){
                    let solutionAppointment = (document.querySelector("input[type='radio'][name=solution-type]")as HTMLInputElement);
                    if(solutionAppointment || window.calendarTypeId ==2){
                        solutionAppointment && sessionStorage.setItem('solutionAppointment', 'true');
                        new AppointmentForm(this.onlineScheduling);
                    } 
                }
                }

                if (this.form[1].innerHTML == 'Submit') {
                    const onlineScheduling:any = false;
                    new AppointmentForm(onlineScheduling).handleSubmit(event, false);
                }
                else {

                    const formNext = document.querySelectorAll('#form-section .contact-us-section')
                    const progress = document.querySelectorAll('.progressbar .progress-step')
                    const progressBar: any = document.getElementById('progress')
                    const form1 = formNext[1]
                    const form2 = formNext[2]
                    const progress1 = progress[1]
                    const progress2 = progress[2]
                    progressBar.style.width = "100%"
                    form1.classList.remove('form-step-active')
                    form2.classList.add('form-step-active')
                    progress1.classList.remove('progress-step-active')
                    progress1.classList.add('progress-step-confirmed')
                    progress2.classList.add('progress-step-active')


                    window.scrollTo(0, 10);


                }
            }else {
                new AppointmentForm(this.onlineScheduling).bookingProcess(event, false);
            }
        }
        // Construct form data to send to the API
        const formData = new FormData(this.formElement);

    }


    hideError(id: any) {
        const field: any = document.getElementById(id);
        let fieldError: any = document.getElementById(`${id}-error-msg`);

        if(field.id == 'send-note' && field.value == '') {
            fieldError.classList.remove('hidden');
            field.classList.add('invalid-field');
            return;
        }
        fieldError.classList.add('hidden')
        field.classList.remove('invalid-field')

        setTimeout(() => { this.showSubmitButton.call(this) }, 300)

    }

    showSubmitButton() {

        // if((this.howAboutUsSelect.required ?   this.howAboutUsSelect.value : true) && (this.selectServiceSelect.required  ? this.selectServiceSelect.value : true) && (this.sendNoteTextarea.required ? this.sendNoteTextarea.value : true)){
        //    this.form[1].disabled = false
        //    this.form[1].style.opacity = '1'
        // }
        // else
        // {
        //    this.form[1].disabled = true
        //    this.form[1].style.opacity = '0.3'

        // }
    }


       async getMenu() {
        const dynamic_url = getWeblocationPathGroupWithDynamicConfig();
        const selectService = document.getElementById('select-service-category') as HTMLSelectElement;
    
        if (this.shouldFetchMenu()) {
            try {
                const cachedResponse = await getCachedAPIResponse(dynamic_url, apiCaching.SiteConfig, false);
                if (cachedResponse) {
                    const options = this.getServiceOptions(cachedResponse);
                    serviceData = options;
    
                    if (selectService || this.selectServiceProCategory) {
                        this.clearExistingOptions(selectService, this.selectServiceProCategory);
                        this.populateOptions(selectService, this.selectServiceProCategory, options);
                    }
    
                    this.setupCustomSelects();
                }
            } catch (error) {
                console.log("Error in getMenu function", error);
            }
        }
    }
    
    private shouldFetchMenu(): boolean {
        return !this.selectServiceSelect?.value || 
               this.selectServiceSelect?.value === 'Select a category type' || 
               !this.selectServiceProCategory?.value;
    }
    
    private getServiceOptions(cachedResponse: any): any {
        const data = cachedResponse.serviceMenu;
        let options = data["country"][0].brand[0].option;
        if (localStorage.getItem("dbaName")) {
            options = data["country"][0]?.brand[0]?.webLocation?.[0]?.option;
        }
        return options?.filter((service: any) => service?.visibility) // Filter out services with visibility set to false
        .map((service: any) => {
            return {
                ...service,
                category: service.category
                    .filter((cat: any) => cat?.visibility && 
                    cat.menu?.some((menuItem: any) => menuItem?.visibility)) // Filter categories by visibility and also to check if at least one menu item is visible
            };
        });
    }
    
    private clearExistingOptions(selectService: HTMLSelectElement, selectServiceProCategory: HTMLSelectElement): void {
        if (selectService) {
            selectService.innerHTML = '';
        }
        if (selectServiceProCategory) {
            selectServiceProCategory.innerHTML = '';
        }
    }
    
    private populateOptions(selectService: HTMLSelectElement, selectServiceProCategory: HTMLSelectElement, options: any): void {
        const defaultOption = this.createOption('', 'Select a Service Category');
        selectService?.appendChild(defaultOption);
        selectServiceProCategory?.appendChild(defaultOption.cloneNode(true));
        const category = this.sortServicesByName(options[0]?.category);
        category?.forEach((optionData: any) => {
            const option = this.createOption(optionData.name, optionData.name);
            selectService?.appendChild(option);
            selectServiceProCategory?.appendChild(option.cloneNode(true));
        });
    }
    
    private createOption(value: string, text: string): HTMLOptionElement {
        const option = document.createElement('option');
        option.value = value;
        option.textContent = text;
        return option;
    }
    
    private setupCustomSelects(): void {
        setTimeout(() => {
            if(window?.calendarTypeId ==2){
                this.setupData("custom-select1-scheduler-pro");
                this.setupData2("custom-select2-scheduler-pro",this.selectServiceProCategory ? true:false);
            }else{
                this.setupData("custom-select1");
                this.setupData2("custom-select2",this.selectServiceSelect ? true:false);
            }
        }, 1000);
    }
    
    solutionAppointmentError(): void {
        const shelfError = document.getElementById('shelf-solution-radio') as HTMLInputElement;
        const closetError = document.getElementById('closet-solution-radio') as HTMLInputElement;
        const bothError = document.getElementById('both-solution-radio') as HTMLInputElement;
        const radioButtons = [shelfError, closetError, bothError];
        radioButtons.forEach(element => {
            element?.addEventListener('click', () => {
                this.hideErrorMessage();
            });
        });
    }
    
    hideErrorMessage() {
        const errorMsg = document.getElementById('shelf-solution-radio-error-msg') as HTMLElement;
        if (errorMsg) {
            errorMsg.classList.add('hidden');
        }
    }
    private isFormValid(): boolean {
        const shelfError = document.getElementById('shelf-solution-radio') as HTMLInputElement;
        const closetError = document.getElementById('closet-solution-radio') as HTMLInputElement;
        const bothError = document.getElementById('both-solution-radio') as HTMLInputElement;
        const solutionNeedLabel = document.querySelector(".select-solution-wrap .confiramtion_slnLabel")as HTMLElement;
        if(solutionNeedLabel){
            sessionStorage.setItem("solution_needs_label", solutionNeedLabel.innerHTML)
        }

        document.querySelectorAll('.error-msg').forEach(e => e.classList.add('hidden'));
        document.querySelectorAll('.invalid-field').forEach(e => e.classList.remove('invalid-field'));
        const errors: any = {};
        
        const allNull = !shelfError && !closetError && !bothError;
        let appointMentCloset = window.offersClosetAppointments; 

        if (!allNull && appointMentCloset && this.onlineScheduling) {
            
            const isShelfValid = !shelfError || (shelfError.required && !shelfError.checked);
            const isClosetValid = !closetError || (closetError.required && !closetError.checked);
            const isBothValid = !bothError || (bothError.required && !bothError.checked);
        
            if (isShelfValid && isClosetValid && isBothValid) {
                errors['shelf-solution-radio'] = '';
            }
        }
        if (this.sendNoteTextarea?.required && this.sendNoteTextarea?.value === '') {
            errors['send-note'] = '';
        }

        if (window?.calendarTypeId !=2 && this.selectServiceSelect?.required && this.selectServiceSelect?.value === '') {
            errors['select-service-category'] = '';
        }

        if (window?.calendarTypeId !=2 && this.selectSubServiceSelect?.required && this.selectSubServiceSelect?.value === '') {
            errors['select-service'] = '';
        }
        
        if (this.selectFloorAppliance?.required && this.selectFloorAppliance?.value === 'Select floor') {
            errors['select-floor'] = '';
        }

        if (this.howAboutUsSelect?.required && this.howAboutUsSelect?.value === '') {
            errors['how-about-us'] = '';
        }

        if (window?.calendarTypeId ==2 && this.selectServiceProCategory?.required && this.selectServiceProCategory?.value === '') {
            errors['select-servicepro-category'] = '';
        }

        if (window?.calendarTypeId ==2 && this.selectServicePro?.required && this.selectServicePro?.value === '') {
            errors['select-servicepro'] = '';
        }

        Object.keys(errors).forEach((fieldId, index) => {
            const field: any = document.getElementById(fieldId);
            let fieldError: any = document.getElementById(`${fieldId}-error-msg`);
            
            if (field && field.parentNode) {
                const errorMessage = errors[fieldId];
                const errorElement = document.createElement('span');
                errorElement.className = 'error-msg';
                errorElement.textContent = errorMessage
                field.classList.add("invalid-field");
                if (fieldError && errorMessage) {
                    fieldError.innerHTML = errorMessage
                }
                if (fieldError && fieldError.classList?.contains('hidden'))
                    fieldError.classList.remove('hidden')
                // field.parentNode.appendChild(errorElement);
            }
            if (index == 0 && field) {
                field.focus()
            }
        });
        return Object.keys(errors).length === 0;    
    }


    private resetForm(): void {
        this.formElement.reset();
    }




    private getSelectedFiles(): File[] {
        const fileInputs = document.querySelectorAll('input[type="file"]');
        const selectedFiles: File[] = [];
        fileInputs.forEach((input: any) => {
            if (input.files && input.files.length > 0) {
                selectedFiles.push(input.files[0]);
            }
        });
        return selectedFiles;
    }

    private clearPreview(index: any): void {
        const previewContainer: any = document?.querySelectorAll('.file-upload img')[index];
        const uploadData: any = document?.querySelectorAll('.upload-data')[index];
        previewContainer.src = null
        const fileInputs: any = document.querySelectorAll('input[type="file"]')[index] as HTMLInputElement
        fileInputs.value = null
        previewContainer.removeAttribute('style')
        uploadData.classList.remove('active');
    }


    private showFilePreview(fileInput: any, index: number): void {
        const file = fileInput.files && fileInput.files[0];
        // FilesUrl[index] =    URL.createObjectURL(file)

        s3upload(file, (fs) => {
            FilesUrl[index] = fs

        })

        const fileName = file.name;
        const fileExt = fileName.split('.')[1]?.trim();
        const imgFiles = ["png", "svg", "jpg", "jpeg"].includes(fileExt);
        const pdfFiles = fileExt === "pdf";
        const excelFiles = fileExt === "xlsx";
        const docFiles = ["docx", "doc"].includes(fileExt);
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const previewContainer: any = document?.querySelectorAll('.file-upload img')[index];
                const uploadData: any = document?.querySelectorAll('.upload-data')[index];
                if (previewContainer) {
                    if (imgFiles) {
                        previewContainer.src = reader.result as string;
                        previewContainer.style.display = "block";
                        uploadData.classList.add('active')
                    } else if (pdfFiles) {
                        previewContainer.src = "/brand/_assets/images/icons/pdf-icon.svg";
                        previewContainer.style.display = "block";
                        uploadData.classList.add('active')
                    } else if (excelFiles) {
                        previewContainer.src = "/brand/_assets/images/icons/excel-icon.svg";
                        previewContainer.style.display = "block";
                        uploadData.classList.add('active')
                    } else if (docFiles) {
                        previewContainer.src = "/brand/_assets/images/icons/doc-icon.svg";
                        previewContainer.style.display = "block";
                        uploadData.classList.add('active')
                    }

                }
            };
            reader.readAsDataURL(file);
        }
    }

    private escapeServiceCategory(selectItems: HTMLDivElement,selectSelected: HTMLButtonElement){
        document.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
           selectItems.classList.add("select-hide");
           selectSelected.classList.remove("select-arrow-active");
            }
          })
    }

     flattenMenu(menu:any) {
        return menu.reduce((result:any, item:any) => {
            result.push(item);
    
            if (item.menu && Array.isArray(item.menu)) {
                result.push(...this.flattenMenu(item.menu));
            }
    
            return result;
        }, []);
    }

    public setupServiceDropdown(selectSelected: HTMLButtonElement | null) {

        const customSelect2 = document.getElementById("custom-select2");
        const customSelect2SchedulerPro = document.getElementById("custom-select2-scheduler-pro");

            if(customSelect2 != null || customSelect2SchedulerPro != null){ 
            const indexx = this.getValue();
            const valueSelected = serviceData[indexx]?.category?.find((x: any) => x.name == selectSelected?.innerHTML);

            let servicess = extractServices(valueSelected);

            if (selectSelected === null) {
                const flattenedMenu = serviceData[indexx]?.category?.flatMap((item: { menu: any; service: any }) => {
                    if (item.menu) {
                        return item.menu.flatMap((menuItem: { service: any ,serviceSkuId:any}) => {
                            if (menuItem.service) {
                                menuItem.service.forEach((item: any) => {
                                    item.serviceSkuId = true;
                                });
                                return this.flattenMenu(menuItem?.service);
                            } else {
                                return [menuItem];
                            }
                        });
                    } else {
                        return [];
                    }
                });
                if (flattenedMenu && Array.isArray(flattenedMenu)) {
                    const sortedMenu: MenuItem[] = flattenedMenu.sort((a: { name: string; }, b: { name: any; }) => a.name.localeCompare(b.name));
                    const uniqueMenu: MenuItem[] = sortedMenu.filter((value, index, self) =>
                        index === self.findIndex((item) => item.name === value.name)
                    );
                    servicess = uniqueMenu;
                } 
            
            }

            if (!valueSelected) {
                this.disibleSelectCateogry(customSelect2, 'select-service');
                this.disibleSelectCateogry(customSelect2SchedulerPro, 'select-service');
            }
            const selectService = document.getElementById('select-service') as HTMLSelectElement;
            
            if (selectService !== null || this.selectServicePro !== null) {

                if (selectService !== null) {
                  selectService.innerHTML = '';
                }
                if (this.selectServicePro !== null) {
                    this.selectServicePro.innerHTML = '';
                }
                const option = document.createElement('option'); // Create default option 
                option.value = '';
                option.textContent = 'Select a Service';
                selectService?.appendChild(option); // Append selected option to the select service dropdown
                this.selectServicePro?.appendChild(option.cloneNode(true)); // Append selected option to the select service pro dropdown
            }
            servicess = this.sortServicesByName(servicess); // Sorting the Select Servicess
            servicess?.forEach((optionData: any) => {
                const option = document.createElement('option');
                option.value = optionData.name;
                option.textContent = optionData.name;
                option.setAttribute('data-service-sku-id', optionData.id);
                option.setAttribute('data-service-sku', optionData.serviceSkuId);
                selectService?.appendChild(option); // append option to the select service dropdown
                this.selectServicePro?.appendChild(option.cloneNode(true)); // append option to the select service pro dropdown
            });
        }
    }

  public disibleSelectCateogry(element: HTMLElement | null, className: string) {
        if (element) {
            const parentElement = element.parentElement;
            if (parentElement) {
                parentElement.classList.add(className);
            }
        }
    }

    public sortServicesByName(services: any[]): any[] {
        return services?.sort((a: any, b: any) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
    }

}


// Initialize the form handler

