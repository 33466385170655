import { FranchiseDetail } from "./models/FranchiseDetail";
import { debugConfig,brandColorCode } from "./config";
import { getBubbleContent } from "./getBubbleContent";
export class Cluster {
    map: H.Map; 
    ui: H.ui.UI;
    private bubble: H.ui.InfoBubble | undefined;

    constructor(map: H.Map, ui: H.ui.UI) {
        this.map = map;
        this.ui = ui;    
    }

    startClustering(data: FranchiseDetail[]){
        //const data: FranchiseDetail[] = JSON.parse(localStorage.getItem('filteredFranchiseDetails'));
        
        const dataPoints = data.map(function (item)
        {
            return new H.clustering.DataPoint(item.latitude, item.longitude, undefined , item);
        });

        // Create a clustering provider with custom options for clusterizing the input
        var clusteredDataProvider = new H.clustering.Provider(dataPoints, {
            clusteringOptions: {
                // Maximum radius of the neighbourhood - should be power of 2
                eps: 32,
                // minimum weight of points required to form a cluster
                minWeight: 2,
                strategy: H.clustering.Provider.Strategy.GRID                
            },
            theme: CUSTOM_THEME
        });

        // Note that we attach the event listener to the cluster provider, and not to
        // the individual markers
        clusteredDataProvider.addEventListener('tap', this.onMarkerClick);

        // Create a layer tha will consume objects from our clustering provider
        var clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);

        // To make objects from clustering provder visible,
        // we need to add our layer to the map
        this.map.addLayer(clusteringLayer);
    }
    onMarkerClick =  (e: any) => {
        
        var position = e.target.getGeometry(),
            // Get the data associated with that marker
            data = e.target.getData();
            // Merge default template with the data and get HTML
            if(data) {
                let bubbleContent = getBubbleContent(data),
                bubble = this.bubble;
    
                // For all markers create only one bubble, if not created yet
                if (!bubble)
                {
                    (async () => {
                    bubble = new H.ui.InfoBubble(position, {
                        content: await bubbleContent
                    });
                    this.ui.addBubble(bubble);
                    // Cache the bubble object
                    this.bubble = bubble;
                })();
                } else
                {
                    (async () => {
                    // Reuse existing bubble object
                    bubble.setPosition(position);
                    bubble.setContent(await bubbleContent);
                    bubble.open();
                    })();
                }
        
                // Move map's center to a clicked marker
                this.map.setCenter(position, true);
        
                // replace close-icon
                if(debugConfig.level === 'debug') {
                    //console.log('marker clicked: replacing close icon');
                }    
                
                const closeElement = document.getElementsByClassName("H_ib_close H_btn")[0];
                if(closeElement) {
                    closeElement.innerHTML = bubbleCloseIcon;
                }
            }
            else {
                // cluster point is clicked
                //console.log('cluster point clicked position: ', position);
                
                this.map.setCenter({lat:position.lat, lng: position.lng});
                this.map.setZoom(this.map.getZoom() + 1);
            }
        
        //replaceInfoBubbleCloseIcon();
    }
}
let CUSTOM_THEME = {
    getClusterPresentation: (cluster: H.clustering.ICluster): H.map.Object =>
    {
        const svgMarkup = getCombinedLocationsMarkup(cluster.getWeight());

        const icon = new H.map.Icon(svgMarkup);
        // Get random DataPoint from our cluster
        //var randomDataPoint = getRandomDataPoint(cluster),
        // Get a reference to data object that DataPoint holds
        //data = randomDataPoint.getData();

        // Create a marker from a random point in the cluster
        var clusterMarker = new H.map.Marker(cluster.getPosition(), {
            icon: icon,

            // Set min/max zoom with values from the cluster,
            // otherwise clusters will be shown at all zoom levels:
            min: cluster.getMinZoom(),
            max: cluster.getMaxZoom()
        });

        // Link data from the random point from the cluster to the marker,
        // to make it accessible inside onMarkerClick
        //clusterMarker.setData(data);
        //clusterMarker.addEventListener('tap', onClusterMarkerClick);
        return clusterMarker;
    },
    getNoisePresentation: (noisePoint: H.clustering.INoisePoint): H.map.Object =>
    {

        const icon = new H.map.Icon(singleLocationSvgMarkup, {size: {w: 30, h: 30}});
        // Get a reference to data object our noise points
        var data = noisePoint.getData(),
            // Create a marker for the noisePoint
            noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
                // Use min zoom from a noise point
                // to show it correctly at certain zoom levels:
                min: noisePoint.getMinZoom(),
                icon: icon
            });

        // Link a data from the point to the marker
        // to make it accessible inside onMarkerClick
        noiseMarker.setData(data);
        
        return noiseMarker;
    }
};
const getCombinedLocationsMarkup = (num: number) =>
{
    const colorCode:any = document.getElementById('conceptCode') as HTMLInputElement;
    const colorCodeVal:any = brandColorCode[colorCode.value]

    return `<svg xmlns="http://www.w3.org/2000/svg" height="32" width="32"><circle class="c" cx="16" cy="16" r="14" fill="none" stroke="${colorCodeVal}" stroke-width="3"/><circle class="d" cx="16" cy="16" r="5" fill="${colorCodeVal}" stroke="${colorCodeVal}" stroke-width="10"/><text stroke="#fff" alignment-baseline="middle" text-anchor="middle" x="16" y="16" fill="#fff" font-size=".8em">${num}</text></svg>`

}
const origin = window.location.origin;
const singleLocationSvgMarkup =origin+ "/brand/_assets/images/brand-img/map-view.svg";
const bubbleCloseIcon = '<svg class="H_icon" viewBox="0 0 12 12"><path d="m8.12 6 3.66-3.66c.29-.29.29-.76 0-1.05L10.72.23a.742.742 0 0 0-1.05 0L6.01 3.89 2.35.23a.742.742 0 0 0-1.05 0L.24 1.29c-.29.29-.3.76 0 1.05L3.9 6 .24 9.66c-.29.29-.29.76 0 1.05l1.06 1.06c.29.29.76.29 1.05 0l3.66-3.66 3.66 3.66c.29.29.76.29 1.05 0l1.06-1.06c.29-.29.3-.76 0-1.05z" fill="#fff"/></svg>';
