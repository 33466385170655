import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { performanceBenchmark, handleApiError, uniqueIDGenerator } from './apiLogger';
import { isTestEnvironment } from '../apiCaching/POST-Requests/helperModules/helperFunctions';

const axiosInstance1 = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
});

const axiosInstance2 = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'apikey': process.env.JS_API_KEY,
  }
});

const testEnvironment: boolean = isTestEnvironment();
const conceptCode = (document.getElementById('conceptCode')as HTMLInputElement)?.value ?? '';

// Track in-progress requests and throttled requests
const pendingRequests = new Map<string, Promise<any>>();
const throttledRequests = new Map<string, number>();

// Throttle duration in milliseconds
const THROTTLE_DURATION = 3000;

/**
 * Generates a unique key based on URL and request payload (excluding response data).
 */
function generateRequestKey(config: AxiosRequestConfig): string {
  const url = config.url ?? 'unknown-url';
  const type = config.method?.toUpperCase() ?? 'GET';
  return `${type}-${url}`;
}


/**
 * Generalized function to handle API requests with deduplication and payload-based throttling.
 */
async function performApiRequest<T>(
  config: AxiosRequestConfig, 
  axiosInstance = axiosInstance1
): Promise<T> {
  const requestKey = generateRequestKey(config);
  const now = Date.now();

  // Throttling: If an identical request was made recently, ignore the new request
  if(conceptCode.toUpperCase() === 'DVW'){
    if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(config.method?.toUpperCase() ?? '') && throttledRequests.has(requestKey)) {
      const lastRequestTime = throttledRequests.get(requestKey)!;
      if ((now - lastRequestTime) < THROTTLE_DURATION) {
        if (testEnvironment) 
          console.log('%c[API]%c Throttled request, no new call made:', 'background:orange; color:#f3f3f3; font-weight: bold; padding: 0 8px', '', requestKey);
        return Promise.reject(new Error('Request throttled due to duplicate payload.'));
      }
    }
  
    // Deduplication: Return existing promise if the request is already in progress
    if (pendingRequests.has(requestKey)) {
      if (testEnvironment)
        console.log('%c[API]%c API request already in progress - Returning existing promise:', 'background:orange; color:#f3f3f3; font-weight: bold; padding: 0 8px', '', requestKey);
      return pendingRequests.get(requestKey)! as Promise<T>;
    }
  } 
  

  const uniqueID = uniqueIDGenerator(config.url ?? 'unknown-url');
  performance.mark(`${uniqueID}-start`);

  const requestPromise = axiosInstance.request<T>(config)
    .then((response: AxiosResponse<T>) => {
      performanceBenchmark(config.url ?? 'unknown-url', uniqueID);

      // Update throttle timestamp after successful request
      throttledRequests.set(requestKey, Date.now());

      return response.data;
    })
    .catch((error: any) => {
      throw handleApiError(error, config.url ?? 'unknown-url');
    })
    .finally(() => {
      pendingRequests.delete(requestKey);
    });

  pendingRequests.set(requestKey, requestPromise);
  return requestPromise;
}



/**
 * Wrapper function for handling API requests with performance and error logging.
 */
export async function apiRequest<T>(config: AxiosRequestConfig): Promise<T> {
  return performApiRequest(config, axiosInstance1);
}

/**
 * Alternative API request function using a different Axios instance.
 */
export async function apiRequest2<T>(config: AxiosRequestConfig): Promise<T> {
  return performApiRequest(config, axiosInstance2);
}

/**
 * API request function for wrapper with specialized error handling.
 */
export async function apiRequestForWrapper<T>(config: AxiosRequestConfig): Promise<T> {
 return performApiRequest(config, axiosInstance1);
}

export async function wrapperAPICall<T>(config: AxiosRequestConfig): Promise<T> {
  return performApiRequest(config, axiosInstance2);
}

export default apiRequest;
