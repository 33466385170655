export function youtubeInit() {
    const youtubeElements = Array.from(document.querySelectorAll(".video-container")) as HTMLElement[];
    if (youtubeElements.length > 0) {
        for (const youtube of youtubeElements) {
            let embedCode = youtube.getAttribute('data-embed'); // Get the embedCode from the attribute
            if (!embedCode) {
                // Retrieve the URL from the input-replace-video-image div
                const replaceVideoImage = document.getElementById("input-replace-video-image");
                if (replaceVideoImage) {
                    const url = replaceVideoImage.getAttribute("value");
                    // Extract the video ID from the URL
                    if (url) {
                        const urlParts = url.split("/");
                        embedCode = urlParts[urlParts.length - 1];
                    }
                }
            }
            if (embedCode) {
                // Get the video poster element
                const videoPoster = youtube.querySelector('.video-poster') as HTMLElement;
                if (videoPoster) {
                    if (iOS()) {
                        videoPoster.remove();
                        const youtubeSrc = embedCode;
                        const iframe = document.createElement("iframe");
                        iframe.setAttribute("frameborder", "0");
                        iframe.setAttribute("allowfullscreen", "");
                        iframe.setAttribute("allow", "autoplay; encrypted-media");
                        iframe.setAttribute("src", `https://www.youtube.com/embed/${youtubeSrc}?autoplay=1&rel=0&showinfo=0`);

                        // Clear the contents of the youtube container and add the iframe
                        youtube.innerHTML = "";
                        youtube.appendChild(iframe);
                    }
                    else {
                        // Set the background image URL directly using JavaScript without HTML escaping
                        videoPoster.style.backgroundImage = `url("https://img.youtube.com/vi/${embedCode}/hqdefault.jpg")`;

                        // Attach the click event to the videoPoster
                        videoPoster?.addEventListener("click", () => {
                            const youtubeSrc = embedCode;
                            const iframe = document.createElement("iframe");
                            iframe.setAttribute("frameborder", "0");
                            iframe.setAttribute("allowfullscreen", "");
                            iframe.setAttribute("allow", "autoplay; encrypted-media");
                            iframe.setAttribute("src", `https://www.youtube.com/embed/${youtubeSrc}?autoplay=1&rel=0&showinfo=0`);

                            // Clear the contents of the youtube container and add the iframe
                            youtube.innerHTML = "";
                            youtube.appendChild(iframe);
                        });
                    }
                }
            }
        }
    }
}

function iOS() {
    return (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

youtubeInit();
