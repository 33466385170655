interface OfferSelectors {
    titleSelector: string;
    descriptionSelector: string;
    expirySelector: string;
    disclaimerSelector: string;
    couponCodeInputSelector: string;
}
export function attachEventListenerForClaim(sectionSelector: any, buttonSelector: any, liItem: any, commonSelectors: OfferSelectors ) {
    const claimOfferButtons = document.querySelectorAll(`${sectionSelector} ${buttonSelector}`);
    if (claimOfferButtons?.length === 0) return;

    claimOfferButtons.forEach(button => {
        // Check if the href attribute is not empty
        if (button?.getAttribute('href') !== '') {
            // Attach the event listener
            button?.addEventListener('click', (event) => {
                event.preventDefault(); // Prevent the default action 
                const target = event.target as HTMLElement;
                // Check if the target is the claim button
                if (target?.classList?.contains('secondary-btn')) {
                    const href = (target as HTMLAnchorElement).href;
                    let parentDom;
                    if (sectionSelector === '.sp-offers-section.coupons-eight' || sectionSelector === '.offers-block-section.coupons-eleven') {
                        parentDom = target?.closest(liItem) as HTMLElement;
                        if (parentDom) {
                            setOffersInSessionCommon(sectionSelector,
                                parentDom,
                                href,
                                commonSelectors 
                            )
                        }
                    }
                      
                }
            });
            // If the section is '.coupons-eleven', call removeAllEventListeners     
            if (sectionSelector === '.offers-block-section.coupons-eleven') {
                const anchor = document.querySelector('.secondary-btn.claim-offer');
                anchor?.addEventListener('click', function (event) {
                    event.stopPropagation();  // Prevent the click event from propagating to the li
                });
            }
        }
    });
}


function setOffersInSessionCommon(
    sectionSelector: any,
    targetElement: HTMLElement,
    href: string | null,
    commonSelectors: OfferSelectors 
    // titleSelector: string,
    // descriptionSelector: string,
    // expirySelector: string,
    // disclaimerSelector: string,
    // couponCodeInputSelector: string
) {
    const obj = {
        'title': '',
        'description': '',
        'couponexpirydate': '',
        'longdescription': '',
        'couponcode': ''
    };

    // Info from offer
    obj.title = targetElement.querySelector(commonSelectors.titleSelector)?.textContent ?? '';
    obj.description = targetElement.querySelector(commonSelectors.descriptionSelector)?.textContent ?? '';
    obj.couponexpirydate = targetElement.querySelector(commonSelectors.expirySelector)?.textContent?.replace('Expires: ', '') ?? '';

    //Long description from the disclaimer text
    const firstTxt = targetElement.querySelector(`${commonSelectors.disclaimerSelector} p`)?.textContent ?? '';
    const secondTxt = targetElement.querySelector(`${commonSelectors.disclaimerSelector} p a`)?.textContent ?? '';  // Assuming you want to append the link text too
    obj.longdescription = `${firstTxt} ${secondTxt}`;

    //if its coupon11
    if (sectionSelector === '.offers-block-section.coupons-eleven') {
        const firstTxt = targetElement.querySelector(`${commonSelectors.disclaimerSelector}`)?.textContent ?? '';
        obj.longdescription = `${firstTxt}`;
    }
    // coupon code from the hidden field
    const couponCodeInput = targetElement.querySelector(commonSelectors.couponCodeInputSelector) as HTMLInputElement;
    if (couponCodeInput) {
        obj.couponcode = couponCodeInput.value;
    }
    else {
        console.log("No Coupon code found");
    }
    // sessionStorage
    sessionStorage.setItem('offerDetails', JSON.stringify(obj));

    // redirect the user to the offer URL
    if (href) {
        window.location.href = href;
    }
}
